import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getEmployeeRules,
  postEmployeeRules,
  putEmployeeRulesId,
  deleteEmployeeRulesId,
} from 'src/services/apis/services';
import { EmployeeRuleCreateDto, EmployeeRuleUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useEmployeeRules = () => {
  return useQuery([QUERIES.EMPLOYEE_RULES], () => getEmployeeRules());
};

export const useCreateEmployeeRulesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: EmployeeRuleCreateDto) => postEmployeeRules(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEE_RULES]);
    },
  });
};

export const useUpdateEmployeeRulesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: EmployeeRuleUpdateDto) => putEmployeeRulesId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEE_RULES]);
    },
  });
};

export const useDeleteEmployeeRulesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((employeeId: string) => deleteEmployeeRulesId(employeeId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEE_RULES]);
    },
  });
};
