import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton, RadioButtonGroup } from '@carbon/react';

interface FormikRadioButtonGroupProps {
  value: string | number;
  label: string;
  name: string;
  id?: string;
  useTranslation?: boolean;
  options: Array<{ value: string | number; label: string }>;
  onChange: (value: string) => void;
}

export const FormikRadioButtonGroup = React.memo((props: FormikRadioButtonGroupProps) => {
  const { t } = useTranslation();
  return (
    <Field name={props.name}>
      {() => {
        return (
          <RadioButtonGroup
            id={props.id}
            legendText={props.label}
            valueSelected={props.value}
            onChange={props.onChange}
          >
            {props.options.map((option) => (
              <RadioButton labelText={props.useTranslation ? t(option.label) : option.label} value={option.value} />
            ))}
          </RadioButtonGroup>
        );
      }}
    </Field>
  );
});
