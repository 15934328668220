import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Modal,
  Toggle,
  ComboBox,
  Tag,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import EmployeeChangePasswordForm from 'src/components/Employee/EmployeeChangePasswordForm';
import EmployeeForm from 'src/components/Employee/EmployeeForm';
import {
  useEmployees,
  useCreateEmployeesMutation,
  useUpdateEmployeesMutation,
  useDeleteEmployeesMutation,
  useChangeEmployeePasswordMutation,
} from 'src/hooks/useEmployees';
import { useBranches } from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { EmployeeEntity } from 'src/services/apis/types';
import { EmployeeRoles } from 'src/utils/employee';
import { getMutationErrorMessage } from 'src/utils/error';
import { KToNumber } from 'src/utils/price';

const AllEmployees = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const changePasswordFormRef = useRef<any>();
  const { isLoading, data } = useEmployees();
  const { data: branches } = useBranches();
  const createEmployeesMutation = useCreateEmployeesMutation();
  const updateEmployeesMutation = useUpdateEmployeesMutation();
  const deleteEmployeesMutation = useDeleteEmployeesMutation();
  const changeEmployeePasswordMutation = useChangeEmployeePasswordMutation();
  const currentRestaurant = useCurrentRestaurant();
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState<null | undefined | EmployeeEntity>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [deletingEmployee, setDeletingEmployee] = useState<null | undefined | EmployeeEntity>(null);
  const [changingPasswordForEmployee, setChangingPasswordForEmployee] = useState<null | undefined | EmployeeEntity>(
    null
  );
  const [filterByBranchId, setFilterByBranchId] = useState<string | null>(null);

  const handleCloseAddOrEditEmployee = useCallback(() => {
    setOpenAddEmployee(false);
    setEditingEmployee(null);
    setEditingEmployee(null);
    formRef.current?.resetForm();
  }, []);

  const handleCloseChangeEmployeePassword = useCallback(() => {
    setChangingPasswordForEmployee(null);
    changePasswordFormRef.current?.resetForm();
  }, []);

  const handleAddEmployee = useCallback(() => {
    setOpenAddEmployee(true);
  }, []);

  const handleCreate = useCallback(
    async (values) => {
      await createEmployeesMutation.mutateAsync({
        ...values,
        salary: KToNumber(parseInt(values.salary, 10)),
        extraBonus: KToNumber(parseInt(values.extraBonus, 10)),
        restaurantId: currentRestaurant?.id,
      });
      handleCloseAddOrEditEmployee();
    },
    [currentRestaurant?.id]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateEmployeesMutation.mutateAsync({
        ...values,
        salary: KToNumber(parseInt(values.salary, 10)),
        extraBonus: KToNumber(parseInt(values.extraBonus, 10)),
        restaurantId: currentRestaurant?.id,
      });
      handleCloseAddOrEditEmployee();
    },
    [currentRestaurant?.id]
  );

  const handleChangePassword = useCallback(
    async (values) => {
      await changeEmployeePasswordMutation.mutateAsync(values);
      handleCloseChangeEmployeePassword();
    },
    [currentRestaurant?.id]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingEmployee(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteEmployeesMutation.mutateAsync(deletingEmployee?.id!);
    setDeletingEmployee(null);
  }, [deletingEmployee]);

  const headers = [
    {
      key: 'firstName',
      header: t('common.firstName'),
    },
    {
      key: 'lastName',
      header: t('common.lastName'),
    },
    {
      key: 'email',
      header: t('common.email'),
    },
    {
      key: 'phoneNumber',
      header: t('common.phoneNumber'),
    },
  ];

  const extraHeaders = [
    { key: 'role', header: t('common.role') },
    { key: 'branch', header: t('common.branch') },
    { key: 'activation', header: t('common.activation') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return (
      <DataTableSkeleton
        columnCount={headers.length + extraHeaders.length}
        rowCount={6}
        headers={[...headers, ...extraHeaders]}
      />
    );
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (filterByBranchId) {
          return item.restaurantBranchId === filterByBranchId;
        }
        return true;
      })
      .filter((item) => {
        if (searchQuery) {
          const { firstName, lastName, email, phoneNumber } = item.user;
          return (
            firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
            email.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return true;
      })
      .map((item) => ({
        ...item,
        firstName: item.user.firstName,
        lastName: item.user.lastName,
        email: item.user.email,
        phoneNumber: item.user.phoneNumber,
      })) || [];

  const submitting = createEmployeesMutation.isLoading || updateEmployeesMutation.isLoading;
  const changingPassword = changeEmployeePasswordMutation.isLoading;
  const createError = getMutationErrorMessage(createEmployeesMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateEmployeesMutation.error as AxiosError);

  const tableTitle = t('titles.employeeSettingsPage', { restaurantName: currentRestaurant?.name });

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer title={tableTitle} description={`${data?.total} ${t('common.employees')}`}>
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={(e) => setSearchQuery(e.target.value)} />
                <ComboBox
                  items={branches?.data.map((item) => ({ id: item.id, text: item.name }))}
                  placeholder={t('common.filterByBranch')}
                  size={'lg'}
                  onChange={(e) => setFilterByBranchId(e.selectedItem?.id)}
                  className={'cds--combo-box--no-border-bottom'}
                  itemToString={(item) => (item ? item.text : '')}
                />

                <Button renderIcon={Add} onClick={handleAddEmployee}>
                  {t('actions.create')} {t('common.employee')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      <Tag type={filteredRows[index]?.role === 'E' ? 'green' : 'red'} size="sm">
                        {EmployeeRoles.find((item) => item.value === filteredRows[index]?.role)?.label}
                      </Tag>
                    </TableCell>
                    <TableCell>
                      {filteredRows[index] &&
                        branches?.data.find((item) => item.id === filteredRows[index].restaurantBranchId)?.name}
                    </TableCell>
                    <TableCell>
                      {filteredRows[index] && (
                        <Toggle
                          labelA={t('common.no')}
                          labelB={t('common.yes')}
                          toggled={filteredRows[index].activated}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={'Actions'}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingEmployee(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          itemText={t('actions.changePassword')}
                          requireTitle
                          onClick={() => setChangingPasswordForEmployee(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingEmployee(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddEmployee || !!editingEmployee}
        size={'sm'}
        modalHeading={
          openAddEmployee
            ? `${t('actions.createNew')} ${t('common.employee')}`
            : `${t('actions.update')} ${t('common.employee')}`
        }
        modalLabel={tableTitle}
        primaryButtonText={
          submitting ? `${t('common.loading')}...` : editingEmployee ? t('actions.update') : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditEmployee}
        onRequestClose={handleCloseAddOrEditEmployee}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <EmployeeForm
          errorMessage={createError || updateError}
          initialValues={editingEmployee}
          ref={formRef}
          onSubmit={editingEmployee ? handleUpdate : handleCreate}
        />
      </Modal>
      <Modal
        open={!!changingPasswordForEmployee}
        size={'sm'}
        modalHeading={t('common.changeUserPassword', { firstName: changingPasswordForEmployee?.user.firstName })}
        modalLabel={tableTitle}
        primaryButtonText={changingPassword ? `${t('common.saving')}...` : t('actions.save')}
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseChangeEmployeePassword}
        onRequestClose={handleCloseChangeEmployeePassword}
        primaryButtonDisabled={changingPassword}
        onRequestSubmit={() => changePasswordFormRef.current?.submitForm()}
      >
        <EmployeeChangePasswordForm
          employeeId={changingPasswordForEmployee?.id!}
          ref={changePasswordFormRef}
          onSubmit={handleChangePassword}
        />
      </Modal>
      <DeleteConfirmation
        open={!!deletingEmployee}
        heading={t('messages.areYouSureToDeleteThisEmployee')}
        label={tableTitle}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteEmployeesMutation.isLoading}
      />
    </>
  );
});

export default AllEmployees;
