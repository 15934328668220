import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Modal, SkeletonPlaceholder } from '@carbon/react';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import EmptyState from 'src/components/Base/EmptyState';
import DeliveryOrderItem from 'src/components/Delivery/DeliveryOrderItem';
import OrderDetails from 'src/components/Order/OrderDetails';
import { useDeliveryOrders, usePaidOrderTokens, useUpdateOrdersMutation } from 'src/hooks/useOrders';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { OrderEntity } from 'src/services/apis/types';

interface DeliveryProps {}

const OrdersLoading = React.memo(() => {
  return (
    <div className={'orders-grid'}>
      <div className={'delivery-order-item'}>
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </div>
      <div className={'delivery-order-item'}>
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </div>
      <div className={'delivery-order-item'}>
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </div>
      <div className={'delivery-order-item'}>
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </div>
    </div>
  );
});

const Delivery = React.memo((_: DeliveryProps) => {
  const { t } = useTranslation();
  const { isLoading, data: deliveryOrders } = useDeliveryOrders();
  const currentRestaurant = useCurrentRestaurant();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const { data: paidOrderTokens } = usePaidOrderTokens();
  const [viewingOrder, setViewingOrder] = useState<OrderEntity | null>(null);
  const updateOrdersMutation = useUpdateOrdersMutation(OrderTypeEnum.DELIVERY);

  const handleChangeOrderStatus = useCallback((order: OrderEntity | null | undefined, status: OrderStatusEnum) => {
    if (!order) return;
    updateOrdersMutation.mutate({ id: order.id, status });
  }, []);

  return (
    <div className="delivery-page">
      <div className="delivery-page__header">
        <Breadcrumb noTrailingSlash aria-label="Page navigation" className={'cds--breadcrumb--flex'}>
          <BreadcrumbItem>
            <Link to="/dashboard/selling">{t('common.home')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/delivery">{t('common.delivery')}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <h1 className="delivery-page__heading">
          {t('common.delivery')} {!isLoading && `(${deliveryOrders?.data?.length})`}
        </h1>
      </div>
      <div className={'delivery-page-orders'}>
        {isLoading && <OrdersLoading />}
        {!isLoading && !currentRestaurantBranch && <EmptyState title={t('messages.pleaseSelectABranch')} />}
        {!isLoading && deliveryOrders?.data?.length === 0 && !!currentRestaurantBranch && (
          <EmptyState title={t('messages.thereIsNoDeliveryOrders')} />
        )}
        <div className={'orders-grid'}>
          {deliveryOrders?.data?.map((order) => (
            <DeliveryOrderItem
              order={order}
              key={order.id}
              onView={setViewingOrder}
              paidOrderTokens={paidOrderTokens}
            />
          ))}
        </div>
      </div>
      <Modal
        open={!!viewingOrder}
        size={'md'}
        modalHeading={`#${viewingOrder?.token}`}
        modalLabel={`${currentRestaurant?.name} - ${currentRestaurantBranch?.name}`}
        passiveModal
        onRequestClose={() => setViewingOrder(null)}
      >
        <OrderDetails
          order={viewingOrder!}
          paidOrderTokens={paidOrderTokens}
          onPaid={(order) => {
            setViewingOrder(null);
            handleChangeOrderStatus(order, OrderStatusEnum.PAID);
          }}
        />
      </Modal>
    </div>
  );
});

export default Delivery;
