import classNames from 'classnames';
import { QRCodeSVG } from 'qrcode.react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tag, TextInput } from '@carbon/react';
import { Delivery, PhoneOutgoing, Time, ShoppingBag, Money, RuleCancelled, Close } from '@carbon/react/icons';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import Row from 'src/components/Base/Row';
import SuccessIcon from 'src/components/Lottie/SuccessIcon';
import OrderDate from 'src/components/Order/OrderDate';
import OrderLine from 'src/components/Order/OrderLine';
import { useMenuItems } from 'src/hooks/useMenuItems';
import { useTables } from 'src/hooks/useRestaurantTables';
import { OrderEntity } from 'src/services/apis/types';
import { getOrderTypeText, getOrderStatusText } from 'src/utils/order';
import { NumberToK } from 'src/utils/price';

interface OrderDetailsProps {
  order: OrderEntity | null | undefined;
  onPaid?: (order: OrderEntity | null | undefined) => void;
  onCancelled?: (order: OrderEntity | null | undefined, cancelReason: string) => void;
  paidOrderTokens?: string[];
}

function OrderDetailsNotes(props: { order: OrderEntity }) {
  const { t } = useTranslation();
  if (!props.order.notes) {
    return null;
  }
  return (
    <>
      <hr />
      <h5>{t('common.notes')}</h5>
      <div className="order-item">{props.order.notes}</div>
    </>
  );
}

function OrderDetailsDelivery(props: { order: OrderEntity }) {
  const { t } = useTranslation();
  if (props.order.type !== OrderTypeEnum.DELIVERY) {
    return null;
  }
  return (
    <>
      <hr />
      <h5>{t('common.deliveryDetails')}</h5>
      <div className="delivery-item">
        <Delivery size={34} />
        <p>{props.order.line1}</p>
      </div>
      <div className="delivery-item">
        <PhoneOutgoing size={34} />
        <p>
          <a href={`tel:${props.order.phoneNumber}`}>{props.order.phoneNumber}</a>
        </p>
      </div>
    </>
  );
}

function OrderDetailsSummary(props: { order: OrderEntity }) {
  const { t } = useTranslation();
  return (
    <>
      <h5>{t('common.orderSummary')}</h5>
      <>
        <div className="sub-total">
          <div className="line">
            <h4>{t('common.subTotal')}:</h4>
            <h5>{NumberToK(props.order.subTotal)}K</h5>
          </div>
          <div className="line">
            <h4>{t('common.tax')} (0%):</h4>
            <h5>0</h5>
          </div>
        </div>
        <div className="total">
          <div className="line">
            <h4>{t('common.total')}:</h4>
            <h5>{NumberToK(props.order.subTotal)}K</h5>
          </div>
        </div>
      </>
    </>
  );
}

function OrderDetailsHeading(props: { order: OrderEntity; paid: boolean }) {
  const { t } = useTranslation();
  const { data: tables } = useTables();
  const totalItems = props.order.orderItems.map((item) => item.quantity).reduce((total, quantity) => total + quantity);
  const orderTable = tables?.data?.find((table) => table.id === props.order.tableId);
  return (
    <div className="table-container">
      {props.order.type === OrderTypeEnum.DELIVERY && (
        <div className="table">
          <Delivery size={42} />
        </div>
      )}
      {props.order.type === OrderTypeEnum.TAKEAWAY && (
        <div className="table">
          <ShoppingBag size={42} />
        </div>
      )}
      {props.order.type === OrderTypeEnum.DINE_IN && <div className="table">{orderTable?.tableNumber}</div>}
      <div>
        <h4>
          {totalItems} {t('common.items')}
        </h4>
        <h6>
          <Time size={24} /> <OrderDate date={props.order.createdAt} />
        </h6>
        <Tag className="some-class" type="blue" size="md" title="Clear Filter">
          {t(getOrderTypeText(props.order.type))}
        </Tag>
        <Tag
          className="some-class"
          type={
            props.order.status === OrderStatusEnum.PAID || props.paid
              ? 'green'
              : props.order.status === OrderStatusEnum.READY
              ? 'cyan'
              : 'magenta'
          }
          size="md"
        >
          {props.paid ? t(getOrderStatusText(OrderStatusEnum.PAID)) : t(getOrderStatusText(props.order.status))}
        </Tag>
      </div>
    </div>
  );
}

const OrderDetails = React.memo((props: OrderDetailsProps) => {
  const { t } = useTranslation();
  const { data: menuItems } = useMenuItems();
  const [openCancel, setOpenCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  if (!props.order) {
    return null;
  }

  const handleChangeCancelReason = (e: any, isBlur: boolean) => {
    setCancelReason(e.target.value);
    if (isBlur || e.key === 'Enter' || e.keyCode === 13) {
      handleSubmitCancelOrder();
    }
  };

  const handleSubmitCancelOrder = () => {
    if (!cancelReason) return;
    props.onCancelled && props.onCancelled(props.order, cancelReason);
    setOpenCancel(false);
  };

  const orderItems = props.order.orderItems.map((item) => ({
    ...item,
    orderItem: menuItems?.data.find((r) => r.id === item.menuItemId),
  }));
  const paid = props.paidOrderTokens?.includes(props.order.token) || false;

  const orderDetailsClass = classNames({
    'order-details': true,
    'order-details--paid': paid,
  });

  return (
    <div className={orderDetailsClass}>
      <div className="information">
        <OrderDetailsHeading order={props.order} paid={paid} />
        <h5>{t('common.orderItems')}</h5>
        <div className={'order-items'}>
          {orderItems.map((row, index) => (
            <OrderLine orderLine={row} index={index} key={row.orderItem?.id} />
          ))}
          <hr />
          <OrderDetailsSummary order={props.order} />
          <OrderDetailsNotes order={props.order} />
          <OrderDetailsDelivery order={props.order} />
          {props.onCancelled && <hr />}
          {openCancel && (
            <TextInput
              type="text"
              autoFocus
              labelText={t('common.cancelReason')}
              helperText={t('common.cancelReasonDescribe')}
              onKeyUp={handleChangeCancelReason}
              onBlur={(e: any) => handleChangeCancelReason(e, true)}
            />
          )}
          {props.onCancelled && (
            <Row gap={0.5}>
              <Button
                renderIcon={RuleCancelled}
                disabled={!cancelReason?.trim() && openCancel}
                kind={openCancel && cancelReason.trim() ? 'primary' : 'secondary'}
                className={'cancel-button'}
                onClick={() => (openCancel ? handleSubmitCancelOrder() : setOpenCancel(true))}
              >
                {t('actions.cancelThisOrder')}
              </Button>
              <>
                {openCancel && (
                  <Button
                    renderIcon={Close}
                    kind={'secondary'}
                    hasIconOnly
                    iconDescription={t('actions.close')}
                    className={'cancel-button'}
                    onClick={() => setOpenCancel(false)}
                  ></Button>
                )}
              </>
            </Row>
          )}
        </div>
      </div>
      <div className="qr-payment">
        {!paid && (
          <QRCodeSVG
            value={props.order.paymentQRCode}
            size={210}
            level={'H'}
            bgColor={'#aaa'}
            includeMargin={true}
            imageSettings={{ src: '/dandan.png', width: 36, height: 36, excavate: false }}
          />
        )}
        {paid && (
          <div className="paid">
            <SuccessIcon />
          </div>
        )}
        <div>
          <span>{t('common.scanToPay')}</span>
          {props.onPaid && (
            <Button renderIcon={Money} onClick={() => (props.onPaid ? props.onPaid(props.order) : null)}>
              {t('actions.paid')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

export default OrderDetails;
