import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getRestaurantBranches,
  postRestaurantBranches,
  putRestaurantBranchesId,
  deleteRestaurantBranchesId,
} from 'src/services/apis/services';
import { RestaurantBranchCreateDto, RestaurantBranchUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';
import { getCurrentRestaurantBranchId, saveCurrentRestaurantBranchId } from 'src/utils/restaurant';

export const useBranches = () => {
  return useQuery([QUERIES.BRANCHES], () => getRestaurantBranches());
};

export const useCurrentRestaurantBranch = () => {
  const { data } = useBranches();
  if (data?.data && data?.data.length > 0) {
    const currentRestaurantBranchId = getCurrentRestaurantBranchId();
    if (currentRestaurantBranchId === 'All') {
      return null;
    }
    if (!currentRestaurantBranchId) {
      saveCurrentRestaurantBranchId(data.data[0].id);
      return data.data[0];
    }
    return data.data.find((item) => item.id === currentRestaurantBranchId) || data.data[0];
  }
  return null;
};

export const useCreateRestaurantBranchMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: RestaurantBranchCreateDto) => postRestaurantBranches(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.BRANCHES]);
    },
  });
};

export const useUpdateRestaurantBranchMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: RestaurantBranchUpdateDto) => putRestaurantBranchesId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.BRANCHES]);
    },
  });
};

export const useDeleteRestaurantBranchMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((branchId: string) => deleteRestaurantBranchesId(branchId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.BRANCHES]);
    },
  });
};
