import { Formik } from 'formik';
import React, { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack } from '@carbon/react';
import Spacing from 'src/components/Base/Spacing';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import { EmployeeRuleEntity } from 'src/services/apis/types';
import { NumberToK } from 'src/utils/price';

interface EmployeeRuleFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: EmployeeRuleEntity | null | undefined;
}

const BaseSchema = z.object({
  title: z.string().max(50).trim(),
  description: z.string().max(50).trim(),
  fine: z.preprocess((a) => parseFloat(`${a}`), z.number()),
});

const EmployeeRuleForm = (props: EmployeeRuleFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            id: '',
            title: '',
            fine: 0,
            description: '',
            tags: [],
          },
        }),
    };
  });

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(BaseSchema)}
        initialValues={{
          id: props.initialValues?.id,
          title: props.initialValues?.title,
          fine: props.initialValues ? NumberToK(props.initialValues?.fine) : 0,
          description: props.initialValues?.description,
          tags: props.initialValues?.tags?.join(','),
        }}
        onSubmit={props.onSubmit}
      >
        {() => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <FormikTextInput name={'title'} label={t('common.title')} />
              <FormikTextInput name={'description'} label={t('common.description')} />
              <FormikTextInput name={'fine'} isNumber max={50000} label={`${t('common.fine')} (K)`} />
              <FormikTextInput name={'tags'} label={`${t('common.tags')} (${t('common.separateByAComma')})`} />
            </Stack>
            <Spacing space={1.5} />
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(EmployeeRuleForm);
