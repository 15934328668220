import React from 'react';
import { Time, Gift, ShoppingBag, CheckmarkOutlineError } from '@carbon/react/icons';
import StatCard from 'src/components/Employee/StatCard';
import { NumberToK } from 'src/utils/price';

interface EmployeeBonusDetailsProps {}

const EmployeeBonusDetails = React.memo((props: EmployeeBonusDetailsProps) => {
  return (
    <div className={'employee-bonus-details'}>
      <h5 className={'section__heading'}>Your Bonus Details</h5>
      <div className="stats">
        <StatCard
          title={'Revenue KPIs'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<ShoppingBag size={36} />}
        />
        <StatCard
          title={'Issue KPIs'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<CheckmarkOutlineError size={36} />}
        />
        <StatCard
          title={'Quick Orders KPIs'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<Time size={36} />}
        />
        <StatCard
          title={'Extra Bonus'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<Gift size={36} />}
        />
      </div>
    </div>
  );
});

export default EmployeeBonusDetails;
