import React, { useMemo } from 'react';
import { MultiSelect } from '@carbon/react';
import { useBranches } from 'src/hooks/useRestaurantBranches';

interface RestaurantBranchesSelectorProps {
  value: string[];
  onChange: (value: string[]) => void;
  invalid?: boolean;
  invalidText?: string;
}

const RestaurantBranchesSelector = React.memo((props: RestaurantBranchesSelectorProps) => {
  const { data: branches } = useBranches();

  const branchSelectionItems = useMemo(() => {
    return branches?.data.map((item) => ({ id: item.id, text: item.name }));
  }, [branches?.data]);

  return (
    <MultiSelect
      items={branchSelectionItems}
      label={props.value?.length > 0 ? 'selected branches' : 'Select branches'}
      placeholder="Filter..."
      selectedItems={props.value ? branchSelectionItems?.filter((item) => props.value?.includes(item.id)) : []}
      onChange={(e) => {
        props.onChange(e.selectedItems.map((item) => item.id));
      }}
      itemToString={(item) => (item ? item.text : '')}
      titleText="Select branches"
      invalid={props.invalid}
      invalidText={props.invalidText}
    />
  );
});

export default RestaurantBranchesSelector;
