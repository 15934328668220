import React, { ReactElement, useEffect, useState } from 'react';

interface RowProps {
  gap: number;
  delay?: number;
  children: ReactElement[];
  spaceBetween?: boolean;
  alignCenter?: boolean;
  style?: React.CSSProperties;
}

const Row = (props: RowProps) => {
  const [displayed, setDisplayed] = useState(!props.delay);

  useEffect(() => {
    setTimeout(() => setDisplayed(true), props.delay);
  }, []);

  if (!displayed) return null;

  return (
    <div
      style={{
        display: 'flex',
        gap: `${props.gap}rem`,
        justifyContent: props.spaceBetween ? 'space-between' : 'initial',
        alignItems: props.alignCenter ? 'center' : 'initial',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export default Row;
