import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getRestaurantBranches, getRestaurants, postAuthEmailLogin } from 'src/services/apis/services';
import { AuthEmailLoginDto } from 'src/services/apis/types';
import { setAuthToken } from 'src/utils/auth';
import { QUERIES } from 'src/utils/react-query';
import { saveCurrentRestaurantBranchId, saveCurrentRestaurantId } from 'src/utils/restaurant';

export function useSignInMutation() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    (payload: AuthEmailLoginDto) =>
      postAuthEmailLogin({
        email: payload.email,
        password: payload.password,
      }),
    {
      onSuccess: async (data) => {
        setAuthToken(data.token);
        queryClient.setQueryData([QUERIES.AUTH_USER], () => ({ ...data.user }));
        let currentRestaurantId = data.user.managedRestaurantIds?.at(0);
        if (currentRestaurantId) {
          saveCurrentRestaurantId(currentRestaurantId);
          const restaurants = await getRestaurants();
          queryClient.setQueryData([QUERIES.MY_RESTAURANTS], () => restaurants);
          const restaurantBranches = await getRestaurantBranches();
          queryClient.setQueryData([QUERIES.BRANCHES], () => restaurantBranches);
          if (data.employee?.restaurantBranchId) {
            saveCurrentRestaurantBranchId(data.employee?.restaurantBranchId);
          } else {
            if (restaurants.data.length > 0) {
              const currentRestaurant = restaurants.data.find((item) => item.id === currentRestaurantId);
              if (currentRestaurant?.ownerId === data.user.id) {
                saveCurrentRestaurantBranchId('All');
              }
            }
          }
        }
        navigate('/dashboard/selling');
      },
    }
  );
}
