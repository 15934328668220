import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getMenu, deleteMenuId, putMenuId, postMenu } from 'src/services/apis/services';
import { MenuCreateDto, MenuUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useMenu = () => {
  return useQuery([QUERIES.MENU], () => getMenu());
};

export const useCreateMenuMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: MenuCreateDto) => postMenu(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU]);
    },
  });
};

export const useUpdateMenuMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: MenuUpdateDto) => putMenuId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU]);
    },
  });
};

export const useDeleteMenuMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((menuId: string) => deleteMenuId(menuId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU]);
    },
  });
};
