import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import {
  getEmployeeDayOff,
  postEmployeeDayOff,
  putEmployeeDayOffId,
  deleteEmployeeDayOffId,
} from 'src/services/apis/services';
import { EmployeeDayOffCreateDto, EmployeeDayOffUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useEmployeeDayOff = () => {
  return useQuery([QUERIES.EMPLOYEE_DAY_OFF], () => getEmployeeDayOff());
};

export const useEmployeeMonthlyDayOff = (employeeId: string | null) => {
  const [page] = useState(1);
  const [filters, setFilters] = useState<{
    limit: number;
    startDate: Date | null | string;
    endDate: Date | null | string;
    employeeId: string | null;
  }>({
    limit: 100,
    startDate: dayjs().startOf('month').toDate().toISOString(),
    endDate: dayjs().endOf('month').endOf('day').toDate().toISOString(),
    employeeId: null,
  });
  const getDefaultFilters = useCallback(() => {
    return {
      limit: 100,
      startDate: dayjs().startOf('month').toDate().toISOString(),
      endDate: dayjs().endOf('month').endOf('day').toDate().toISOString(),
      employeeId: employeeId,
    };
  }, [employeeId]);
  useEffect(() => {
    setFilters(getDefaultFilters());
  }, [employeeId]);
  const query = useQuery(
    [
      QUERIES.EMPLOYEE_MONTHLY_DAY_OFF,
      page,
      {
        employeeId: employeeId,
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    ],
    ({ queryKey: [_key, _page, _filters] }: { queryKey: any }) => {
      const convertedFilters: string[] = [];
      convertedFilters.push(`employeeId||$eq||${_filters.employeeId}`);
      convertedFilters.push(`date||$gte||${_filters.startDate}`);
      convertedFilters.push(`date||$lte||${_filters.endDate}`);
      return getEmployeeDayOff({
        page: _page as number,
        limit: _filters.limit,
        filter: convertedFilters,
      });
    },
    {
      cacheTime: 0,
      refetchOnMount: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!employeeId,
    }
  );
  return {
    query,
  };
};

export const useCreateEmployeeDayOffMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: EmployeeDayOffCreateDto) => postEmployeeDayOff(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEE_DAY_OFF]);
    },
  });
};

export const useUpdateEmployeeDayOffMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: EmployeeDayOffUpdateDto) => putEmployeeDayOffId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEE_DAY_OFF]);
    },
  });
};

export const useDeleteEmployeeDayOffMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((employeeId: string) => deleteEmployeeDayOffId(employeeId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEE_DAY_OFF]);
    },
  });
};
