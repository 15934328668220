import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import {
  Home,
  Account,
  Store,
  Finance,
  NoodleBowl,
  ShoppingCartPlus,
  Delivery,
  ResultNew,
  Scan,
  UserMultiple,
} from '@carbon/react/icons';
import MainSideNavSettings from 'src/components/Layout/MainSideNavSettings';
import { useAuthPermissions } from 'src/hooks/useAuthPermissions';

function SideNavItem({ link, label, active, icon }) {
  return (
    <SideNavLink large renderIcon={icon} className={active && 'cds--side-nav__link--current'} as={Link} to={link}>
      {label}
    </SideNavLink>
  );
}

export function MainSideNav(props: { expanded: any }) {
  const { isAdmin, isRestaurantOwner } = useAuthPermissions();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <SideNav isRail addMouseListeners={false} addFocusListeners={false} expanded={props.expanded}>
      <SideNavItems>
        <SideNavItem
          link={'/dashboard/selling'}
          label={t('sideNav.dashboard')}
          active={pathname.includes('/dashboard')}
          icon={Home}
        />
        <SideNavItem
          link={'/pos'}
          label={t('sideNav.pos')}
          active={pathname.includes('/pos')}
          icon={ShoppingCartPlus}
        />
        <SideNavItem
          link={'/delivery'}
          label={t('sideNav.delivery')}
          active={pathname.includes('/delivery')}
          icon={Delivery}
        />
        <SideNavItem
          link={'/kitchen'}
          label={t('sideNav.kitchen')}
          active={pathname.includes('/kitchen')}
          icon={Scan}
        />
        <SideNavItem
          link={'/orders/today'}
          label={t('sideNav.orders')}
          active={pathname.includes('/orders')}
          icon={Account}
        />
        <SideNavItem
          link={'/accounting/transactions'}
          label={t('sideNav.accounting')}
          active={pathname.includes('/accounting') && !pathname.includes('/dashboard')}
          icon={Finance}
        />
        <SideNavItem
          link={'/employees/all'}
          label={t('sideNav.employees')}
          active={pathname.includes('/employees') && !pathname.includes('/dashboard')}
          icon={UserMultiple}
        />
        {/*<SideNavItem*/}
        {/*  link={'/issues/all'}*/}
        {/*  label={t('sideNav.issues')}*/}
        {/*  active={pathname.includes('/issues') && !pathname.includes('/dashboard')}*/}
        {/*  icon={ResultNew}*/}
        {/*/>*/}
        {(isAdmin || isRestaurantOwner) && (
          <SideNavItem
            link={'/menu-settings'}
            label={t('sideNav.menuSettings')}
            active={pathname.includes('/menu-settings')}
            icon={NoodleBowl}
          />
        )}
        {(isAdmin || isRestaurantOwner) && (
          <SideNavItem
            link={'/restaurant-settings'}
            label={t('sideNav.restaurantSettings')}
            active={pathname.includes('/restaurant-settings')}
            icon={Store}
          />
        )}
        <MainSideNavSettings />
      </SideNavItems>
    </SideNav>
  );
}
