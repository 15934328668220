import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import { useDebounce } from 'src/hooks/useUtils';
import {
  getOrders,
  getOrdersDelivery,
  getOrdersRecent,
  postOrders,
  putOrdersId,
  getOrdersInProgress,
  getOrdersPaidOrderTokens,
} from 'src/services/apis/services';
import { OrderCreateDto, OrderEntity, OrderUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useRecentOrders = () => {
  return useQuery([QUERIES.RECENT_ORDERS], () => getOrdersRecent({ limit: 30 }), {
    cacheTime: 0,
    refetchOnMount: true,
    refetchInterval: 10000,
  });
};

export const useDeliveryOrders = () => {
  return useQuery([QUERIES.DELIVERY_ORDERS], () => getOrdersDelivery({ limit: 50 }), {
    cacheTime: 0,
    refetchOnMount: true,
    refetchInterval: 10000,
  });
};

export const useInProgressOrders = () => {
  return useQuery([QUERIES.IN_PROGRESS_ORDERS], () => getOrdersInProgress({ limit: 100 }), {
    cacheTime: 0,
    refetchOnMount: true,
    refetchInterval: 5000,
  });
};
export const usePaidOrderTokens = () => {
  return useQuery([QUERIES.PAID_ORDER_TOKENS], () => getOrdersPaidOrderTokens(), {
    cacheTime: 0,
    refetchOnMount: true,
    refetchInterval: 1000,
    refetchOnWindowFocus: true,
  });
};

export const useOrders = (today: boolean) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{
    limit: number;
    type: string | null;
    status: string | null;
    search: string | null;
    startDate: string | null;
    endDate: string | null;
  }>({
    limit: 20,
    type: null,
    status: null,
    search: null,
    startDate: today ? dayjs().startOf('day').toISOString() : null,
    endDate: today ? dayjs().endOf('day').toISOString() : null,
  });
  const getDefaultFilters = useCallback(() => {
    return {
      limit: 20,
      type: null,
      status: null,
      search: null,
      startDate: today ? dayjs().startOf('day').toISOString() : null,
      endDate: today ? dayjs().endOf('day').toISOString() : null,
    };
  }, [today]);
  useEffect(() => {
    setFilters(getDefaultFilters());
  }, [today]);
  const debounceSearch = useDebounce(filters.search, 1000);
  const query = useQuery(
    [
      QUERIES.ORDERS,
      page,
      {
        limit: filters.limit,
        type: filters.type,
        status: filters.status,
        search: debounceSearch,
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    ],
    ({ queryKey: [_key, _page, _filters] }) => getOrders({ page: _page as number, ...(_filters as object) }),
    {
      cacheTime: 0,
      refetchOnMount: true,
      refetchInterval: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return {
    query,
    page,
    filters,
    setPage,
    setFilters,
  };
};

export const useCreateOrdersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: OrderCreateDto) => postOrders(payload), {
    onSuccess: async (order: OrderEntity) => {
      await queryClient.cancelQueries({ queryKey: [QUERIES.RECENT_ORDERS] });
      const previousRecentOrders = queryClient.getQueryData([QUERIES.RECENT_ORDERS]);
      if (previousRecentOrders) {
        queryClient.setQueryData([QUERIES.RECENT_ORDERS], (old: any) => ({
          ...old,
          total: old.total + 1,
          data: [...old.data, ...[order]],
        }));
        return { previousRecentOrders };
      }
      await queryClient.refetchQueries( [QUERIES.RECENT_ORDERS])
    },
    onError: () => {
      queryClient.invalidateQueries([QUERIES.RECENT_ORDERS]).then((r) => r);
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERIES.RECENT_ORDERS]).then((r) => r);
    },
    retry: false,
  });
};

export const useUpdateOrdersMutation = (type?: OrderTypeEnum) => {
  const queryClient = useQueryClient();
  const updateQueries = type && type === OrderTypeEnum.DELIVERY ? [QUERIES.DELIVERY_ORDERS] : [QUERIES.RECENT_ORDERS];
  return useMutation((payload: OrderUpdateDto) => putOrdersId(payload.id, payload), {
    onMutate: async (data) => {
      const previousOrders = queryClient.getQueryData(updateQueries);
      await queryClient.cancelQueries({ queryKey: updateQueries });
      if (data.status && [OrderStatusEnum.PAID, OrderStatusEnum.CANCELLED].includes(data.status)) {
        queryClient.setQueryData(updateQueries, (old: any) => ({
          ...old,
          data: [...old.data.filter((item) => item.id !== data.id)],
        }));
        return { previousOrders };
      }
      if (data.orderItems && data.orderItems?.length > 0) {
        return;
      }
      queryClient.setQueryData(updateQueries, (old: any) => ({
        ...old,
        data: [...old.data.map((item) => (item.id === data.id ? { ...item, ...data } : item))],
      }));
      return { previousOrders };
    },
    onSuccess: async (order: OrderEntity, variables) => {
      const previousOrders = queryClient.getQueryData(updateQueries);
      if (!variables.orderItems || variables.orderItems?.length === 0) {
        return;
      }
      await queryClient.cancelQueries({ queryKey: updateQueries });
      queryClient.setQueryData(updateQueries, (old: any) => ({
        ...old,
        data: [...old.data.map((item) => (item.id === order.id ? { ...order } : item))],
      }));
      return { previousOrders };
    },
    onError: () => {
      return queryClient.refetchQueries(updateQueries);
    },
  });
};
export const useMarkAsReadyOrdersMutation = () => {
  const queryClient = useQueryClient();
  const updateQueries = [QUERIES.IN_PROGRESS_ORDERS];
  return useMutation(
    (payload: OrderUpdateDto) => putOrdersId(payload.id, { id: payload.id, status: OrderStatusEnum.READY }),
    {
      onMutate: async (data) => {
        const previousOrders = queryClient.getQueryData(updateQueries);
        await queryClient.cancelQueries({ queryKey: updateQueries });
        queryClient.setQueryData(updateQueries, (old: any) => ({
          ...old,
          data: [...old.data.filter((item: OrderEntity) => item.id !== data.id)],
        }));
        return { previousOrders };
      },
      onError: async () => {
        return queryClient.resetQueries({ queryKey: updateQueries });
      },
    }
  );
};

export const useCancelOrdersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: OrderUpdateDto) => putOrdersId(payload.id, payload), {
    onSuccess: async () => {
      return queryClient.refetchQueries([QUERIES.ORDERS]);
    },
  });
};
