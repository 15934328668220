export enum OrderStatusEnum {
  IN_PROCESS = 1,
  READY = 2,
  PAID = 3,
  CANCELLED = 4,
}

export enum OrderTypeEnum {
  DINE_IN = 1,
  TAKEAWAY = 2,
  DELIVERY = 3,
  OTHER = 4,
}
