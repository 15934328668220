import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem } from '@carbon/react';
import { orderTypeOptions } from 'src/utils/order';

interface OrderTypeSelectorProps {
  value: string | undefined | null;
  onChange: (value: string | null) => void;
}

const OrderTypeSelector = React.memo((props: OrderTypeSelectorProps) => {
  const { t } = useTranslation();
  const orderTypesSelectionItems = useMemo(() => {
    return [...[{ id: null, text: 'common.allOrderTypes' }], ...orderTypeOptions].map((item) => ({
      id: item.id,
      text: t(item.text),
    }));
  }, []);

  return (
    <Select
      labelText="Select an option"
      hideLabel
      onChange={(e: any) => props.onChange(e.target.value)}
      defaultValue={props.value}
    >
      {orderTypesSelectionItems?.map((item) => {
        return <SelectItem value={item.id} key={item.id} text={item.text} />;
      })}
    </Select>
  );
});

export default OrderTypeSelector;
