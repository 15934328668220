//@ts-nocheck

/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */
import type { AxiosRequestConfig } from 'axios';
//@ts-ignore
import qs from 'qs';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
import type {
  GetUsersQueryParams,
  GetRestaurantsQueryParams,
  GetRestaurantsIdQueryParams,
  GetRestaurantBranchesIdQueryParams,
  GetRestaurantBranchesQueryParams,
  GetRestaurantTablesIdQueryParams,
  GetRestaurantTablesQueryParams,
  GetMenuIdQueryParams,
  GetMenuQueryParams,
  GetMenuItemsIdQueryParams,
  GetMenuItemsQueryParams,
  GetMenuItemVariantsIdQueryParams,
  GetMenuItemVariantsQueryParams,
  GetEmployeesQueryParams,
  GetEmployeesIdQueryParams,
  GetOrdersQueryParams,
  GetOrdersRecentQueryParams,
  GetOrdersDeliveryQueryParams,
  GetOrdersInProgressQueryParams,
  GetTransactionsIdQueryParams,
  GetTransactionsQueryParams,
  GetTransactionCategoriesIdQueryParams,
  GetTransactionCategoriesQueryParams,
  GetIssuesIdQueryParams,
  GetIssuesQueryParams,
  GetDashboardSalesReportsQueryParams,
  GetDashboardAccountingReportsQueryParams,
  GetDashboardEmployeeIncomesQueryParams,
  GetEmployeeRulesIdQueryParams,
  GetEmployeeRulesQueryParams,
  GetEmployeeDayOffIdQueryParams,
  GetEmployeeDayOffQueryParams,
  CreateUserDto,
  UpdateUserDto,
  AuthEmailLoginDto,
  UserEntity,
  GetManyEmployeeEntityResponseDto,
  EmployeeEntity,
  AuthEmailLoginResponse,
  RestaurantCreateDto,
  RestaurantUpdateDto,
  GetManyRestaurantEntityResponseDto,
  RestaurantEntity,
  CreateManyRestaurantEntityDto,
  GetManyRestaurantBranchEntityResponseDto,
  RestaurantBranchEntity,
  RestaurantBranchCreateDto,
  CreateManyRestaurantBranchEntityDto,
  RestaurantBranchUpdateDto,
  GetManyRestaurantTableEntityResponseDto,
  RestaurantTableEntity,
  RestaurantTableCreateDto,
  CreateManyRestaurantTableEntityDto,
  RestaurantTableUpdateDto,
  GetManyMenuEntityResponseDto,
  MenuEntity,
  MenuCreateDto,
  CreateManyMenuEntityDto,
  MenuUpdateDto,
  GetManyMenuItemEntityResponseDto,
  MenuItemEntity,
  MenuItemCreateDto,
  CreateManyMenuItemEntityDto,
  MenuItemUpdateDto,
  GetManyMenuItemVariantEntityResponseDto,
  MenuItemVariantEntity,
  MenuItemVariantCreateDto,
  CreateManyMenuItemVariantEntityDto,
  MenuItemVariantUpdateDto,
  UploadSignature,
  EmployeeCreateDto,
  EmployeeUpdateDto,
  EmployeeChangePasswordDto,
  CreateManyEmployeeEntityDto,
  OrderCreateDto,
  OrderEntity,
  OrderUpdateDto,
  GetManyTransactionEntityResponseDto,
  TransactionEntity,
  TransactionCreateDto,
  CreateManyTransactionEntityDto,
  TransactionUpdateDto,
  GetManyTransactionCategoryEntityResponseDto,
  TransactionCategoryEntity,
  TransactionCategoryCreateDto,
  CreateManyTransactionCategoryEntityDto,
  TransactionCategoryUpdateDto,
  GetManyIssueEntityResponseDto,
  GetManyEmployeeRuleEntityResponseDto,
  EmployeeRuleEntity,
  IssueEntity,
  IssueCreateDto,
  CreateManyIssueEntityDto,
  IssueUpdateDto,
  CreateBankingNotificationDto,
  EmployeeRuleCreateDto,
  CreateManyEmployeeRuleEntityDto,
  EmployeeRuleUpdateDto,
  GetManyEmployeeDayOffEntityResponseDto,
  EmployeeDayOffEntity,
  EmployeeDayOffCreateDto,
  CreateManyEmployeeDayOffEntityDto,
  EmployeeDayOffUpdateDto,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(config?: AxiosRequestConfig, configOverride?: AxiosRequestConfig): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * Delete a single EmployeeDayOffEntity
 */
export const deleteEmployeeDayOffId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteEmployeeDayOffId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteEmployeeDayOffId.key = '/api/v1/employee-day-off/{id}';

/**
 *
 * Delete a single EmployeeRuleEntity
 */
export const deleteEmployeeRulesId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteEmployeeRulesId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteEmployeeRulesId.key = '/api/v1/employee-rules/{id}';

/**
 *
 * Delete a single EmployeeEntity
 */
export const deleteEmployeesId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteEmployeesId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteEmployeesId.key = '/api/v1/employees/{id}';

/**
 *
 * Delete a single IssueEntity
 */
export const deleteIssuesId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteIssuesId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteIssuesId.key = '/api/v1/issues/{id}';

/**
 *
 * Delete a single MenuEntity
 */
export const deleteMenuId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMenuId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteMenuId.key = '/api/v1/menu/{id}';

/**
 *
 * Delete a single MenuItemVariantEntity
 */
export const deleteMenuItemVariantsId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMenuItemVariantsId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteMenuItemVariantsId.key = '/api/v1/menu-item-variants/{id}';

/**
 *
 * Delete a single MenuItemEntity
 */
export const deleteMenuItemsId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMenuItemsId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteMenuItemsId.key = '/api/v1/menu-items/{id}';

/**
 *
 * Delete a single RestaurantBranchEntity
 */
export const deleteRestaurantBranchesId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteRestaurantBranchesId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteRestaurantBranchesId.key = '/api/v1/restaurant-branches/{id}';

/**
 *
 * Delete a single RestaurantTableEntity
 */
export const deleteRestaurantTablesId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteRestaurantTablesId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteRestaurantTablesId.key = '/api/v1/restaurant-tables/{id}';

/**
 *
 * Delete a single RestaurantEntity
 */
export const deleteRestaurantsId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteRestaurantsId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteRestaurantsId.key = '/api/v1/restaurants/{id}';

/**
 *
 * Delete a single TransactionCategoryEntity
 */
export const deleteTransactionCategoriesId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTransactionCategoriesId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTransactionCategoriesId.key = '/api/v1/transaction-categories/{id}';

/**
 *
 * Delete a single TransactionEntity
 */
export const deleteTransactionsId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTransactionsId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTransactionsId.key = '/api/v1/transactions/{id}';

export const deleteUsersId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteUsersId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteUsersId.key = '/api/v1/users/{id}';

export const get = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(get.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
get.key = '/';

export const getAuthMe = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<UserEntity>> => {
  return Http.getRequest(getAuthMe.key, undefined, undefined, _CONSTANT1, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getAuthMe.key = '/api/v1/auth/me';

export const getDashboardAccountingReports = (
  queryParams: GetDashboardAccountingReportsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getDashboardAccountingReports.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDashboardAccountingReports.key = '/api/v1/dashboard/accounting-reports';

export const getDashboardEmployeeIncomes = (
  queryParams: GetDashboardEmployeeIncomesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getDashboardEmployeeIncomes.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDashboardEmployeeIncomes.key = '/api/v1/dashboard/employee-incomes';

export const getDashboardSalesReports = (
  queryParams: GetDashboardSalesReportsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getDashboardSalesReports.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDashboardSalesReports.key = '/api/v1/dashboard/sales-reports';

/**
 *
 * Retrieve multiple EmployeeDayOffEntities
 */
export const getEmployeeDayOff = (
  queryParams?: GetEmployeeDayOffQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyEmployeeDayOffEntityResponseDto>> => {
  return Http.getRequest(
    getEmployeeDayOff.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeeDayOff.key = '/api/v1/employee-day-off';

/**
 *
 * Retrieve a single EmployeeDayOffEntity
 */
export const getEmployeeDayOffId = (
  id: string,
  queryParams?: GetEmployeeDayOffIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeDayOffEntity>> => {
  return Http.getRequest(
    template(getEmployeeDayOffId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeeDayOffId.key = '/api/v1/employee-day-off/{id}';

/**
 *
 * Retrieve multiple EmployeeRuleEntities
 */
export const getEmployeeRules = (
  queryParams?: GetEmployeeRulesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyEmployeeRuleEntityResponseDto>> => {
  return Http.getRequest(
    getEmployeeRules.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeeRules.key = '/api/v1/employee-rules';

/**
 *
 * Retrieve a single EmployeeRuleEntity
 */
export const getEmployeeRulesId = (
  id: string,
  queryParams?: GetEmployeeRulesIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeRuleEntity>> => {
  return Http.getRequest(
    template(getEmployeeRulesId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeeRulesId.key = '/api/v1/employee-rules/{id}';

/**
 *
 * Retrieve multiple EmployeeEntities
 */
export const getEmployees = (
  queryParams?: GetEmployeesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyEmployeeEntityResponseDto>> => {
  return Http.getRequest(
    getEmployees.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployees.key = '/api/v1/employees';

/**
 *
 * Retrieve a single EmployeeEntity
 */
export const getEmployeesId = (
  id: string,
  queryParams?: GetEmployeesIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeEntity>> => {
  return Http.getRequest(
    template(getEmployeesId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesId.key = '/api/v1/employees/{id}';

/**
 *
 * Retrieve multiple IssueEntities
 */
export const getIssues = (
  queryParams?: GetIssuesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyIssueEntityResponseDto>> => {
  return Http.getRequest(getIssues.key, queryParams, undefined, _CONSTANT1, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getIssues.key = '/api/v1/issues';

/**
 *
 * Retrieve a single IssueEntity
 */
export const getIssuesId = (
  id: string,
  queryParams?: GetIssuesIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<IssueEntity>> => {
  return Http.getRequest(
    template(getIssuesId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getIssuesId.key = '/api/v1/issues/{id}';

export const getMediaSignature = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<UploadSignature>> => {
  return Http.getRequest(
    getMediaSignature.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getMediaSignature.key = '/api/v1/media/signature';

/**
 *
 * Retrieve multiple MenuEntities
 */
export const getMenu = (
  queryParams?: GetMenuQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyMenuEntityResponseDto>> => {
  return Http.getRequest(getMenu.key, queryParams, undefined, _CONSTANT1, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getMenu.key = '/api/v1/menu';

/**
 *
 * Retrieve a single MenuEntity
 */
export const getMenuId = (
  id: string,
  queryParams?: GetMenuIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuEntity>> => {
  return Http.getRequest(
    template(getMenuId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getMenuId.key = '/api/v1/menu/{id}';

/**
 *
 * Retrieve multiple MenuItemVariantEntities
 */
export const getMenuItemVariants = (
  queryParams?: GetMenuItemVariantsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyMenuItemVariantEntityResponseDto>> => {
  return Http.getRequest(
    getMenuItemVariants.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getMenuItemVariants.key = '/api/v1/menu-item-variants';

/**
 *
 * Retrieve a single MenuItemVariantEntity
 */
export const getMenuItemVariantsId = (
  id: string,
  queryParams?: GetMenuItemVariantsIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuItemVariantEntity>> => {
  return Http.getRequest(
    template(getMenuItemVariantsId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getMenuItemVariantsId.key = '/api/v1/menu-item-variants/{id}';

/**
 *
 * Retrieve multiple MenuItemEntities
 */
export const getMenuItems = (
  queryParams?: GetMenuItemsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyMenuItemEntityResponseDto>> => {
  return Http.getRequest(
    getMenuItems.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getMenuItems.key = '/api/v1/menu-items';

/**
 *
 * Retrieve a single MenuItemEntity
 */
export const getMenuItemsId = (
  id: string,
  queryParams?: GetMenuItemsIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuItemEntity>> => {
  return Http.getRequest(
    template(getMenuItemsId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getMenuItemsId.key = '/api/v1/menu-items/{id}';

export const getOrders = (
  queryParams?: GetOrdersQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ data?: OrderEntity[]; limit?: number; page?: number; total?: number }>> => {
  return Http.getRequest(getOrders.key, queryParams, undefined, _CONSTANT1, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getOrders.key = '/api/v1/orders';

export const getOrdersDelivery = (
  queryParams: GetOrdersDeliveryQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ data?: OrderEntity[]; limit?: number; page?: number; total?: number }>> => {
  return Http.getRequest(
    getOrdersDelivery.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrdersDelivery.key = '/api/v1/orders/delivery';

export const getOrdersId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getOrdersId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrdersId.key = '/api/v1/orders/{id}';

export const getOrdersInProgress = (
  queryParams: GetOrdersInProgressQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ data?: OrderEntity[]; limit?: number; page?: number; total?: number }>> => {
  return Http.getRequest(
    getOrdersInProgress.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrdersInProgress.key = '/api/v1/orders/in-progress';

export const getOrdersPaidOrderTokens = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<[]>> => {
  return Http.getRequest(
    getOrdersPaidOrderTokens.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrdersPaidOrderTokens.key = '/api/v1/orders/paid-order-tokens';

export const getOrdersRecent = (
  queryParams: GetOrdersRecentQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ data?: OrderEntity[]; limit?: number; page?: number; total?: number }>> => {
  return Http.getRequest(
    getOrdersRecent.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrdersRecent.key = '/api/v1/orders/recent';

/**
 *
 * Retrieve multiple RestaurantBranchEntities
 */
export const getRestaurantBranches = (
  queryParams?: GetRestaurantBranchesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyRestaurantBranchEntityResponseDto>> => {
  return Http.getRequest(
    getRestaurantBranches.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRestaurantBranches.key = '/api/v1/restaurant-branches';

/**
 *
 * Retrieve a single RestaurantBranchEntity
 */
export const getRestaurantBranchesId = (
  id: string,
  queryParams?: GetRestaurantBranchesIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantBranchEntity>> => {
  return Http.getRequest(
    template(getRestaurantBranchesId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRestaurantBranchesId.key = '/api/v1/restaurant-branches/{id}';

/**
 *
 * Retrieve multiple RestaurantTableEntities
 */
export const getRestaurantTables = (
  queryParams?: GetRestaurantTablesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyRestaurantTableEntityResponseDto>> => {
  return Http.getRequest(
    getRestaurantTables.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRestaurantTables.key = '/api/v1/restaurant-tables';

/**
 *
 * Retrieve a single RestaurantTableEntity
 */
export const getRestaurantTablesId = (
  id: string,
  queryParams?: GetRestaurantTablesIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantTableEntity>> => {
  return Http.getRequest(
    template(getRestaurantTablesId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRestaurantTablesId.key = '/api/v1/restaurant-tables/{id}';

/**
 *
 * Retrieve multiple RestaurantEntities
 */
export const getRestaurants = (
  queryParams?: GetRestaurantsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyRestaurantEntityResponseDto>> => {
  return Http.getRequest(
    getRestaurants.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRestaurants.key = '/api/v1/restaurants';

/**
 *
 * Retrieve a single RestaurantEntity
 */
export const getRestaurantsId = (
  id: string,
  queryParams?: GetRestaurantsIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantEntity>> => {
  return Http.getRequest(
    template(getRestaurantsId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRestaurantsId.key = '/api/v1/restaurants/{id}';

/**
 *
 * Retrieve multiple TransactionCategoryEntities
 */
export const getTransactionCategories = (
  queryParams?: GetTransactionCategoriesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyTransactionCategoryEntityResponseDto>> => {
  return Http.getRequest(
    getTransactionCategories.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionCategories.key = '/api/v1/transaction-categories';

/**
 *
 * Retrieve a single TransactionCategoryEntity
 */
export const getTransactionCategoriesId = (
  id: string,
  queryParams?: GetTransactionCategoriesIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionCategoryEntity>> => {
  return Http.getRequest(
    template(getTransactionCategoriesId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionCategoriesId.key = '/api/v1/transaction-categories/{id}';

/**
 *
 * Retrieve multiple TransactionEntities
 */
export const getTransactions = (
  queryParams?: GetTransactionsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetManyTransactionEntityResponseDto>> => {
  return Http.getRequest(
    getTransactions.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactions.key = '/api/v1/transactions';

/**
 *
 * Retrieve a single TransactionEntity
 */
export const getTransactionsId = (
  id: string,
  queryParams?: GetTransactionsIdQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionEntity>> => {
  return Http.getRequest(
    template(getTransactionsId.key, { id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsId.key = '/api/v1/transactions/{id}';

export const getUsers = (
  queryParams: GetUsersQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getUsers.key, queryParams, undefined, _CONSTANT1, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getUsers.key = '/api/v1/users';

export const getUsersId = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getUsersId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUsersId.key = '/api/v1/users/{id}';

/**
 *
 * Update a single EmployeeDayOffEntity
 */
export const patchEmployeeDayOffId = (
  id: string,
  requestBody: EmployeeDayOffUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeDayOffEntity>> => {
  return Http.patchRequest(
    template(patchEmployeeDayOffId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchEmployeeDayOffId.key = '/api/v1/employee-day-off/{id}';

/**
 *
 * Recover one EmployeeDayOffEntity
 */
export const patchEmployeeDayOffIdRecover = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchEmployeeDayOffIdRecover.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchEmployeeDayOffIdRecover.key = '/api/v1/employee-day-off/{id}/recover';

/**
 *
 * Update a single EmployeeRuleEntity
 */
export const patchEmployeeRulesId = (
  id: string,
  requestBody: EmployeeRuleUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeRuleEntity>> => {
  return Http.patchRequest(
    template(patchEmployeeRulesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchEmployeeRulesId.key = '/api/v1/employee-rules/{id}';

/**
 *
 * Recover one EmployeeRuleEntity
 */
export const patchEmployeeRulesIdRecover = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchEmployeeRulesIdRecover.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchEmployeeRulesIdRecover.key = '/api/v1/employee-rules/{id}/recover';

/**
 *
 * Update a single EmployeeEntity
 */
export const patchEmployeesId = (
  id: string,
  requestBody: EmployeeUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeEntity>> => {
  return Http.patchRequest(
    template(patchEmployeesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchEmployeesId.key = '/api/v1/employees/{id}';

/**
 *
 * Update a single IssueEntity
 */
export const patchIssuesId = (
  id: string,
  requestBody: IssueUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<IssueEntity>> => {
  return Http.patchRequest(
    template(patchIssuesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchIssuesId.key = '/api/v1/issues/{id}';

/**
 *
 * Recover one IssueEntity
 */
export const patchIssuesIdRecover = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchIssuesIdRecover.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchIssuesIdRecover.key = '/api/v1/issues/{id}/recover';

/**
 *
 * Update a single MenuEntity
 */
export const patchMenuId = (
  id: string,
  requestBody: MenuUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuEntity>> => {
  return Http.patchRequest(
    template(patchMenuId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchMenuId.key = '/api/v1/menu/{id}';

/**
 *
 * Recover one MenuEntity
 */
export const patchMenuIdRecover = (id: string, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchMenuIdRecover.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchMenuIdRecover.key = '/api/v1/menu/{id}/recover';

/**
 *
 * Update a single MenuItemVariantEntity
 */
export const patchMenuItemVariantsId = (
  id: string,
  requestBody: MenuItemVariantUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuItemVariantEntity>> => {
  return Http.patchRequest(
    template(patchMenuItemVariantsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchMenuItemVariantsId.key = '/api/v1/menu-item-variants/{id}';

/**
 *
 * Update a single MenuItemEntity
 */
export const patchMenuItemsId = (
  id: string,
  requestBody: MenuItemUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuItemEntity>> => {
  return Http.patchRequest(
    template(patchMenuItemsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchMenuItemsId.key = '/api/v1/menu-items/{id}';

/**
 *
 * Recover one MenuItemEntity
 */
export const patchMenuItemsIdRecover = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchMenuItemsIdRecover.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchMenuItemsIdRecover.key = '/api/v1/menu-items/{id}/recover';

/**
 *
 * Update a single RestaurantBranchEntity
 */
export const patchRestaurantBranchesId = (
  id: string,
  requestBody: RestaurantBranchUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantBranchEntity>> => {
  return Http.patchRequest(
    template(patchRestaurantBranchesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchRestaurantBranchesId.key = '/api/v1/restaurant-branches/{id}';

/**
 *
 * Update a single RestaurantTableEntity
 */
export const patchRestaurantTablesId = (
  id: string,
  requestBody: RestaurantTableUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantTableEntity>> => {
  return Http.patchRequest(
    template(patchRestaurantTablesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchRestaurantTablesId.key = '/api/v1/restaurant-tables/{id}';

/**
 *
 * Update a single RestaurantEntity
 */
export const patchRestaurantsId = (
  id: string,
  requestBody: RestaurantUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantEntity>> => {
  return Http.patchRequest(
    template(patchRestaurantsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchRestaurantsId.key = '/api/v1/restaurants/{id}';

/**
 *
 * Update a single TransactionCategoryEntity
 */
export const patchTransactionCategoriesId = (
  id: string,
  requestBody: TransactionCategoryUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionCategoryEntity>> => {
  return Http.patchRequest(
    template(patchTransactionCategoriesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchTransactionCategoriesId.key = '/api/v1/transaction-categories/{id}';

/**
 *
 * Recover one TransactionCategoryEntity
 */
export const patchTransactionCategoriesIdRecover = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchTransactionCategoriesIdRecover.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchTransactionCategoriesIdRecover.key = '/api/v1/transaction-categories/{id}/recover';

/**
 *
 * Update a single TransactionEntity
 */
export const patchTransactionsId = (
  id: string,
  requestBody: TransactionUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionEntity>> => {
  return Http.patchRequest(
    template(patchTransactionsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchTransactionsId.key = '/api/v1/transactions/{id}';

/**
 *
 * Recover one TransactionEntity
 */
export const patchTransactionsIdRecover = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchTransactionsIdRecover.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchTransactionsIdRecover.key = '/api/v1/transactions/{id}/recover';

export const patchUsersId = (
  id: string,
  requestBody: UpdateUserDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.patchRequest(
    template(patchUsersId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchUsersId.key = '/api/v1/users/{id}';

export const postAuthAdminEmailLogin = (
  requestBody: AuthEmailLoginDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthEmailLoginResponse>> => {
  return Http.postRequest(
    postAuthAdminEmailLogin.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAuthAdminEmailLogin.key = '/api/v1/auth/admin/email/login';

export const postAuthEmailLogin = (
  requestBody: AuthEmailLoginDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthEmailLoginResponse>> => {
  return Http.postRequest(
    postAuthEmailLogin.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAuthEmailLogin.key = '/api/v1/auth/email/login';

export const postBankingNotifications = (
  requestBody: CreateBankingNotificationDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postBankingNotifications.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postBankingNotifications.key = '/api/v1/banking/notifications';

export const postE2eClearTestRecords = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postE2eClearTestRecords.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postE2eClearTestRecords.key = '/api/v1/e2e/clear-test-records';

/**
 *
 * Create a single EmployeeDayOffEntity
 */
export const postEmployeeDayOff = (
  requestBody: EmployeeDayOffCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployeeDayOff.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeeDayOff.key = '/api/v1/employee-day-off';

/**
 *
 * Create multiple EmployeeDayOffEntities
 */
export const postEmployeeDayOffBulk = (
  requestBody: CreateManyEmployeeDayOffEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployeeDayOffBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeeDayOffBulk.key = '/api/v1/employee-day-off/bulk';

/**
 *
 * Create a single EmployeeRuleEntity
 */
export const postEmployeeRules = (
  requestBody: EmployeeRuleCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployeeRules.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeeRules.key = '/api/v1/employee-rules';

/**
 *
 * Create multiple EmployeeRuleEntities
 */
export const postEmployeeRulesBulk = (
  requestBody: CreateManyEmployeeRuleEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployeeRulesBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeeRulesBulk.key = '/api/v1/employee-rules/bulk';

/**
 *
 * Create a single EmployeeEntity
 */
export const postEmployees = (
  requestBody: EmployeeCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployees.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployees.key = '/api/v1/employees';

/**
 *
 * Create multiple EmployeeEntities
 */
export const postEmployeesBulk = (
  requestBody: CreateManyEmployeeEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployeesBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeesBulk.key = '/api/v1/employees/bulk';

export const postEmployeesChangePassword = (
  requestBody: EmployeeChangePasswordDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployeesChangePassword.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeesChangePassword.key = '/api/v1/employees/change-password';

/**
 *
 * Create a single IssueEntity
 */
export const postIssues = (
  requestBody: IssueCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postIssues.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postIssues.key = '/api/v1/issues';

/**
 *
 * Create multiple IssueEntities
 */
export const postIssuesBulk = (
  requestBody: CreateManyIssueEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postIssuesBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postIssuesBulk.key = '/api/v1/issues/bulk';

/**
 *
 * Create a single MenuEntity
 */
export const postMenu = (
  requestBody: MenuCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postMenu.key, undefined, requestBody, _CONSTANT1, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postMenu.key = '/api/v1/menu';

/**
 *
 * Create multiple MenuEntities
 */
export const postMenuBulk = (
  requestBody: CreateManyMenuEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMenuBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postMenuBulk.key = '/api/v1/menu/bulk';

/**
 *
 * Create a single MenuItemVariantEntity
 */
export const postMenuItemVariants = (
  requestBody: MenuItemVariantCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMenuItemVariants.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postMenuItemVariants.key = '/api/v1/menu-item-variants';

/**
 *
 * Create multiple MenuItemVariantEntities
 */
export const postMenuItemVariantsBulk = (
  requestBody: CreateManyMenuItemVariantEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMenuItemVariantsBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postMenuItemVariantsBulk.key = '/api/v1/menu-item-variants/bulk';

/**
 *
 * Create a single MenuItemEntity
 */
export const postMenuItems = (
  requestBody: MenuItemCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMenuItems.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postMenuItems.key = '/api/v1/menu-items';

/**
 *
 * Create multiple MenuItemEntities
 */
export const postMenuItemsBulk = (
  requestBody: CreateManyMenuItemEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMenuItemsBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postMenuItemsBulk.key = '/api/v1/menu-items/bulk';

export const postOrders = (
  requestBody: OrderCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postOrders.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOrders.key = '/api/v1/orders';

/**
 *
 * Create a single RestaurantBranchEntity
 */
export const postRestaurantBranches = (
  requestBody: RestaurantBranchCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postRestaurantBranches.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postRestaurantBranches.key = '/api/v1/restaurant-branches';

/**
 *
 * Create multiple RestaurantBranchEntities
 */
export const postRestaurantBranchesBulk = (
  requestBody: CreateManyRestaurantBranchEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postRestaurantBranchesBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postRestaurantBranchesBulk.key = '/api/v1/restaurant-branches/bulk';

/**
 *
 * Create a single RestaurantTableEntity
 */
export const postRestaurantTables = (
  requestBody: RestaurantTableCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postRestaurantTables.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postRestaurantTables.key = '/api/v1/restaurant-tables';

/**
 *
 * Create multiple RestaurantTableEntities
 */
export const postRestaurantTablesBulk = (
  requestBody: CreateManyRestaurantTableEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postRestaurantTablesBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postRestaurantTablesBulk.key = '/api/v1/restaurant-tables/bulk';

/**
 *
 * Create a single RestaurantEntity
 */
export const postRestaurants = (
  requestBody: RestaurantCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postRestaurants.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postRestaurants.key = '/api/v1/restaurants';

/**
 *
 * Create multiple RestaurantEntities
 */
export const postRestaurantsBulk = (
  requestBody: CreateManyRestaurantEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postRestaurantsBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postRestaurantsBulk.key = '/api/v1/restaurants/bulk';

/**
 *
 * Create a single TransactionCategoryEntity
 */
export const postTransactionCategories = (
  requestBody: TransactionCategoryCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postTransactionCategories.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTransactionCategories.key = '/api/v1/transaction-categories';

/**
 *
 * Create multiple TransactionCategoryEntities
 */
export const postTransactionCategoriesBulk = (
  requestBody: CreateManyTransactionCategoryEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postTransactionCategoriesBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTransactionCategoriesBulk.key = '/api/v1/transaction-categories/bulk';

/**
 *
 * Create a single TransactionEntity
 */
export const postTransactions = (
  requestBody: TransactionCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postTransactions.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTransactions.key = '/api/v1/transactions';

/**
 *
 * Create multiple TransactionEntities
 */
export const postTransactionsBulk = (
  requestBody: CreateManyTransactionEntityDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postTransactionsBulk.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTransactionsBulk.key = '/api/v1/transactions/bulk';

export const postUsers = (
  requestBody: CreateUserDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postUsers.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUsers.key = '/api/v1/users';

/**
 *
 * Replace a single EmployeeDayOffEntity
 */
export const putEmployeeDayOffId = (
  id: string,
  requestBody: EmployeeDayOffUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeDayOffEntity>> => {
  return Http.putRequest(
    template(putEmployeeDayOffId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putEmployeeDayOffId.key = '/api/v1/employee-day-off/{id}';

/**
 *
 * Replace a single EmployeeRuleEntity
 */
export const putEmployeeRulesId = (
  id: string,
  requestBody: EmployeeRuleUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeRuleEntity>> => {
  return Http.putRequest(
    template(putEmployeeRulesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putEmployeeRulesId.key = '/api/v1/employee-rules/{id}';

/**
 *
 * Replace a single EmployeeEntity
 */
export const putEmployeesId = (
  id: string,
  requestBody: EmployeeUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeEntity>> => {
  return Http.putRequest(
    template(putEmployeesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putEmployeesId.key = '/api/v1/employees/{id}';

/**
 *
 * Replace a single IssueEntity
 */
export const putIssuesId = (
  id: string,
  requestBody: IssueUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<IssueEntity>> => {
  return Http.putRequest(
    template(putIssuesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putIssuesId.key = '/api/v1/issues/{id}';

/**
 *
 * Replace a single MenuEntity
 */
export const putMenuId = (
  id: string,
  requestBody: MenuUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuEntity>> => {
  return Http.putRequest(
    template(putMenuId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putMenuId.key = '/api/v1/menu/{id}';

/**
 *
 * Replace a single MenuItemVariantEntity
 */
export const putMenuItemVariantsId = (
  id: string,
  requestBody: MenuItemVariantUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuItemVariantEntity>> => {
  return Http.putRequest(
    template(putMenuItemVariantsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putMenuItemVariantsId.key = '/api/v1/menu-item-variants/{id}';

/**
 *
 * Replace a single MenuItemEntity
 */
export const putMenuItemsId = (
  id: string,
  requestBody: MenuItemUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuItemEntity>> => {
  return Http.putRequest(
    template(putMenuItemsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putMenuItemsId.key = '/api/v1/menu-items/{id}';

export const putOrdersId = (
  id: string,
  requestBody: OrderUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putOrdersId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putOrdersId.key = '/api/v1/orders/{id}';

/**
 *
 * Replace a single RestaurantBranchEntity
 */
export const putRestaurantBranchesId = (
  id: string,
  requestBody: RestaurantBranchUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantBranchEntity>> => {
  return Http.putRequest(
    template(putRestaurantBranchesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putRestaurantBranchesId.key = '/api/v1/restaurant-branches/{id}';

/**
 *
 * Replace a single RestaurantTableEntity
 */
export const putRestaurantTablesId = (
  id: string,
  requestBody: RestaurantTableUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantTableEntity>> => {
  return Http.putRequest(
    template(putRestaurantTablesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putRestaurantTablesId.key = '/api/v1/restaurant-tables/{id}';

/**
 *
 * Replace a single RestaurantEntity
 */
export const putRestaurantsId = (
  id: string,
  requestBody: RestaurantCreateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RestaurantEntity>> => {
  return Http.putRequest(
    template(putRestaurantsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putRestaurantsId.key = '/api/v1/restaurants/{id}';

/**
 *
 * Replace a single TransactionCategoryEntity
 */
export const putTransactionCategoriesId = (
  id: string,
  requestBody: TransactionCategoryUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionCategoryEntity>> => {
  return Http.putRequest(
    template(putTransactionCategoriesId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTransactionCategoriesId.key = '/api/v1/transaction-categories/{id}';

/**
 *
 * Replace a single TransactionEntity
 */
export const putTransactionsId = (
  id: string,
  requestBody: TransactionUpdateDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionEntity>> => {
  return Http.putRequest(
    template(putTransactionsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTransactionsId.key = '/api/v1/transactions/{id}';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
export const _CONSTANT1 = [{ bearer: [] }];
