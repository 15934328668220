import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const AuthenticationLayout = React.memo(() => {
  const { t } = useTranslation('authentication');
  return (
    <div className={'authentication-layout'}>
      <div className="authentication-layout__welcome">
        <div className={'authentication-layout__bg-pattern'} />
        <div className={'authentication-layout__welcome__content'}>
          <h2>{t('hero.title')}</h2>
          <h4>{t('hero.description')}</h4>
        </div>
        <div className="authentication-layout__welcome__copyright">
          <h4>©2023 Restacity Inc</h4>
          <div className={'authentication-layout__welcome__terms'}>
            <a>{t('common.terms')}</a>
            <a>{t('common.privacy')}</a>
          </div>
        </div>
      </div>
      <div className="authentication-layout__main-content">
        <Outlet />
      </div>
    </div>
  );
});

export default AuthenticationLayout;
