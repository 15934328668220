import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Tab, TabList, Tabs } from '@carbon/react';

const routes = [
  {
    label: 'common.information',
    url: '/restaurant-settings',
  },
  {
    label: 'common.branches',
    url: '/restaurant-settings/branches',
  },
  {
    label: 'common.tables',
    url: '/restaurant-settings/tables',
  },
];

const getActiveTabIndex = (pathname) => {
  return routes.findIndex((route) => pathname === route.url);
};

const RestaurantSettings = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTabIndex = useMemo(() => getActiveTabIndex(location.pathname), [location.pathname]);

  return (
    <div className="store-settings-page">
      <div className="store-settings-page__header">
        <Breadcrumb noTrailingSlash aria-label="Page navigation">
          <BreadcrumbItem>
            <a href="/">{t('common.home')}</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/restaurant-settings">{t('common.restaurantSettings')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={routes[activeTabIndex].url}>{t(routes[activeTabIndex].label)}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <h1 className="store-settings-page__heading">{t('common.restaurantSettings')}</h1>
      </div>
      <Tabs selectedIndex={activeTabIndex}>
        <TabList className="tabs-group" aria-label="Tab navigation" contained>
          {routes.map((route) => (
            <Tab key={route.url} onClick={() => navigate(route.url)}>
              {t(route.label)}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <div className="store-settings-page__main-content">
        <Outlet />
      </div>
    </div>
  );
});

export default RestaurantSettings;
