import { Field, Formik } from 'formik';
import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack, TextInput, Toggle, ComboBox } from '@carbon/react';
import { useBranches } from 'src/hooks/useRestaurantBranches';
import { RestaurantTableEntity } from 'src/services/apis/types';

interface TableFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: RestaurantTableEntity | null | undefined;
}

function FormField(props: { label: string; name: string }) {
  const { name, label } = props;
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <TextInput
            id={name}
            labelText={label}
            invalid={!!(meta.touched && meta.error)}
            invalidText={meta.error}
            {...field}
          />
        );
      }}
    </Field>
  );
}

const Schema = z.object({
  summary: z.string().max(255).trim().optional(),
  tableNumber: z.preprocess((a) => parseInt(`${a}`, 10), z.number()),
  capacity: z.preprocess((a) => parseInt(`${a}`, 10), z.number()),
});

const TableForm = (props: TableFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();
  const { data: branches } = useBranches();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            tableNumber: '',
            capacity: '',
            summary: '',
            available: '',
            restaurantBranchId: '',
          },
        }),
    };
  });

  const branchSelectionItems = useMemo(() => {
    return branches?.data.map((item) => ({ id: item.id, text: item.name }));
  }, [branches?.data]);

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          id: props.initialValues?.id,
          restaurantBranchId: props.initialValues
            ? props.initialValues.restaurantBranchId
            : branchSelectionItems?.find(() => true)?.id,
          tableNumber: props.initialValues?.tableNumber,
          capacity: props.initialValues?.capacity,
          summary: props.initialValues?.summary,
          available: props.initialValues ? props.initialValues.available : true,
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <ComboBox
                items={branchSelectionItems}
                placeholder={t('common.filter')}
                selectedItem={
                  values.restaurantBranchId
                    ? branchSelectionItems?.find((item) => item.id === values.restaurantBranchId)
                    : null
                }
                onChange={(e) => setFieldValue('restaurantBranchId', e.selectedItem?.id)}
                itemToString={(item) => (item ? item.text : '')}
                titleText={t('common.selectABranch')}
              />
              <FormField name={'tableNumber'} label={t('common.tableNumber')} />
              <FormField name={'capacity'} label={t('common.capacity')} />
              <FormField name={'summary'} label={t('common.summary')} />
              <Toggle
                labelText={t('common.available')}
                labelA={t('common.off')}
                labelB={t('common.on')}
                onToggle={(checked) => setFieldValue('available', checked)}
                toggled={values.available}
                id="available"
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(TableForm);
