import React, { useEffect, useMemo } from 'react';
import { Select, SelectItem } from '@carbon/react';
import { useTransactionCategories } from 'src/hooks/useTransactionCategories';
import { TransactionCategoryTypeEnum } from 'src/utils/accounting';

interface TransactionCategorySelectorProps {
  value: string | undefined | null;
  type: TransactionCategoryTypeEnum;
  invalid?: boolean;
  onChange: (value: string | null) => void;
}

const TransactionCategorySelector = React.memo((props: TransactionCategorySelectorProps) => {
  const { data: transactionCategories } = useTransactionCategories();

  useEffect(() => {
    if (transactionCategories?.data?.length) {
      const defaultTransactionCategoryId = transactionCategories?.data
        ?.filter((item) => item.type === props.type)
        .find(() => true)?.id;
      if (defaultTransactionCategoryId) {
        props.onChange(defaultTransactionCategoryId);
      } else {
        props.onChange(null);
      }
    }
  }, [transactionCategories, props.type]);

  const transactionCategoriesSelectionItems = useMemo(() => {
    if (!props.type) {
      return transactionCategories?.data.map((item) => ({ id: item.id, text: item.name }));
    }
    return transactionCategories?.data
      ?.filter((item) => item.type === props.type)
      .map((item) => ({ id: item.id, text: item.name }));
  }, [transactionCategories?.data, props.type]);

  return (
    <div className={'cds--fullwidth'}>
      <label className={'cds--label'}>Select a category</label>
      <Select
        labelText="Select an option"
        hideLabel
        id={'transaction-category-selector'}
        onChange={(e: any) => props.onChange(e.target.value)}
        invalid={!props.value && transactionCategoriesSelectionItems?.length}
        defaultValue={props.value}
      >
        {transactionCategoriesSelectionItems?.map((item) => {
          return <SelectItem value={item.id} key={item.id} text={item.text} />;
        })}
      </Select>
    </div>
  );
});

export default TransactionCategorySelector;
