import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { SideNavLink } from '@carbon/react';
import { UserAvatar, ChevronLeft } from '@carbon/react/icons';
import { useAuthUser } from 'src/hooks/useAuthUser';
import { useSignOut } from 'src/hooks/useSignOut';
import { useAppStore } from 'src/states/app';

function MainSideNavSettings() {
  const { theme, locale, setTheme, setFullscreen, setLocale } = useAppStore(
    (state) => ({
      theme: state.theme,
      locale: state.locale,
      setTheme: state.setTheme,
      setLocale: state.setLocale,
      setFullscreen: state.setFullscreen,
    }),
    shallow
  );
  const { t, i18n } = useTranslation();
  const authUser = useAuthUser();
  const { signOut } = useSignOut();

  const handleChangeLocale = useCallback((locale: 'vi' | 'en') => {
    setLocale(locale);
    dayjs.locale(locale);
    i18n.changeLanguage(locale).then((r) => r);
  }, []);

  return (
    <div className={'cds--side-nav__link--settings'}>
      <SideNavLink
        large
        renderIcon={() => <span style={{ fontSize: 20 }}>{locale === 'vi' ? '🇻🇳' : '🇬🇧'}</span>}
        onClick={() => handleChangeLocale(locale === 'vi' ? 'en' : 'vi')}
        id={'switch-locale-button'}
      >
        {locale === 'en' ? 'English' : 'Tiếng Việt'}
      </SideNavLink>
      <SideNavLink
        large
        renderIcon={() => <ChevronLeft size={32} />}
        onClick={() => setFullscreen(true)}
        id={'collapse-sidenav-button'}
      >
        {t('sideNav.collapse')}
      </SideNavLink>
      {authUser && (
        <>
          <SideNavLink large renderIcon={UserAvatar} as={Link} to={'/'} id={'current-user-name'}>
            {authUser.firstName} {authUser.lastName}
          </SideNavLink>
        </>
      )}
    </div>
  );
}

export default React.memo(MainSideNavSettings);
