import { useLottie } from 'lottie-react';
import React from 'react';
import animationData from 'src/json/lottie-success.json';

interface SuccessIconProps {
  width?: number;
  height?: number;
}

const SuccessIcon = React.memo(({ width }: SuccessIconProps) => {
  const options = {
    loop: false,
    autoplay: true,
    animationData: animationData,
  };
  const { View } = useLottie(options);
  return <>{View}</>;
});

export default SuccessIcon;
