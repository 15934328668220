import { Field } from 'formik';
import React from 'react';
import { TextInput, NumberInput } from '@carbon/react';

export function FormikTextInput(props: {
  label: string;
  name: string;
  isNumber?: boolean;
  min?: number;
  max?: number;
}) {
  const { name, label } = props;
  return (
    <Field name={name}>
      {({ field, meta }) => {
        if (props.isNumber) {
          return (
            <NumberInput
              id={name}
              min={props.min || 0}
              max={props.max}
              label={label}
              invalid={!!(meta.touched && meta.error)}
              invalidText={meta.error}
              {...field}
            />
          );
        }
        return (
          <TextInput
            id={name}
            labelText={label}
            invalid={!!(meta.touched && meta.error)}
            invalidText={meta.error}
            {...field}
          />
        );
      }}
    </Field>
  );
}
