import * as Sentry from '@sentry/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import '@carbon/charts/styles.css';
import '@carbon/styles/css/styles.css';
import App from 'src/App';
import 'src/index.scss';
import { locale } from 'src/utils/settings';
import './i18n';

dayjs.extend(relativeTime);
dayjs.extend(isoWeek);
dayjs.locale(locale);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
    },
  },
});

const consoleWarn = console.error;
const SUPPRESSED_WARNINGS = [
  'Warning: Failed %s type: %s%s',
  'Warning: validateDOMNesting',
  'does not exist in data groups',
  'for a non-boolean attribute',
];

if (import.meta.env.VITE_SENTRY_DNS_URL && import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS_URL,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  });
}

console.error = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg?.indexOf(entry) > -1)) {
    consoleWarn(msg, ...args);
  }
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
