import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import useWindowFocus from 'use-window-focus';
import { shallow } from 'zustand/shallow';
import { DashboardDateRange } from '@restacity/shared/enums/dashboard';
import {
  getEmployees,
  postEmployees,
  putEmployeesId,
  deleteEmployeesId,
  postEmployeesChangePassword,
  getDashboardSalesReports,
  getDashboardEmployeeIncomes,
} from 'src/services/apis/services';
import { EmployeeChangePasswordDto, EmployeeCreateDto, EmployeeUpdateDto } from 'src/services/apis/types';
import { useDashboardStore } from 'src/states/dashboard';
import { useEmployeeStore } from 'src/states/employee';
import { QUERIES } from 'src/utils/react-query';

export const useEmployees = () => {
  return useQuery([QUERIES.EMPLOYEES], () => getEmployees());
};

export const useCreateEmployeesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: EmployeeCreateDto) => postEmployees(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEES]);
    },
  });
};

export const useUpdateEmployeesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: EmployeeUpdateDto) => putEmployeesId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEES]);
    },
  });
};

export const useDeleteEmployeesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((employeeId: string) => deleteEmployeesId(employeeId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.EMPLOYEES]);
    },
  });
};

export const useChangeEmployeePasswordMutation = () => {
  return useMutation((payload: EmployeeChangePasswordDto) => postEmployeesChangePassword(payload));
};

export const useEmployeeIncomesDashboard = () => {
  const { employeeId } = useEmployeeStore(
    (state) => ({
      employeeId: state.employeeId,
    }),
    shallow
  );

  const query = useQuery(
    [
      QUERIES.EMPLOYEE_INCOMES,
      {
        employeeId,
      },
    ],
    ({ queryKey: [_key, _filters] }) => getDashboardEmployeeIncomes(_filters as any),
    {
      cacheTime: 0,
      refetchOnMount: true,
      refetchInterval: 120000,
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      enabled: !!employeeId,
    }
  );
  return {
    query,
  };
};
