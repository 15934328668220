export enum IssueStatusEnum {
  OPEN = 1,
  IN_REVIEW = 2,
  RESOLVED = 3,
  REJECTED = 4,
  CLOSED = 5,
}

export enum IssueTypeEnum {
  EMPLOYEE = 1,
  DISH = 2,
  OPERATION = 3,
  OTHERS = 4,
}

export const IssueTypes = [
  { label: 'issueTypes.employee', value: IssueTypeEnum.EMPLOYEE },
  { label: 'issueTypes.dish', value: IssueTypeEnum.DISH },
  { label: 'issueTypes.operation', value: IssueTypeEnum.OPERATION },
  { label: 'issueTypes.others', value: IssueTypeEnum.OTHERS },
];

export const IssueStatuses = [
  { label: 'issueStatuses.open', value: IssueStatusEnum.OPEN },
  { label: 'issueStatuses.inReview', value: IssueStatusEnum.IN_REVIEW },
  { label: 'issueStatuses.resolved', value: IssueStatusEnum.RESOLVED },
  { label: 'issueStatuses.rejected', value: IssueStatusEnum.REJECTED },
  { label: 'issueStatuses.closed', value: IssueStatusEnum.CLOSED },
];

export function getIssueTypeText(type: IssueTypeEnum | number) {
  if (type === IssueTypeEnum.EMPLOYEE) {
    return 'issueTypes.employee';
  }
  if (type === IssueTypeEnum.DISH) {
    return 'issueTypes.dish';
  }
  if (type === IssueTypeEnum.OPERATION) {
    return 'issueTypes.operation';
  }
  return 'issueTypes.others';
}

export function getIssueStatusText(status: IssueStatusEnum | number) {
  if (status === IssueStatusEnum.OPEN) {
    return 'issueStatuses.open';
  }
  if (status === IssueStatusEnum.IN_REVIEW) {
    return 'issueStatuses.inReview';
  }
  if (status === IssueStatusEnum.RESOLVED) {
    return 'issueStatuses.resolved';
  }
  if (status === IssueStatusEnum.REJECTED) {
    return 'issueStatuses.rejected';
  }
  if (status === IssueStatusEnum.CLOSED) {
    return 'issueStatuses.closed';
  }
  return 'issueStatuses.others';
}

export function getIssueTypeColor(type: IssueTypeEnum | number) {
  if (type === IssueTypeEnum.EMPLOYEE) {
    return 'green';
  }
  if (type === IssueTypeEnum.DISH) {
    return 'red';
  }
  if (type === IssueTypeEnum.OPERATION) {
    return 'warm-gray';
  }
  return 'gray';
}

export function getIssueStatusColor(status: IssueStatusEnum | number) {
  if (status === IssueStatusEnum.OPEN) {
    return 'green';
  }
  if (status === IssueStatusEnum.IN_REVIEW) {
    return 'blue';
  }
  if (status === IssueStatusEnum.RESOLVED) {
    return 'teal';
  }
  if (status === IssueStatusEnum.REJECTED) {
    return 'red';
  }
  if (status === IssueStatusEnum.CLOSED) {
    return 'warm-gray';
  }
  return 'outline';
}

export const issueStatusOptions = [
  { id: IssueStatusEnum.OPEN, text: getIssueStatusText(IssueStatusEnum.OPEN) },
  { id: IssueStatusEnum.IN_REVIEW, text: getIssueStatusText(IssueStatusEnum.IN_REVIEW) },
  { id: IssueStatusEnum.RESOLVED, text: getIssueStatusText(IssueStatusEnum.RESOLVED) },
  { id: IssueStatusEnum.REJECTED, text: getIssueStatusText(IssueStatusEnum.REJECTED) },
  { id: IssueStatusEnum.CLOSED, text: getIssueStatusText(IssueStatusEnum.CLOSED) },
];
