export const QUERIES = {
  AUTH_USER: 'authUser',
  MY_RESTAURANTS: 'myRestaurants',
  BRANCHES: 'branches',
  MENU: 'menu',
  ORDERS: 'orders',
  RECENT_ORDERS: 'recentOrders',
  DELIVERY_ORDERS: 'deliveryOrders',
  IN_PROGRESS_ORDERS: 'inProgressOrders',
  PAID_ORDER_TOKENS: 'paidOrderTokens',
  MENU_ITEMS: 'menuItems',
  MENU_ITEM_VARIANTS: 'menuItemVariants',
  TABLES: 'tables',
  EMPLOYEES: 'employees',
  TRANSACTION_CATEGORIES: 'transactionCategories',
  EMPLOYEE_RULES: 'employeeRules',
  EMPLOYEE_DAY_OFF: 'employeeDayOff',
  TRANSACTIONS: 'transactions',
  ISSUES: 'issues',
  EMPLOYEE_MONTHLY_ISSUES: 'employeeMonthlyIssues',
  EMPLOYEE_MONTHLY_DAY_OFF: 'employeeMonthlyDayOff',
  DASHBOARD_SALES_REPORTS: 'dashboardSalesReports',
  EMPLOYEE_INCOMES: 'employeeIncomes',
  DASHBOARD_ACCOUNTING_REPORTS: 'dashboardAccountingReports',
};
