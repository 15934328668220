import React, { useCallback, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { Modal } from '@carbon/react';
import { useCloudinaryUpload } from 'src/hooks/useCloudinaryUpload';
import { getMediaSignature } from 'src/services/apis/services';
import { encodeImageToBlurhash } from 'src/utils/blurhash';

export function CropperModal(props: {
  croppingFile: { file: File | null; previewUrl: string } | null;
  onClose: () => void;
  cropperAspectRatio: number;
  onChange: ({ fileId, format, blurhash, previewUrl }) => void;
}) {
  const { t } = useTranslation();
  const upload = useCloudinaryUpload();
  const cropperRef = useRef<any>(null);
  const [cropper, setCropper] = useState<Cropper>();
  const [cropping, setCropping] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleCropImageAndUpload = useCallback(async () => {
    if (typeof cropper !== 'undefined') {
      setCropping(true);
      const blob = await (await fetch(cropper.getCroppedCanvas().toDataURL())).blob();
      const file = new File([blob], props.croppingFile?.file?.name!, { type: props.croppingFile?.file?.type });
      setUploading(true);
      const uploadSignature = await getMediaSignature();
      const uploadedData = await upload({
        file,
        resourceType: 'image',
        ...uploadSignature,
      });
      const blurhash = await encodeImageToBlurhash(uploadedData.data.url);
      props.onChange({
        fileId: uploadedData.data.public_id,
        format: uploadedData.data.format,
        blurhash,
        previewUrl: URL.createObjectURL(file),
      });
      setCropping(false);
      setUploading(false);
    }
  }, [props.croppingFile]);

  return (
    <Modal
      open={!!props.croppingFile}
      modalHeading={t('common.cropYourImage')}
      modalLabel={t('common.optimizeImage')}
      size={'sm'}
      primaryButtonText={
        uploading ? `${t('common.uploading')}...` : cropping ? `${t('common.cropping')}...` : t('actions.crop')
      }
      secondaryButtonText={t('actions.cancel')}
      primaryButtonDisabled={cropping}
      onRequestClose={props.onClose}
      onSecondarySubmit={props.onClose}
      onRequestSubmit={handleCropImageAndUpload}
    >
      <Cropper
        src={props.croppingFile?.previewUrl}
        style={{ height: 400, width: '100%' }}
        initialAspectRatio={props.cropperAspectRatio}
        guides={true}
        ref={cropperRef}
        checkOrientation={true}
        dragMode={'move'}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
    </Modal>
  );
}
