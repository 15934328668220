import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@carbon/react';
import { TransactionStatusEnum, TransactionTypeEnum } from 'src/utils/accounting';
import {
  getTransactionStatusText,
  getTransactionStatusColor,
  getTransactionTypeColor,
  getTransactionTypeText,
} from 'src/utils/accounting';

interface TransactionTypeTagProps {
  type: TransactionTypeEnum;
}

interface TransactionTagProps {
  status: TransactionStatusEnum;
}

const TransactionTypeTag = React.memo((props: TransactionTypeTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getTransactionTypeColor(props.type)} size="sm">
      {t(getTransactionTypeText(props.type))}
    </Tag>
  );
});

const TransactionStatusTag = React.memo((props: TransactionTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getTransactionStatusColor(props.status)} size="sm">
      {t(getTransactionStatusText(props.status))}
    </Tag>
  );
});

export { TransactionTypeTag, TransactionStatusTag };
