import dayjs from 'dayjs';
import React from 'react';

interface FormattedDateProps {
  date: Date | string;
  format?: string;
}

const FormattedDate = React.memo((props: FormattedDateProps) => {
  const isToday = dayjs(props.date).isSame(new Date(), 'day');
  if (isToday) {
    return <span>Today</span>;
  }
  if (props.format) {
    return <span>{dayjs(props.date).format(props.format)}</span>;
  }
  return <span>{dayjs(props.date).fromNow()}</span>;
});

export default FormattedDate;
