import { useNavigate } from 'react-router-dom';
import { deleteAuthToken } from 'src/utils/auth';

export function useSignOut() {
  const navigate = useNavigate();
  const signOut = () => {
    deleteAuthToken();
    navigate('/auth/login');
  };
  return { signOut };
}
