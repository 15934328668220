import React, { useEffect, useState } from 'react';

interface DelayedProps {
  children: React.ReactElement;
  delay: number;
}

const Delayed = React.memo((props: DelayedProps) => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRendered(true);
    }, props.delay);
  }, []);
  if (!rendered) {
    return null;
  }
  return props.children;
});

export default Delayed;
