import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@carbon/react';
import { getIssueStatusText, getIssueStatusColor, getIssueTypeColor, getIssueTypeText } from 'src/utils/issue';
import { IssueStatusEnum, IssueTypeEnum } from 'src/utils/issue';

interface IssueTypeTagProps {
  type: IssueTypeEnum;
}

interface IssueTagProps {
  status: IssueStatusEnum;
}

const IssueTypeTag = React.memo((props: IssueTypeTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getIssueTypeColor(props.type)} size="sm">
      {t(getIssueTypeText(props.type))}
    </Tag>
  );
});

const IssueStatusTag = React.memo((props: IssueTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getIssueStatusColor(props.status)} size="sm">
      {t(getIssueStatusText(props.status))}
    </Tag>
  );
});

export { IssueTypeTag, IssueStatusTag };
