import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Modal,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import EmployeeRuleForm from 'src/components/Employee/EmployeeRuleForm';
import {
  useEmployeeRules,
  useCreateEmployeeRulesMutation,
  useUpdateEmployeeRulesMutation,
  useDeleteEmployeeRulesMutation,
} from 'src/hooks/useEmployeeRules';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { EmployeeRuleEntity } from 'src/services/apis/types';
import { getMutationErrorMessage } from 'src/utils/error';
import { KToNumber, NumberToK } from 'src/utils/price';

const EmployeeRules = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const { isLoading, data } = useEmployeeRules();
  const createEmployeeRulesMutation = useCreateEmployeeRulesMutation();
  const updateEmployeeRulesMutation = useUpdateEmployeeRulesMutation();
  const deleteEmployeeRulesMutation = useDeleteEmployeeRulesMutation();
  const currentRestaurant = useCurrentRestaurant();
  const [openAddEmployeeRule, setOpenAddEmployeeRule] = useState(false);
  const [editingEmployeeRule, setEditingEmployeeRule] = useState<null | undefined | EmployeeRuleEntity>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [deletingEmployeeRule, setDeletingEmployeeRule] = useState<null | undefined | EmployeeRuleEntity>(null);

  const handleCloseAddOrEditEmployeeRule = useCallback(() => {
    setOpenAddEmployeeRule(false);
    setEditingEmployeeRule(null);
    setEditingEmployeeRule(null);
    formRef.current?.resetForm();
  }, []);

  const handleAddEmployeeRule = useCallback(() => {
    setOpenAddEmployeeRule(true);
  }, []);

  const handleCreate = useCallback(
    async (values) => {
      await createEmployeeRulesMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
        fine: KToNumber(parseInt(values.fine || 0, 10)),
      });
      handleCloseAddOrEditEmployeeRule();
    },
    [currentRestaurant?.id]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateEmployeeRulesMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
        fine: KToNumber(parseInt(values.fine || 0, 10)),
      });
      handleCloseAddOrEditEmployeeRule();
    },
    [currentRestaurant?.id]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingEmployeeRule(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteEmployeeRulesMutation.mutateAsync(deletingEmployeeRule?.id!);
    setDeletingEmployeeRule(null);
  }, [deletingEmployeeRule]);

  const headers = [
    { key: 'title', header: t('common.title') },
    { key: 'description', header: t('common.description') },
  ];

  const extraHeaders = [
    { key: 'fine', header: t('common.fine') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return <DataTableSkeleton columnCount={headers.length + 3} rowCount={6} headers={[...headers, ...extraHeaders]} />;
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (searchQuery) {
          return (
            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.description.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return true;
      })
      .map((item) => ({
        ...item,
      })) || [];

  const submitting = createEmployeeRulesMutation.isLoading || updateEmployeeRulesMutation.isLoading;
  const createError = getMutationErrorMessage(createEmployeeRulesMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateEmployeeRulesMutation.error as AxiosError);

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer
            title={t('titles.employeeRulesPage', { restaurantName: currentRestaurant?.name })}
            description={`${data?.total} ${t('common.employeeRules')}`}
          >
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch placeholder={t('common.search')} onChange={(e) => setSearchQuery(e.target.value)} />
                <Button renderIcon={Add} onClick={handleAddEmployeeRule}>
                  {t('actions.createNewEmployeeRule')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>{NumberToK(filteredRows[index]?.fine)}K</TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingEmployeeRule(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingEmployeeRule(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddEmployeeRule || !!editingEmployeeRule}
        size={'sm'}
        modalHeading={openAddEmployeeRule ? t('actions.createNewEmployeeRule') : t('actions.updateEmployeeRule')}
        modalLabel={t('titles.employeeRulesPage', { restaurantName: currentRestaurant?.name })}
        primaryButtonText={
          submitting ? `${t('common.loading')}...` : editingEmployeeRule ? t('actions.update') : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditEmployeeRule}
        onRequestClose={handleCloseAddOrEditEmployeeRule}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <EmployeeRuleForm
          errorMessage={createError || updateError}
          initialValues={editingEmployeeRule}
          ref={formRef}
          onSubmit={editingEmployeeRule ? handleUpdate : handleCreate}
        />
      </Modal>
      <DeleteConfirmation
        open={!!deletingEmployeeRule}
        heading={t('messages.areYouSureToDeleteThisEmployeeRule')}
        label={t('titles.employeeRulesPage', { restaurantName: currentRestaurant?.name })}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteEmployeeRulesMutation.isLoading}
      />
    </>
  );
});

export default EmployeeRules;
