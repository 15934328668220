export enum TransactionCategoryStatusEnum {
  ACTIVE = 1,
  ARCHIVED = 2,
}

export enum TransactionStatusEnum {
  PENDING = 1,
  PAID = 2,
  RECEIVED = 3,
  CANCELLED = 4,
}

export enum TransactionCategoryTypeEnum {
  INCOME = 1,
  EXPENSE = 2,
  INVESTMENT = 3,
  OTHER = 4,
}

export enum TransactionTypeEnum {
  INCOME = 1,
  EXPENSE = 2,
  INVESTMENT = 3,
  OTHER = 4,
}

export const TransactionCategoryTypes = [
  { label: 'transactionCategoryTypes.income', value: TransactionCategoryTypeEnum.INCOME },
  { label: 'transactionCategoryTypes.expense', value: TransactionCategoryTypeEnum.EXPENSE },
  { label: 'transactionCategoryTypes.investment', value: TransactionCategoryTypeEnum.INVESTMENT },
  { label: 'transactionCategoryTypes.other', value: TransactionCategoryTypeEnum.OTHER },
];

export const TransactionCategoryStatuses = [
  { label: 'transactionCategoryStatuses.active', value: TransactionCategoryStatusEnum.ACTIVE },
  { label: 'transactionCategoryStatuses.archived', value: TransactionCategoryStatusEnum.ARCHIVED },
];

export const TransactionTypes = [
  { label: 'transactionTypes.income', value: TransactionTypeEnum.INCOME },
  { label: 'transactionTypes.expense', value: TransactionTypeEnum.EXPENSE },
  { label: 'transactionTypes.investment', value: TransactionTypeEnum.INVESTMENT },
  { label: 'transactionTypes.other', value: TransactionTypeEnum.OTHER },
];

export const TransactionStatuses = [
  { label: 'transactionStatuses.pending', value: TransactionStatusEnum.PENDING },
  { label: 'transactionStatuses.paid', value: TransactionStatusEnum.PAID },
  { label: 'transactionStatuses.received', value: TransactionStatusEnum.RECEIVED },
  { label: 'transactionStatuses.cancelled', value: TransactionStatusEnum.CANCELLED },
];

export function getTransactionCategoryTypeText(orderType: TransactionCategoryTypeEnum | number) {
  if (orderType === TransactionCategoryTypeEnum.INCOME) {
    return 'transactionCategoryTypes.income';
  }
  if (orderType === TransactionCategoryTypeEnum.EXPENSE) {
    return 'transactionCategoryTypes.expense';
  }
  if (orderType === TransactionCategoryTypeEnum.INVESTMENT) {
    return 'transactionCategoryTypes.investment';
  }
  return 'transactionCategoryTypes.other';
}

export function getTransactionCategoryStatusText(orderStatus: TransactionCategoryStatusEnum | number) {
  if (orderStatus === TransactionCategoryStatusEnum.ACTIVE) {
    return 'transactionCategoryStatuses.active';
  }
  if (orderStatus === TransactionCategoryStatusEnum.ARCHIVED) {
    return 'transactionCategoryStatuses.archived';
  }
  return 'transactionCategoryStatuses.other';
}

export function getTransactionCategoryTypeColor(orderType: TransactionCategoryTypeEnum | number) {
  if (orderType === TransactionCategoryTypeEnum.INCOME) {
    return 'green';
  }
  if (orderType === TransactionCategoryTypeEnum.EXPENSE) {
    return 'red';
  }
  if (orderType === TransactionCategoryTypeEnum.INVESTMENT) {
    return 'warm-gray';
  }
  return 'gray';
}

export function getTransactionCategoryStatusColor(orderStatus: TransactionCategoryStatusEnum | number) {
  if (orderStatus === TransactionCategoryStatusEnum.ACTIVE) {
    return 'cyan';
  }
  if (orderStatus === TransactionCategoryStatusEnum.ARCHIVED) {
    return 'gray';
  }
  return 'gray';
}

export function getTransactionTypeColor(orderType: TransactionTypeEnum | number) {
  if (orderType === TransactionTypeEnum.INCOME) {
    return 'green';
  }
  if (orderType === TransactionTypeEnum.EXPENSE) {
    return 'red';
  }
  if (orderType === TransactionTypeEnum.INVESTMENT) {
    return 'warm-gray';
  }
  return 'gray';
}

export function getTransactionTypeText(orderType: TransactionCategoryTypeEnum | number) {
  if (orderType === TransactionCategoryTypeEnum.INCOME) {
    return 'Income';
  }
  if (orderType === TransactionCategoryTypeEnum.EXPENSE) {
    return 'Expense';
  }
  if (orderType === TransactionCategoryTypeEnum.INVESTMENT) {
    return 'Investment';
  }
  return 'Other';
}

export function getTransactionStatusColor(orderStatus: TransactionStatusEnum | number) {
  if (orderStatus === TransactionStatusEnum.PENDING) {
    return 'cyan';
  }
  if (orderStatus === TransactionStatusEnum.PAID) {
    return 'green';
  }
  if (orderStatus === TransactionStatusEnum.RECEIVED) {
    return 'green';
  }
  if (orderStatus === TransactionStatusEnum.CANCELLED) {
    return 'red';
  }
  return 'gray';
}

export function getTransactionStatusText(transactionStatus: TransactionStatusEnum | number) {
  if (transactionStatus === TransactionStatusEnum.PENDING) {
    return 'transactionStatuses.pending';
  }
  if (transactionStatus === TransactionStatusEnum.PAID) {
    return 'transactionStatuses.paid';
  }
  if (transactionStatus === TransactionStatusEnum.RECEIVED) {
    return 'transactionStatuses.received';
  }
  if (transactionStatus === TransactionStatusEnum.CANCELLED) {
    return 'transactionStatuses.cancelled';
  }
  return 'transactionStatuses.other';
}

export const transactionStatusOptions = [
  { id: TransactionStatusEnum.PENDING, text: getTransactionStatusText(TransactionStatusEnum.PENDING) },
  { id: TransactionStatusEnum.PAID, text: getTransactionStatusText(TransactionStatusEnum.PAID) },
  { id: TransactionStatusEnum.RECEIVED, text: getTransactionStatusText(TransactionStatusEnum.RECEIVED) },
  { id: TransactionStatusEnum.CANCELLED, text: getTransactionStatusText(TransactionStatusEnum.CANCELLED) },
];
