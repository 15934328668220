import { Formik } from 'formik';
import React, { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack, FormLabel } from '@carbon/react';
import { FormFieldUpload } from 'src/components/Form/FormFieldUpload';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import Separator from 'src/components/Separator/Separator';
import { RestaurantBranchEntity } from 'src/services/apis/types';
import { NumberToK } from 'src/utils/price';

interface BranchFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: RestaurantBranchEntity | null | undefined;
  onSelectedFile: ({ file, previewUrl }) => void;
}

const Schema = z.object({
  name: z.string().max(60).trim(),
  slug: z.string().max(24).trim(),
  address: z.string().max(255).trim(),
  latitude: z.preprocess((a) => parseFloat(`${a}`), z.number()),
  longitude: z.preprocess((a) => parseFloat(`${a}`), z.number()),
});

const BranchForm = (props: BranchFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            name: '',
            slug: '',
            address: '',
            photo: '',
            photoBlurhash: '',
            latitude: '',
            longitude: '',
            salesGoalPerDay: '',
            salesGoalPerWeek: '',
            salesGoalPerMonth: '',
            salesGoalPerYear: '',
          },
        }),
    };
  });

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          id: props.initialValues?.id,
          name: props.initialValues?.name,
          slug: props.initialValues?.slug,
          address: props.initialValues?.address,
          photo: props.initialValues?.photo,
          salesGoalPerDay: NumberToK(props.initialValues?.salesGoalPerDay || 0),
          salesGoalPerWeek: NumberToK(props.initialValues?.salesGoalPerWeek || 0),
          salesGoalPerMonth: NumberToK(props.initialValues?.salesGoalPerMonth || 0),
          salesGoalPerYear: NumberToK(props.initialValues?.salesGoalPerYear || 0),
          photoBlurhash: props.initialValues?.photoBlurhash,
          latitude: props.initialValues?.latitude ? `${props.initialValues?.latitude}` : null,
          longitude: props.initialValues?.longitude ? `${props.initialValues?.longitude}` : null,
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <FormikTextInput name={'name'} label={t('common.name')} />
              <FormikTextInput name={'slug'} label={t('common.slug')} />
              <FormikTextInput name={'address'} label={t('common.address')} />
              <div style={{ display: 'flex', gap: '1rem' }}>
                <FormikTextInput name={'latitude'} label={t('common.latitude')} />
                <FormikTextInput name={'longitude'} label={t('common.longitude')} />
              </div>
              <Separator>Sales Goals</Separator>
              <FormikTextInput name={'salesGoalPerDay'} isNumber max={10000000} label={t('common.salesGoalPerDay')} />
              <FormikTextInput name={'salesGoalPerWeek'} isNumber max={10000000} label={t('common.salesGoalPerWeek')} />
              <FormikTextInput
                name={'salesGoalPerMonth'}
                isNumber
                max={10000000}
                label={t('common.salesGoalPerMonth')}
              />
              <FormikTextInput name={'salesGoalPerYear'} isNumber max={10000000} label={t('common.salesGoalPerYear')} />
              <Separator>Bank Account</Separator>
              <FormikTextInput name={'bankAccountName'} label={t('common.bankAccountName')} />
              <FormikTextInput name={'bankAccountOwnerName'} label={t('common.bankAccountOwnerName')} />
              <FormikTextInput name={'bankAccountNumber'} label={t('common.bankAccountNumber')} />
              <FormikTextInput name={'bankAccountBranchName'} label={t('common.bankAccountBranchName')} />
              <FormFieldUpload
                title={t('common.branchPhoto')}
                description={t('messages.photoRecommended', { size: 1, aspectRatio: '16x9' })}
                cropperAspectRatio={16 / 9}
                aspectRatio={'16x9'}
                onSelectedFile={props.onSelectedFile}
                value={values.photo}
                onChange={(data) => {
                  setFieldValue('photo', `${data.fileId}.${data.format}`);
                  setFieldValue('photoBlurhash', data.blurhash);
                }}
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(BranchForm);
