import React, { useMemo } from 'react';
import { ComboBox } from '@carbon/react';
import { useEmployees } from 'src/hooks/useEmployees';

interface EmployeeSelectorProps {
  value: string | undefined | null;
  invalid?: boolean;
  branchId: string | undefined;
  onChange: (value: string | null) => void;
  hideTitle?: boolean;
  placeholderText?: string;
}

const EmployeeSelector = React.memo((props: EmployeeSelectorProps) => {
  const { data: employees } = useEmployees();

  const employeesSelectionItems = useMemo(() => {
    return employees?.data
      ?.filter((item) => item.restaurantBranchId === props.branchId)
      .map((item) => ({ id: item.id, text: `${item.user.firstName} ${item.user.lastName}` }));
  }, [employees?.data, props.branchId]);

  return (
    <ComboBox
      items={employeesSelectionItems}
      placeholder={props.placeholderText || 'Filter...'}
      invalid={props.invalid}
      selectedItem={props.value ? employeesSelectionItems?.find((item) => item.id === props.value) : null}
      onChange={(e) => props.onChange(e.selectedItem?.id)}
      itemToString={(item) => (item ? item.text : '')}
      titleText={!props.hideTitle ? 'Select a employee' : null}
    />
  );
});

export default EmployeeSelector;
