import { Cloudinary } from '@cloudinary/url-gen';
import { Resize } from '@cloudinary/url-gen/actions/resize';

const cloudinary = new Cloudinary({
  cloud: {
    cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
  },
});

export const getImageUrl = (fileId: string | undefined, size?: { width: number; height: number }) => {
  if (!fileId) return null;
  const image = cloudinary.image(fileId);
  if (size) {
    image.resize(Resize.scale().width(size.width).height(size.height));
  }
  return image.toURL();
};
