import { Field, Formik } from 'formik';
import React, { useImperativeHandle, useRef } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, Stack, TextInput, InlineNotification } from '@carbon/react';

interface EmployeeChangePasswordFormProps {
  onSubmit: (values: any) => void;
  employeeId: string;
}

const BaseSchema = z.object({
  password: z.string().max(255).trim(),
});

const EmployeeChangePasswordForm = (props: EmployeeChangePasswordFormProps, ref) => {
  const formikRef = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            password: '',
          },
        }),
    };
  });

  return (
    <div>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(BaseSchema)}
        initialValues={{
          id: props.employeeId,
          password: '',
        }}
        onSubmit={props.onSubmit}
      >
        {() => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <Field name="password">
                {({ field, meta }) => {
                  return (
                    <TextInput.PasswordInput
                      id="employeePassword"
                      labelText="Password"
                      name={'password'}
                      invalid={!!(meta.touched && meta.error)}
                      invalidText={meta.error}
                      {...field}
                    />
                  );
                }}
              </Field>
              <InlineNotification
                title="Re-Sign In Required"
                hideCloseButton={true}
                subtitle="This employee needs to sign in again on all devices."
                lowContrast={true}
                kind={'info'}
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(EmployeeChangePasswordForm);
