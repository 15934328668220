import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { HeaderGlobalAction, HeaderGlobalBar, HeaderMenuItem } from '@carbon/react';
import { Search, Close, Asleep, Light, Logout } from '@carbon/react/icons';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { useSignOut } from 'src/hooks/useSignOut';
import { useAppStore } from 'src/states/app';

export const MainHeaderGlobalBar = React.memo((props: { active: boolean; onExpandSwitchStore: () => void }) => {
  const { t } = useTranslation();
  const { theme, locale, setTheme, setFullscreen, setLocale } = useAppStore(
    (state) => ({
      theme: state.theme,
      locale: state.locale,
      setTheme: state.setTheme,
      setLocale: state.setLocale,
      setFullscreen: state.setFullscreen,
    }),
    shallow
  );
  const currentRestaurant = useCurrentRestaurant();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const { signOut } = useSignOut();
  return (
    <HeaderGlobalBar>
      <HeaderGlobalAction aria-label="Search" tooltipAlignment="center">
        <Search size={20} />
      </HeaderGlobalAction>
      <HeaderGlobalAction onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} id={'switch-theme-button'}>
        {theme === 'dark' ? <Asleep size={20} /> : <Light size={20} />}
      </HeaderGlobalAction>
      <HeaderGlobalAction onClick={signOut} id={'logout-button'}>
        <Logout size={20} />
      </HeaderGlobalAction>
      <HeaderGlobalAction
        aria-label={`${t('header.switchStoreBranch')}: ${currentRestaurant?.name} - ${
          currentRestaurantBranch?.name || t('common.all')
        }`}
        tooltipAlignment="end"
        isActive={props.active}
        onClick={props.onExpandSwitchStore}
      >
        {props.active ? (
          <Close size={20} />
        ) : (
          <img className="cds--header__global--store-switcher__image" src={'/dandan.png'} alt="Carbon illustration" />
        )}
      </HeaderGlobalAction>
    </HeaderGlobalBar>
  );
});
