import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { MeterChart } from '@carbon/charts-react';
import { OrderTypeEnum } from '@restacity/shared/enums/order';
import { useAppStore } from 'src/states/app';
import { getOrderTypeText } from 'src/utils/order';
import { NumberToK } from 'src/utils/price';

interface SalesGoalProps {
  title: string;
  isLoading: boolean;
  totalDeliverySales: number;
  totalTakeawaySales: number;
  totalDineInSales: number;
  foodAppsSales: any[];
  goal: number;
}

const SalesGoal = React.memo((props: SalesGoalProps) => {
  const { t } = useTranslation();
  const { theme } = useAppStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow
  );
  return (
    <div className="dashboard__stat_widget">
      <MeterChart
        data={[
          ...[
            {
              group: t(getOrderTypeText(OrderTypeEnum.DINE_IN)),
              value: props.totalDineInSales,
            },
            {
              group: t(getOrderTypeText(OrderTypeEnum.TAKEAWAY)),
              value: props.totalTakeawaySales,
            },
            {
              group: t(getOrderTypeText(OrderTypeEnum.DELIVERY)),
              value: props.totalDeliverySales,
            },
          ],
          ...props.foodAppsSales?.map((item) => ({ group: item.category.name, value: NumberToK(item.total) })),
        ]}
        options={{
          title: props.title,
          height: '130px',
          data: { loading: props.isLoading },
          meter: {
            proportional: {
              total: props.goal,
              unit: 'K',
              breakdownFormatter: (value) =>
                t('dashboard:currentSales', { datasetsTotal: value.datasetsTotal, total: value.total, unit: 'K' }),
              totalFormatter: (value) => t('dashboard:goal', { value, unit: 'K' }),
            } as any,
          },
          animations: true,
          theme: theme === 'dark' ? 'g100' : ('g10' as any),
        }}
      />
    </div>
  );
});

export default SalesGoal;
