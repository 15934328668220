/**
 * You can modify this file
 *
 * @version 6
 *
 */
import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { getAuthToken, deleteAuthToken } from 'src/utils/auth';
import { getCurrentRestaurantBranchId, getCurrentRestaurantId, clearCurrentRestaurantId } from 'src/utils/restaurant';

const baseConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

let axiosInstance: AxiosInstance;

function getAxiosInstance(): AxiosInstance {
  if (!axiosInstance) {
    axiosInstance = Axios.create(baseConfig);
    // Response interceptor
    axiosInstance.interceptors.response.use(
      (async (response: AxiosResponse): Promise<SwaggerResponse<any>> => {
        if (import.meta.env.VITE_AXIOS_RESPONSE_DELAYED) {
          await new Promise((resolve) =>
            setTimeout(resolve, parseInt(import.meta.env.VITE_AXIOS_RESPONSE_DELAYED, 10))
          );
        }
        return response.data;
      }) as any,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          deleteAuthToken();
          clearCurrentRestaurantId();
          if (location.pathname !== '/auth/login') {
            location.href = '/auth/login';
          }
        }
        return Promise.reject(error);
      }
    );
  }
  axiosInstance.interceptors.request.use(
    async (requestConfig) => {
      const authToken = getAuthToken();
      const currentRestaurantId = getCurrentRestaurantId();
      const currentRestaurantBranchId = getCurrentRestaurantBranchId();
      if (requestConfig.headers && authToken) {
        requestConfig.headers.authorization = `Bearer ${authToken}`;
      }
      if (requestConfig.headers && currentRestaurantId) {
        requestConfig.headers['X-Restaurant-Id'] = currentRestaurantId;
      }
      if (requestConfig.headers && currentRestaurantBranchId && currentRestaurantBranchId !== 'All') {
        requestConfig.headers['X-Restaurant-Branch-Id'] = currentRestaurantBranchId;
      }
      return requestConfig;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}

class RequestError extends Error {
  constructor(public message: string, public status?: number, public response?: AxiosResponse) {
    super(message);
  }
}

export type Security = any[] | undefined;

export type SwaggerResponse<R> = R;

export { getAxiosInstance, RequestError };
