import React from 'react';

interface SpacingProps {
  space: number;
}

const Spacing = (props: SpacingProps) => {
  return <div style={{ height: `${props.space}rem` }} />;
};

export default Spacing;
