import React from 'react';
import { useTranslation } from 'react-i18next';

interface EmptyStateProps {
  title?: string;
  desc?: string;
}

const EmptyState = React.memo((props: EmptyStateProps) => {
  const { t } = useTranslation();
  return (
    <div className={'cds--empty-state'}>
      <img src="/empty-state.png" alt="" />
      <h4>{props.title || t('messages.emptyDefaultTitle')}</h4>
      <p>{props.desc || t('messages.emptyDefaultDescription')}</p>
    </div>
  );
});

export default EmptyState;
