import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Tab, TabList, Tabs } from '@carbon/react';

const routes = [
  {
    label: 'common.menu',
    url: '/menu-settings',
  },
  {
    label: 'common.menuItems',
    url: '/menu-settings/items',
  },
  {
    label: 'common.itemVariants',
    url: '/menu-settings/item-variants',
  },
];

const getActiveTabIndex = (pathname) => {
  return routes.findIndex((route) => pathname === route.url);
};

const MenuSettings = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTabIndex = useMemo(() => getActiveTabIndex(location.pathname), [location.pathname]);

  return (
    <div className="menu-settings-page">
      <div className="menu-settings-page__header">
        <Breadcrumb noTrailingSlash aria-label="Page navigation">
          <BreadcrumbItem>
            <a href="/">{t('common.home')}</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a href="/">{t('common.menuSettings')}</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a href="/">{t(routes[activeTabIndex].label)}</a>
          </BreadcrumbItem>
        </Breadcrumb>
        <h1 className="menu-settings-page__heading">{t('common.menuSettings')}</h1>
      </div>
      <Tabs selectedIndex={activeTabIndex}>
        <TabList className="tabs-group" aria-label="Tab navigation" contained>
          {routes.map((route) => (
            <Tab key={route.url} onClick={() => navigate(route.url)}>
              {t(route.label)}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <div className="menu-settings-page__main-content">
        <Outlet />
      </div>
    </div>
  );
});

export default MenuSettings;
