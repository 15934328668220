import { create } from 'zustand';

interface EmployeeState {
  employeeId: string | null;
  setEmployeeId: (employeeId: string | null) => void;
}

export const useEmployeeStore = create<EmployeeState>((set) => ({
  employeeId: null,
  setEmployeeId: (employeeId: string | null) => {
    set((state) => {
      return {
        ...state,
        employeeId,
      };
    });
  },
}));
