import { Formik } from 'formik';
import React, { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, FormLabel, InlineNotification, Stack, Toggle } from '@carbon/react';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import IconSelector from 'src/components/Form/IconSelector';
import { MenuEntity } from 'src/services/apis/types';

interface MainMenuFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: MenuEntity | null | undefined;
}

const Schema = z.object({
  name: z.string().max(60).trim(),
  slug: z.string().max(24).trim(),
  summary: z.string().max(255).trim(),
  icon: z.string().max(255).trim(),
});

const MainMenuForm = (props: MainMenuFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            name: '',
            slug: '',
            summary: '',
          },
        }),
    };
  });

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          id: props.initialValues?.id,
          name: props.initialValues?.name,
          slug: props.initialValues?.slug,
          summary: props.initialValues?.summary,
          icon: props.initialValues?.photos?.find(() => true) || '/menu/rice.png',
          activated: props.initialValues ? props.initialValues.activated : true,
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <FormikTextInput name={'name'} label={t('common.name')} />
              <FormikTextInput name={'slug'} label={t('common.slug')} />
              <FormikTextInput name={'summary'} label={t('common.summary')} />
              <FormLabel>{t('common.icon')}</FormLabel>
              <IconSelector
                value={values.icon}
                onChange={(value) => setFieldValue('icon', value)}
                icons={[
                  '/menu/rice.png',
                  '/menu/noodles.png',
                  '/menu/pho.png',
                  '/menu/cooked-meat.png',
                  '/menu/drink.png',
                  '/menu/other.png',
                ]}
              />
              <Toggle
                labelText={t('common.activated')}
                labelA={t('common.off')}
                labelB={t('common.on')}
                onToggle={(checked) => setFieldValue('activated', checked)}
                toggled={values.activated}
                id="activated"
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(MainMenuForm);
