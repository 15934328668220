import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Modal,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import EmployeeDayOffForm from 'src/components/Employee/EmployeeDayOffForm';
import {
  useEmployeeDayOff,
  useCreateEmployeeDayOffMutation,
  useUpdateEmployeeDayOffMutation,
  useDeleteEmployeeDayOffMutation,
} from 'src/hooks/useEmployeeDayOff';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { EmployeeDayOffEntity } from 'src/services/apis/types';
import { getMutationErrorMessage } from 'src/utils/error';

const EmployeeDayOff = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const { isLoading, data } = useEmployeeDayOff();
  const createEmployeeDayOffMutation = useCreateEmployeeDayOffMutation();
  const updateEmployeeDayOffMutation = useUpdateEmployeeDayOffMutation();
  const deleteEmployeeDayOffMutation = useDeleteEmployeeDayOffMutation();
  const currentRestaurant = useCurrentRestaurant();
  const [openAddEmployeeDayOff, setOpenAddEmployeeDayOff] = useState(false);
  const [editingEmployeeDayOff, setEditingEmployeeDayOff] = useState<null | undefined | EmployeeDayOffEntity>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [deletingEmployeeDayOff, setDeletingEmployeeDayOff] = useState<null | undefined | EmployeeDayOffEntity>(null);

  const handleCloseAddOrEditEmployeeDayOff = useCallback(() => {
    setOpenAddEmployeeDayOff(false);
    setEditingEmployeeDayOff(null);
    setEditingEmployeeDayOff(null);
    formRef.current?.resetForm();
  }, []);

  const handleAddEmployeeDayOff = useCallback(() => {
    setOpenAddEmployeeDayOff(true);
  }, []);

  const handleCreate = useCallback(
    async (values) => {
      await createEmployeeDayOffMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
      });
      handleCloseAddOrEditEmployeeDayOff();
    },
    [currentRestaurant?.id]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateEmployeeDayOffMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
      });
      handleCloseAddOrEditEmployeeDayOff();
    },
    [currentRestaurant?.id]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingEmployeeDayOff(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteEmployeeDayOffMutation.mutateAsync(deletingEmployeeDayOff?.id!);
    setDeletingEmployeeDayOff(null);
  }, [deletingEmployeeDayOff]);

  const headers = [
    { key: 'title', header: t('common.title') },
    { key: 'reason', header: t('common.reason') },
    { key: 'amount', header: t('common.amount') },
  ];

  const extraHeaders = [
    { key: 'employee', header: t('common.employee') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return <DataTableSkeleton columnCount={headers.length + 3} rowCount={6} headers={[...headers, ...extraHeaders]} />;
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (searchQuery) {
          return (
            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.reason.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return true;
      })
      .map((item) => ({
        ...item,
      })) || [];

  const submitting = createEmployeeDayOffMutation.isLoading || updateEmployeeDayOffMutation.isLoading;
  const createError = getMutationErrorMessage(createEmployeeDayOffMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateEmployeeDayOffMutation.error as AxiosError);

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer
            title={t('titles.employeeRulesPage', { restaurantName: currentRestaurant?.name })}
            description={`${data?.total} ${t('common.employeeRules')}`}
          >
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch placeholder={t('common.search')} onChange={(e) => setSearchQuery(e.target.value)} />
                <Button renderIcon={Add} onClick={handleAddEmployeeDayOff}>
                  {t('actions.createNewEmployeeDayOff')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      {filteredRows[index]?.employee
                        ? `${filteredRows[index]?.employee?.user?.firstName} ${filteredRows[index]?.employee?.user?.lastName}`
                        : 'None'}
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingEmployeeDayOff(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingEmployeeDayOff(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddEmployeeDayOff || !!editingEmployeeDayOff}
        size={'sm'}
        modalHeading={openAddEmployeeDayOff ? t('actions.createNewEmployeeDayOff') : t('actions.updateEmployeeDayOff')}
        modalLabel={t('titles.employeeRulesPage', { restaurantName: currentRestaurant?.name })}
        primaryButtonText={
          submitting ? `${t('common.loading')}...` : editingEmployeeDayOff ? t('actions.update') : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditEmployeeDayOff}
        onRequestClose={handleCloseAddOrEditEmployeeDayOff}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <EmployeeDayOffForm
          errorMessage={createError || updateError}
          initialValues={editingEmployeeDayOff}
          ref={formRef}
          onSubmit={editingEmployeeDayOff ? handleUpdate : handleCreate}
        />
      </Modal>
      <DeleteConfirmation
        open={!!deletingEmployeeDayOff}
        heading={t('messages.areYouSureToDeleteThisEmployeeDayOff')}
        label={t('titles.employeeRulesPage', { restaurantName: currentRestaurant?.name })}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteEmployeeDayOffMutation.isLoading}
      />
    </>
  );
});

export default EmployeeDayOff;
