import React, { useEffect, useMemo } from 'react';
import { Select, SelectItem } from '@carbon/react';
import { useEmployeeRules } from 'src/hooks/useEmployeeRules';

interface EmployeeRuleSelectorProps {
  value: string | undefined | null;
  invalid?: boolean;
  onChange: (value: string | null) => void;
}

const EmployeeRuleSelector = React.memo((props: EmployeeRuleSelectorProps) => {
  const { data: employeeRules } = useEmployeeRules();

  useEffect(() => {
    if (employeeRules?.data?.length) {
      const defaultEmployeeRuleId = employeeRules?.data?.find(() => true)?.id;
      if (defaultEmployeeRuleId) {
        props.onChange(defaultEmployeeRuleId);
      } else {
        props.onChange(null);
      }
    }
  }, [employeeRules]);

  const employeeRulesSelectionItems = useMemo(() => {
    return employeeRules?.data?.map((item) => ({ id: item.id, text: item.title }));
  }, [employeeRules?.data]);

  return (
    <div className={'cds--fullwidth'}>
      <label className={'cds--label'}>Select rule</label>
      <Select
        labelText="Select an option"
        hideLabel
        id={'transaction-category-selector'}
        onChange={(e: any) => props.onChange(e.target.value)}
        invalid={!props.value && employeeRulesSelectionItems?.length}
        defaultValue={props.value}
      >
        {employeeRulesSelectionItems?.map((item) => {
          return <SelectItem value={item.id} key={item.id} text={item.text} />;
        })}
      </Select>
    </div>
  );
});

export default EmployeeRuleSelector;
