import React from 'react';
import { DatePicker, DatePickerInput } from '@carbon/react';

export function FormikDatePicker(props: {
  label: string;
  value: Date | string | null | undefined;
  onChange: (date: Date) => void;
  disabled?: boolean;
}) {
  const { label, value, disabled, onChange } = props;
  return (
    <DatePicker datePickerType="single" onChange={onChange} value={value} dateFormat={'d/m/Y'}>
      <DatePickerInput disabled={disabled} id="date-picker-single" labelText={label} placeholder="dd/mm/yyyy" />
    </DatePicker>
  );
}
