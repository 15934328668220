import { Field, Formik } from 'formik';
import React, { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack, TextInput, Toggle } from '@carbon/react';
import Row from 'src/components/Base/Row';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import { FormikRadioButtonGroup } from 'src/components/Form/FormikRadioButtonGroup';
import RestaurantBranchSelector from 'src/components/Form/RestaurantBranchSelector';
import { EmployeeEntity } from 'src/services/apis/types';
import { EmployeeRoles, EmployeeSalaryUnit } from 'src/utils/employee';
import { NumberToK } from 'src/utils/price';

interface EmployeeFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: EmployeeEntity | null | undefined;
}

const BaseSchema = z.object({
  firstName: z.string().max(50).trim(),
  lastName: z.string().max(50).trim(),
  username: z.string().max(50).trim(),
  email: z.string().max(120).email().trim(),
  phoneNumber: z.string().max(14).trim(),
  salary: z.preprocess((a) => parseInt(`${a}`, 10), z.number()),
});

const CreateSchema = BaseSchema.merge(
  z.object({
    password: z.string().max(255).trim(),
  })
);

const EmployeeForm = (props: EmployeeFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            id: '',
            restaurantBranchId: '',
            userId: '',
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            phoneNumber: '',
            password: '',
            salary: '',
            extraBonus: '',
            salaryUnit: 'M',
            role: 'E',
            partTime: false,
            activated: true,
          },
        }),
    };
  });

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(props.initialValues ? BaseSchema : CreateSchema)}
        initialValues={{
          id: props.initialValues?.id,
          restaurantBranchId: props.initialValues?.restaurantBranchId,
          userId: props.initialValues?.user?.id,
          firstName: props.initialValues?.user?.firstName,
          lastName: props.initialValues?.user?.lastName,
          username: props.initialValues?.user?.username,
          email: props.initialValues?.user?.email,
          phoneNumber: props.initialValues?.user?.phoneNumber,
          password: '',
          salary: NumberToK(props.initialValues?.salary || 0),
          extraBonus: NumberToK(props.initialValues?.extraBonus || 0),
          salaryUnit: props.initialValues?.salaryUnit || 'M',
          role: props.initialValues?.role || 'E',
          partTime: props.initialValues ? props.initialValues.partTime : true,
          activated: props.initialValues ? props.initialValues.activated : true,
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <RestaurantBranchSelector
                value={values.restaurantBranchId}
                onChange={(value) => setFieldValue('restaurantBranchId', value)}
              />
              <FormikRadioButtonGroup
                name={'role'}
                label={t('common.role')}
                value={values.role}
                onChange={(value) => setFieldValue('role', value)}
                options={EmployeeRoles}
              />
              <Row gap={2}>
                <FormikTextInput name={'firstName'} label={t('common.firstName')} />
                <FormikTextInput name={'lastName'} label={t('common.lastName')} />
              </Row>
              <FormikTextInput name={'email'} label={t('common.email')} />
              <FormikTextInput name={'username'} label={t('common.username')} />
              <FormikTextInput name={'phoneNumber'} label={t('common.phoneNumber')} />
              <FormikTextInput name={'salary'} label={`${t('common.salary')} (K)`} />
              <FormikTextInput name={'extraBonus'} label={`${t('common.extraBonus')} (K)`} />
              <FormikRadioButtonGroup
                name={'salaryUnit'}
                label={t('common.salaryUnit')}
                value={values.salaryUnit}
                onChange={(value) => setFieldValue('salaryUnit', value)}
                options={EmployeeSalaryUnit}
              />
              {!props.initialValues && (
                <Field name="password">
                  {({ field, meta }) => {
                    return (
                      <TextInput.PasswordInput
                        id="employeePassword"
                        labelText={t('common.password')}
                        name={'fakePassword'}
                        invalid={!!(meta.touched && meta.error)}
                        invalidText={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
              )}
              <Row gap={2}>
                <Toggle
                  labelText={t('common.activation')}
                  labelA={t('common.off')}
                  labelB={t('common.on')}
                  onToggle={(checked) => setFieldValue('activated', checked)}
                  toggled={values.activated}
                  id="activated-employeeForm"
                />
                <Toggle
                  labelText={t('common.partTime')}
                  labelA={t('common.no')}
                  labelB={t('common.yes')}
                  onToggle={(checked) => setFieldValue('partTime', checked)}
                  toggled={values.partTime}
                  id="partTime-employeeForm"
                />
              </Row>
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(EmployeeForm);
