import { AxiosError } from 'axios';
import i18n from 'src/i18n';

export function getMutationErrorMessage(error: AxiosError) {
  if (!error) {
    return null;
  }
  if ((error.response?.data as any)?.errors?.email) {
    return i18n.t('messages.emailAlreadyExist');
  }
  if ((error.response?.data as any)?.errors?.username) {
    return i18n.t('messages.usernameAlreadyExist');
  }
  if ((error.response?.data as any)?.errors?.slug) {
    return i18n.t('messages.slugAlreadyExist');
  }
  return i18n.t('messages.somethingWentWrong');
}
