import React, { useState } from 'react';
import SwipeToDelete from 'react-swipe-to-delete-ios';
import { shallow } from 'zustand/shallow';
import { MenuItemEntity } from 'src/services/apis/types';
import { usePOSStore } from 'src/states/pos';
import { NumberToK } from 'src/utils/price';

interface OrderLineProps {
  orderLine: {
    orderItem: MenuItemEntity;
    quantity: number;
    subTotal: number;
  };
  index: number;
}

const OrderLine = React.memo((props: OrderLineProps) => {
  const [editingTotal, setEditingTotal] = useState(false);
  const { deleteOrderItem, setOrderItemSubTotal } = usePOSStore(
    (state) => ({
      deleteOrderItem: state.deleteOrderItem,
      setOrderItemSubTotal: state.setOrderItemSubTotal,
    }),
    shallow
  );

  const handleChangeSubtotal = (e, isBlur = false) => {
    const value = e.target.value;
    if (isBlur || e.key === 'Enter' || e.keyCode === 13) {
      setEditingTotal(false);
      return setOrderItemSubTotal(props.orderLine.orderItem, parseInt(value, 10) * 1000);
    }
  };

  return (
    <SwipeToDelete
      onDelete={() => deleteOrderItem(props.orderLine.orderItem)}
      height={60}
      rtl={false}
      className={'pos-current-order-item-std'}
    >
      <div className="pos-current-order-item">
        <div className="index">{props.index}</div>
        <h4>{props.orderLine.orderItem.name}</h4>
        <span>x{props.orderLine.quantity}</span>
        {!editingTotal && <h5 onClick={() => setEditingTotal(true)}>{NumberToK(props.orderLine.subTotal)}K</h5>}
        {editingTotal && (
          <input
            type="number"
            min={5}
            defaultValue={NumberToK(props.orderLine.subTotal)}
            autoFocus
            onKeyUp={handleChangeSubtotal}
            onBlur={(e) => handleChangeSubtotal(e, true)}
          />
        )}
      </div>
    </SwipeToDelete>
  );
});

export default OrderLine;
