import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ContainedList, ExpandableSearch, ContainedListItem, Tag, Tooltip } from '@carbon/react';
import EmptyState from 'src/components/Base/EmptyState';
import Row from 'src/components/Base/Row';
import Spacing from 'src/components/Base/Spacing';
import { EmployeeDayOffEntity } from 'src/services/apis/types';

interface EmployeeDashboardDayOffProps {
  employeeMonthlyDayOff: EmployeeDayOffEntity[] | undefined;
}

const EmployeeDashboardDayOff = (props: EmployeeDashboardDayOffProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredDayOff = props.employeeMonthlyDayOff?.filter((item) =>
    searchTerm?.trim() ? item.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 : true
  );

  return (
    <div className="day-off">
      <Spacing space={2} />
      <h5 className={'section__heading'}>Your Day Off ({filteredDayOff?.length || 0})</h5>
      <ContainedList
        label="Day off in this month"
        kind="on-page"
        size={'lg'}
        action={
          <>
            <ExpandableSearch
              placeholder="Filterable search"
              value={searchTerm}
              onChange={handleChange}
              closeButtonLabelText="Clear search input"
              size="lg"
            />
          </>
        }
      >
        {(!filteredDayOff || filteredDayOff?.length === 0) && <EmptyState title={'No Day Off Found!'} />}
        {filteredDayOff?.map((dayOff) => (
          <ContainedListItem key={dayOff.id}>
            <Row gap={0.5} spaceBetween={true} alignCenter={true}>
              <h6>{dayOff.title}</h6>
              <Row gap={0} style={{ minWidth: '12rem', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <Tooltip align="bottom" label={dayOff.reason}>
                  <Tag type={'blue'} size={'md'}>
                    Reason
                  </Tag>
                </Tooltip>
                <Tag size={'md'} title="Clear filter" type={'red'}>
                  Amount: {dayOff.amount}d
                </Tag>
                <Tag size={'md'} title="Clear filter">
                  {dayjs(dayOff.createdAt).format('DD/MM/YYYY')}
                </Tag>
              </Row>
            </Row>
          </ContainedListItem>
        ))}
      </ContainedList>
    </div>
  );
};

export default EmployeeDashboardDayOff;
