import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { DatePicker, DatePickerInput, Tab, TabList, Tabs } from '@carbon/react';
import { Finance, Money } from '@carbon/react/icons';
import { DashboardDateRange } from '@restacity/shared/enums/dashboard';
import { useAuthPermissions } from 'src/hooks/useAuthPermissions';
import { useDashboardStore } from 'src/states/dashboard';

interface ToolbarProps {}

const routes = [
  {
    label: 'selling',
    url: '/dashboard/selling',
    icon: Money,
  },
  {
    label: 'accounting',
    url: '/dashboard/accounting',
    icon: Finance,
  },
];

const dateRanges = [
  {
    label: 'yesterday',
    range: DashboardDateRange.YESTERDAY,
  },
  {
    label: 'today',
    range: DashboardDateRange.DAILY,
  },
  {
    label: 'weekly',
    range: DashboardDateRange.WEEKLY,
  },
  {
    label: 'monthly',
    range: DashboardDateRange.MONTHLY,
  },
  {
    label: 'yearly',
    range: DashboardDateRange.YEARLY,
  },
  {
    label: 'all',
    range: DashboardDateRange.ALL,
  },
];

const getActiveTabIndex = (routes, pathname) => {
  return routes.findIndex((route) => pathname === route.url);
};

const Toolbar = React.memo((_: ToolbarProps) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const location = useLocation();
  const { isManager } = useAuthPermissions();
  const activeTabIndex = useMemo(() => getActiveTabIndex(routes, location.pathname), [location.pathname]);
  const { startDate, endDate, dateRange, setDates } = useDashboardStore(
    (state) => ({
      startDate: state.startDate,
      endDate: state.endDate,
      dateRange: state.dateRange,
      setDates: state.setDates,
    }),
    shallow
  );

  const handleChangeDateRange = useCallback((range: DashboardDateRange) => {
    if (range === DashboardDateRange.YESTERDAY) {
      setDates(dayjs().subtract(1, 'd').startOf('day').toDate(), dayjs().subtract(1, 'd').endOf('day').toDate(), range);
    }
    if (range === DashboardDateRange.DAILY) {
      setDates(dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate(), range);
    }
    if (range === DashboardDateRange.WEEKLY) {
      setDates(dayjs().startOf('isoWeek').toDate(), dayjs().endOf('isoWeek').toDate(), range);
    }
    if (range === DashboardDateRange.MONTHLY) {
      setDates(dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate(), range);
    }
    if (range === DashboardDateRange.YEARLY) {
      setDates(dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate(), range);
    }
    if (range === DashboardDateRange.ALL) {
      setDates(dayjs().subtract(3, 'year').startOf('year').toDate(), dayjs().endOf('year').toDate(), range);
    }
  }, []);

  const activeDateRange =
    dateRange === DashboardDateRange.CUSTOM
      ? dateRanges.length
      : dateRanges.findIndex((item) => item.range === dateRange);

  return (
    <div className="toolbar">
      <Tabs selectedIndex={activeTabIndex}>
        <TabList className="tabs-group" contained>
          {routes.map((route) => (
            <Tab
              key={route.url}
              renderIcon={route.icon}
              onClick={() => navigate(route.url, { state: { previousPath: location.pathname } })}
            >
              {t(route.label)}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <div className={'toolbar__dates'}>
        <Tabs selectedIndex={activeDateRange}>
          <TabList className="tabs-group" contained>
            {dateRanges.slice(isManager ? 1 : 0, dateRanges.length).map((item) => (
              <Tab
                onClick={() => handleChangeDateRange(item.range)}
                key={item.range}
                disabled={isManager && item.range !== DashboardDateRange.DAILY}
              >
                {t(`translation:dateRanges.${item.label}`)}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        {!isManager && (
          <DatePicker
            datePickerType="range"
            dateFormat={'d/m/Y'}
            value={dateRange === DashboardDateRange.CUSTOM ? [startDate, endDate] : []}
            onChange={(dates) => {
              if (dates.length > 1) {
                setDates(dates[0], dates[1], DashboardDateRange.CUSTOM);
              }
            }}
          >
            <DatePickerInput placeholder={t('translation:dateRanges.startDate')} />
            <DatePickerInput placeholder={t('translation:dateRanges.endDate')} />
          </DatePicker>
        )}
      </div>
    </div>
  );
});

export default Toolbar;
