import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Tab, TabList, Tabs } from '@carbon/react';

interface TabsWithTablesLayoutProps {
  children: React.ReactElement;
  title: string;
  homeUrl?: string;
  mainPageUrl?: string;
  routes: Array<{ label: string; url: string }>;
}

const getActiveTabIndex = (routes, pathname) => {
  return routes.findIndex((route) => pathname === route.url);
};

const TabsWithTablesLayout = React.memo(
  ({ title, homeUrl, mainPageUrl, children, routes }: TabsWithTablesLayoutProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const activeTabIndex = useMemo(() => getActiveTabIndex(routes, location.pathname), [location.pathname]);

    return (
      <div className="tabs-with-tables-layout">
        <div className="tabs-with-tables-layout__header">
          <Breadcrumb noTrailingSlash aria-label="Page navigation" className={'cds--breadcrumb--flex'}>
            <BreadcrumbItem>
              <Link to={homeUrl || '/'}>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={mainPageUrl || '/'}>{title}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={routes[activeTabIndex].url}>{routes[activeTabIndex].label}</Link>
            </BreadcrumbItem>
          </Breadcrumb>
          <h1 className="tabs-with-tables-layout__heading">{title}</h1>
        </div>
        <Tabs selectedIndex={activeTabIndex}>
          <TabList className="tabs-group" aria-label="Tab navigation" contained>
            {routes.map((route) => (
              <Tab key={route.url} onClick={() => navigate(route.url, { state: { previousPath: location.pathname } })}>
                {route.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <div className="tabs-with-tables-layout__main-content">{children}</div>
      </div>
    );
  }
);

export default TabsWithTablesLayout;
