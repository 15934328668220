import { create } from 'zustand';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import { MenuItemEntity, OrderEntity } from 'src/services/apis/types';

interface POSState {
  expandingRecentOrders: boolean;
  viewingOrder?: OrderEntity | null;
  currentOrderItems: Array<{
    orderItem: MenuItemEntity & { menuItemVariantId?: string };
    quantity: number;
    subTotal: number;
    editing?: boolean;
  }>;
  currentOrderInfo: {
    tableId?: string;
    type?: number;
    notes?: string;
    phoneNumber?: string;
    address?: string;
    editingOrder?: OrderEntity;
  };
  recentOrdersFilters: {
    type?: OrderTypeEnum | null | undefined;
    status?: OrderStatusEnum | null | undefined;
  };
  selectedMenuId: string | null;
  toggleExpandingRecentOrders: () => void;
  addOrderItem: (orderItem: MenuItemEntity & { menuItemVariantId?: string }, quantity: number) => void;
  minusOrderItem: (orderItem: MenuItemEntity & { menuItemVariantId?: string }, quantity: number) => void;
  setOrderItemSubTotal: (menuItem: MenuItemEntity & { menuItemVariantId?: string }, subTotal: number) => void;
  deleteOrderItem: (orderItem: MenuItemEntity & { menuItemVariantId?: string }) => void;
  clearOrderItems: () => void;
  setEditOrder: (order: OrderEntity, menuItems: MenuItemEntity[]) => void;
  setOrderTableId: (tableId: string) => void;
  setOrderNotes: (notes: string) => void;
  setOrderPhoneNumber: (phoneNumber: string) => void;
  setOrderAddress: (address: string) => void;
  setOrderType: (type: OrderTypeEnum) => void;
  clearCurrentOrder: () => void;
  setViewingOrder: (order: OrderEntity | null) => void;
  setRecentOrdersFilters: (data: { type?: OrderTypeEnum | null; status?: OrderStatusEnum | null }) => void;
  setSelectedMenuId: (menuId: string) => void;
}

export const usePOSStore = create<POSState>((set) => ({
  expandingRecentOrders: false,
  currentOrderItems: [],
  currentOrderInfo: {
    type: OrderTypeEnum.DINE_IN,
  },
  recentOrdersFilters: {
    type: null,
    status: null,
  },
  selectedMenuId: null,
  addOrderItem: (menuItem, quantity) => {
    set((state) => {
      if (state.currentOrderItems.find((item) => item.orderItem.id === menuItem.id)) {
        return {
          currentOrderItems: state.currentOrderItems.map((item) =>
            item.orderItem.id === menuItem.id
              ? {
                  ...item,
                  quantity: item.quantity + quantity,
                  subTotal: item.subTotal + quantity * item.orderItem.price,
                  editing: true,
                }
              : item
          ),
        };
      } else {
        return {
          currentOrderItems: [
            ...state.currentOrderItems,
            { orderItem: menuItem, quantity, subTotal: quantity * menuItem.price, editing: true },
          ],
        };
      }
    });
  },
  minusOrderItem: (menuItem, quantity) => {
    set((state) => {
      const orderItem = state.currentOrderItems.find((item) => item.orderItem.id === menuItem.id);
      if (orderItem) {
        if (orderItem.quantity === 1) {
          return {
            currentOrderItems: state.currentOrderItems.filter((item) => item.orderItem.id !== menuItem.id),
          };
        }
        return {
          currentOrderItems: state.currentOrderItems.map((item) =>
            item.orderItem.id === menuItem.id
              ? {
                  ...item,
                  quantity: item.quantity - quantity,
                  subTotal: item.subTotal - quantity * item.orderItem.price,
                  editing: true,
                }
              : item
          ),
        };
      }
      return {};
    });
  },
  deleteOrderItem: (menuItem) => {
    set((state) => {
      return {
        currentOrderItems: state.currentOrderItems.filter((item) => item.orderItem.id !== menuItem.id),
      };
    });
  },
  setOrderItemSubTotal: (menuItem, subTotal) => {
    set((state) => {
      return {
        currentOrderItems: state.currentOrderItems.map((item) =>
          item.orderItem.id === menuItem.id
            ? {
                ...item,
                subTotal: subTotal,
                editing: true,
              }
            : item
        ),
      };
    });
  },
  setEditOrder: (order, menuItems: MenuItemEntity[]) => {
    return set(() => ({
      currentOrderInfo: {
        tableId: order.tableId,
        type: order.type,
        address: order.line1,
        phoneNumber: order.phoneNumber,
        notes: order.notes,
        editingOrder: order,
      },
      currentOrderItems: order.orderItems.map((item) => ({
        orderItem: menuItems.find((r) => r.id === item.menuItemId)!,
        quantity: item.quantity,
        subTotal: item.subTotal,
        editing: false,
      })),
    }));
  },
  setOrderTableId: (tableId) => set((state) => ({ currentOrderInfo: { ...state.currentOrderInfo, tableId } })),
  setOrderNotes: (notes) => set((state) => ({ currentOrderInfo: { ...state.currentOrderInfo, notes } })),
  setOrderPhoneNumber: (phoneNumber) =>
    set((state) => ({ currentOrderInfo: { ...state.currentOrderInfo, phoneNumber } })),
  setOrderAddress: (address) => set((state) => ({ currentOrderInfo: { ...state.currentOrderInfo, address } })),
  setOrderType: (type) => set((state) => ({ currentOrderInfo: { ...state.currentOrderInfo, type } })),
  clearOrderItems: () => set(() => ({ currentOrderItems: [] })),
  toggleExpandingRecentOrders: () => set((state) => ({ expandingRecentOrders: !state.expandingRecentOrders })),
  setViewingOrder: (order) => set(() => ({ viewingOrder: order })),
  setSelectedMenuId: (menuId) => set(() => ({ selectedMenuId: menuId })),
  clearCurrentOrder: () =>
    set(() => ({
      currentOrderItems: [],
      currentOrderInfo: {
        type: OrderTypeEnum.DINE_IN,
      },
    })),
  setRecentOrdersFilters: (filters) =>
    set(() => ({
      recentOrdersFilters: filters,
    })),
}));
