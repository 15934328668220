import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

interface OrderDateProps {
  date: string;
  format?: string;
}

const OrderDate = React.memo((props: OrderDateProps) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(Date.now()), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const orderMinutes = dayjs(new Date(currentTime)).diff(dayjs(new Date(props.date)), 'minutes');

  if (orderMinutes > 60) {
    return <time>{dayjs(props.date).format(props.format || 'DD/MM HH:mm')}</time>;
  }

  return <time>{dayjs(props.date).fromNow()}</time>;
});

export default OrderDate;
