import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { GroupedBarChart } from '@carbon/charts-react';
import { green, red, blue } from '@carbon/colors';
import { useAppStore } from 'src/states/app';
import { TransactionTypeEnum, TransactionTypes } from 'src/utils/accounting';
import { NumberToK } from 'src/utils/price';

interface SalesByMenuProps {
  title: string;
  transactionsByDateRange: any[];
  dateFormat: string;
}

const DailyTransactions = React.memo((props: SalesByMenuProps) => {
  const { t } = useTranslation('dashboard');
  const { theme } = useAppStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow
  );
  const transformedTransactionTypes = TransactionTypes.reduce((previousObject, currentObject) => {
    return Object.assign(previousObject, {
      [currentObject.value]: t(`translation:${currentObject.label}`),
    });
  }, {});

  const getScaleOptions = () => {
    const options = {};
    props.transactionsByDateRange.map((item) => {
      if (
        !options[transformedTransactionTypes[item.transactionType]] &&
        item.transactionType === TransactionTypeEnum.INCOME
      ) {
        options[transformedTransactionTypes[TransactionTypeEnum.INCOME]] = green[50];
      }
      if (
        !options[transformedTransactionTypes[item.transactionType]] &&
        item.transactionType === TransactionTypeEnum.EXPENSE
      ) {
        options[transformedTransactionTypes[TransactionTypeEnum.EXPENSE]] = red[60];
      }
      if (
        !options[transformedTransactionTypes[item.transactionType]] &&
        item.transactionType === TransactionTypeEnum.INVESTMENT
      ) {
        options[transformedTransactionTypes[TransactionTypeEnum.INVESTMENT]] = blue[50];
      }
    });
    return options;
  };

  return (
    <div className="dashboard__stat_widget">
      <GroupedBarChart
        data={props.transactionsByDateRange.map((item) => ({
          group: transformedTransactionTypes[item.transactionType],
          date: item.transactionDate,
          value: NumberToK(item.transactionTotal),
        }))}
        options={{
          title: props.title,
          axes: {
            left: {
              mapsTo: 'value',
            },
            bottom: {
              mapsTo: 'date',
              scaleType: 'time' as any,
              ticks: {
                formatter: (date) => {
                  if (
                    dayjs(date).format(props.dateFormat) === dayjs().format(props.dateFormat) &&
                    props.dateFormat === 'DD/MM'
                  ) {
                    return `Today (${dayjs(date).format(props.dateFormat)})`;
                  }
                  return dayjs(date).format(props.dateFormat || 'DD/MM');
                },
              },
            },
          },
          color: {
            pairing: {
              option: 2,
            },
            scale: getScaleOptions(),
          },
          experimental: true,
          height: '400px',
          zoomBar: {
            top: {
              enabled: true,
              type: 'slider_view' as any,
              initialZoomDomain:
                props.dateFormat === 'MM/YYYY'
                  ? [dayjs().subtract(7, 'month').toDate(), dayjs().add(1, 'month').toDate()]
                  : [dayjs().subtract(7, 'day').toDate(), dayjs().add(1, 'day').toDate()],
            },
          },
          theme: theme === 'dark' ? 'g100' : ('g10' as any),
        }}
      />
    </div>
  );
});

export default DailyTransactions;
