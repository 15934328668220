import { Formik } from 'formik';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack } from '@carbon/react';
import Row from 'src/components/Base/Row';
import Spacing from 'src/components/Base/Spacing';
import EmployeeSelector from 'src/components/Form/EmployeeSelector';
import { FormikDatePicker } from 'src/components/Form/FormikDatePicker';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import RestaurantBranchSelector from 'src/components/Form/RestaurantBranchSelector';
import { EmployeeDayOffEntity } from 'src/services/apis/types';

interface EmployeeDayOffFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: EmployeeDayOffEntity | null | undefined;
}

const BaseSchema = z.object({
  title: z.string().max(50).trim(),
  reason: z.string().max(50).trim(),
  amount: z.preprocess((a) => parseFloat(`${a}`), z.number()),
});

const EmployeeDayOffForm = (props: EmployeeDayOffFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();
  const [submitted, setSubmitted] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      submitForm: () => {
        setSubmitted(true);
        formikRef.current?.submitForm();
      },
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            id: '',
            title: '',
            amount: 0,
            date: null,
            reason: '',
            tags: [],
          },
        }),
    };
  });

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(BaseSchema)}
        initialValues={{
          id: props.initialValues?.id,
          employeeId: props.initialValues?.employeeId,
          date: props.initialValues?.date,
          restaurantBranchId: props.initialValues?.restaurantBranchId,
          title: props.initialValues?.title,
          amount: props.initialValues?.amount,
          reason: props.initialValues?.reason,
          tags: props.initialValues?.tags?.join(','),
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <RestaurantBranchSelector
                value={values.restaurantBranchId}
                onChange={(value) => {
                  setFieldValue('restaurantBranchId', value);
                }}
              />
              <EmployeeSelector
                value={values.employeeId}
                invalid={submitted && !values.employeeId}
                branchId={values.restaurantBranchId}
                onChange={(value) => {
                  setFieldValue('employeeId', value);
                }}
              />
              <FormikTextInput name={'title'} label={t('common.title')} />
              <FormikTextInput name={'reason'} label={t('common.reason')} />
              <Row gap={1}>
                <FormikDatePicker
                  label={t('common.date')}
                  value={values.date}
                  onChange={(dates) => setFieldValue('date', dates[0])}
                />
                <div className="cds--fullwidth">
                  <FormikTextInput name={'amount'} isNumber max={50000} label={`${t('common.amount')}`} />
                </div>
              </Row>
              <FormikTextInput name={'tags'} label={`${t('common.tags')} (${t('common.separateByAComma')})`} />
            </Stack>
            <Spacing space={1.5} />
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(EmployeeDayOffForm);
