import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { Loading } from '@carbon/react';
import { Delivery, DeliveryParcel, Money, ShoppingBag, Store } from '@carbon/react/icons';
import { DashboardDateRange } from '@restacity/shared/enums/dashboard';
import { OrderTypeEnum } from '@restacity/shared/enums/order';
import DailySales from 'src/components/Dashboard/DailySales';
import ItemsSales from 'src/components/Dashboard/ItemsSales';
import SalesGoal from 'src/components/Dashboard/SalesGoal';
import StatCard from 'src/components/Dashboard/StatCard';
import { useDashboardSalesReports } from 'src/hooks/useDashboardReports';
import { useBranches, useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useDashboardStore } from 'src/states/dashboard';
import { NumberToK } from 'src/utils/price';

interface SellingProps {}

const Selling = React.memo((_: SellingProps) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const {
    query: { isLoading: salesReportsLoading, data: report, isRefetching },
  } = useDashboardSalesReports();
  const { dateRange, startDate, endDate } = useDashboardStore(
    (state) => ({
      dateRange: state.dateRange,
      startDate: state.startDate,
      endDate: state.endDate,
    }),
    shallow
  );
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const branches = useBranches();
  const totalSales = report?.salesReports?.totalSales || 0;
  const totalOrders = report?.salesReports?.totalOrders || 0;
  const totalTakeawaySales =
    report?.salesReports?.salesReportsByType?.find((item) => item.orderType === OrderTypeEnum.TAKEAWAY)?.totalSales ||
    0;
  const totalDeliverySales =
    report?.salesReports?.salesReportsByType?.find((item) => item.orderType === OrderTypeEnum.DELIVERY)?.totalSales ||
    0;
  const totalDineInSales =
    report?.salesReports?.salesReportsByType?.find((item) => item.orderType === OrderTypeEnum.DINE_IN)?.totalSales || 0;

  const salesByDateRange = report?.salesReports?.salesByDateRange || [];
  const orderItemsSalesReports = report?.orderItemsSalesReports || [];

  const getSalesGoal = useCallback(() => {
    if (!currentRestaurantBranch) {
      switch (dateRange) {
        case DashboardDateRange.DAILY:
          return branches?.data?.data
            ?.map((item) => item.salesGoalPerDay)
            .reduce((total, quantity) => total + quantity);
        case DashboardDateRange.YESTERDAY:
          return branches?.data?.data
            ?.map((item) => item.salesGoalPerDay)
            .reduce((total, quantity) => total + quantity);
        case DashboardDateRange.WEEKLY:
          return branches?.data?.data
            ?.map((item) => item.salesGoalPerWeek)
            .reduce((total, quantity) => total + quantity);
        case DashboardDateRange.MONTHLY:
          return branches?.data?.data
            ?.map((item) => item.salesGoalPerMonth)
            .reduce((total, quantity) => total + quantity);
        case DashboardDateRange.YEARLY:
          return branches?.data?.data
            ?.map((item) => item.salesGoalPerYear)
            .reduce((total, quantity) => total + quantity);
      }
    }
    switch (dateRange) {
      case DashboardDateRange.DAILY:
        return currentRestaurantBranch?.salesGoalPerDay;
      case DashboardDateRange.YESTERDAY:
        return currentRestaurantBranch?.salesGoalPerDay;
      case DashboardDateRange.WEEKLY:
        return currentRestaurantBranch?.salesGoalPerWeek;
      case DashboardDateRange.MONTHLY:
        return currentRestaurantBranch?.salesGoalPerMonth;
      case DashboardDateRange.YEARLY:
        return currentRestaurantBranch?.salesGoalPerYear;
    }
  }, [dateRange, currentRestaurantBranch, branches.isLoading]);

  const handleViewDetails = useCallback(
    (orderType: OrderTypeEnum | null) => {
      const filteredParams = {
        orderType: `${orderType}` || 'all',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
      navigate({
        pathname: '/orders',
        search: `?${createSearchParams(filteredParams)}`,
      });
    },
    [startDate, endDate]
  );

  const isLoading = salesReportsLoading || branches.isLoading;

  return (
    <div className={'selling'}>
      {(isLoading || isRefetching) && <Loading small />}
      {!currentRestaurantBranch && (
        <div className="stats">
          {branches?.data?.data.map((branch) => (
            <StatCard
              title={`${branch.name}`}
              onClick={() => handleViewDetails(null)}
              value={NumberToK(
                report?.salesReports?.totalSalesReportsByBranches.find((sale) => sale.restaurantBranchId === branch.id)
                  ?.totalSales || 0
              )}
              unit={'K'}
              icon={<Store size={36} />}
            />
          ))}
        </div>
      )}
      <div className="stats">
        <StatCard
          title={t('totalSales')}
          onClick={() => handleViewDetails(null)}
          value={NumberToK(totalSales)}
          unit={'K'}
          icon={<Money size={36} />}
        />
        <StatCard
          title={t('totalOrders')}
          onClick={() => handleViewDetails(null)}
          value={totalOrders}
          icon={<ShoppingBag size={36} />}
        />
        <StatCard
          title={t('totalTakeawaySales')}
          onClick={() => handleViewDetails(OrderTypeEnum.TAKEAWAY)}
          value={NumberToK(totalTakeawaySales)}
          unit={'K'}
          icon={<DeliveryParcel size={36} />}
        />
        <StatCard
          title={t('totalDeliverySales')}
          onClick={() => handleViewDetails(OrderTypeEnum.TAKEAWAY)}
          value={NumberToK(totalDeliverySales)}
          unit={'K'}
          icon={<Delivery size={36} />}
        />
      </div>
      <div className="stats">
        {report?.foodAppsSalesReports?.map((item) => (
          <StatCard
            key={item.category.id}
            title={item.category.name}
            onClick={() => handleViewDetails(null)}
            value={NumberToK(item.total)}
            unit={'K'}
            icon={<Money size={36} />}
          />
        ))}
      </div>
      <div className="stats">
        <SalesGoal
          goal={NumberToK(getSalesGoal() || 0)}
          totalDineInSales={NumberToK(totalDineInSales)}
          totalTakeawaySales={NumberToK(totalTakeawaySales)}
          totalDeliverySales={NumberToK(totalDeliverySales)}
          foodAppsSales={report?.foodAppsSalesReports || []}
          isLoading={isLoading}
          title={t('dailySalesGoal')}
        />
      </div>
      <div className="stats">
        <DailySales
          dateFormat={dateRange === DashboardDateRange.YEARLY ? 'MM/YYYY' : 'DD/MM'}
          salesByDateRange={salesByDateRange}
          title={
            dateRange === DashboardDateRange.YEARLY
              ? t('salesPerMonth', { unit: 'K' })
              : t('salesPerDay', { unit: 'K' })
          }
        />
      </div>
      <div className="stats">
        <ItemsSales
          valueKey={'totalSales'}
          orderItemsSalesReports={orderItemsSalesReports}
          title={t('salesPerMenuItem', { unit: 'K' })}
        />
      </div>
      <div className="stats">
        <ItemsSales
          valueKey={'totalQuantities'}
          orderItemsSalesReports={orderItemsSalesReports}
          title={t('salesPerMenuItemByQuantity')}
        />
      </div>
    </div>
  );
});

export default Selling;
