import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '@carbon/react';
import EmptyState from 'src/components/Base/EmptyState';
import KitchenOrderItem from 'src/components/Kitchen/KitchenOrderItem';
import { useInProgressOrders } from 'src/hooks/useOrders';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';

interface KitchenProps {}

const Kitchen = React.memo((_: KitchenProps) => {
  const { t } = useTranslation();
  const { isLoading, data: orders } = useInProgressOrders();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  return (
    <div className="kitchen-page">
      <div className={'kitchen-page-orders'}>
        {isLoading && <Loading small />}
        {!isLoading && !currentRestaurantBranch && <EmptyState title={t('messages.pleaseSelectABranch')} />}
        {!isLoading && orders?.data?.length === 0 && !!currentRestaurantBranch && (
          <EmptyState title={t('messages.noInProgressOrders')} />
        )}
        <div className={'orders-grid'}>
          {orders?.data?.map((order) => (
            <KitchenOrderItem order={order} key={order.id} />
          ))}
        </div>
      </div>
    </div>
  );
});

export default Kitchen;
