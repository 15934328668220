import React from 'react';
import CurrentOrder from 'src/components/POS/CurrentOrder';
import Menu from 'src/components/POS/Menu';
import MenuItems from 'src/components/POS/MenuItems';
import RecentOrders from 'src/components/POS/RecentOrders';

interface POSProps {}

const POS = React.memo((_: POSProps) => {
  return (
    <div className="pos-page">
      <div className="pos-page__main-content">
        <div className={'pos-items'}>
          <div className={'pos-menu-container'}>
            <Menu />
            <MenuItems />
          </div>
          <hr />
          <RecentOrders />
        </div>
        <CurrentOrder />
      </div>
    </div>
  );
});

export default POS;
