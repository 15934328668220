import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { SimpleBarChart } from '@carbon/charts-react';
import { green, magenta, blue } from '@carbon/colors';
import { OrderTypeEnum } from '@restacity/shared/enums/order';
import { useAppStore } from 'src/states/app';
import { OrderTypes } from 'src/utils/order';
import { NumberToK } from 'src/utils/price';

interface SalesByMenuProps {
  title: string;
  salesByDateRange: any[];
  dateFormat: string;
}

const DailySales = React.memo((props: SalesByMenuProps) => {
  const { t } = useTranslation('dashboard');
  const { theme } = useAppStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow
  );
  const transformedOrderTypes = OrderTypes.reduce((previousObject, currentObject) => {
    return Object.assign(previousObject, {
      [currentObject.value]: t(`translation:${currentObject.label}`),
    });
  }, {});

  const getScaleOptions = () => {
    const options = {};
    props.salesByDateRange.map((item) => {
      if (!options[transformedOrderTypes[item.orderType]] && item.orderType === OrderTypeEnum.DINE_IN) {
        options[transformedOrderTypes[OrderTypeEnum.DINE_IN]] = green[60];
      }
      if (!options[transformedOrderTypes[item.orderType]] && item.orderType === OrderTypeEnum.TAKEAWAY) {
        options[transformedOrderTypes[OrderTypeEnum.TAKEAWAY]] = magenta[60];
      }
      if (!options[transformedOrderTypes[item.orderType]] && item.orderType === OrderTypeEnum.DELIVERY) {
        options[transformedOrderTypes[OrderTypeEnum.DELIVERY]] = blue[50];
      }
    });
    return options;
  };

  return (
    <div className="dashboard__stat_widget">
      <SimpleBarChart
        data={props.salesByDateRange.map((item) => ({
          group: transformedOrderTypes[item.orderType],
          date: dayjs(item.date).toDate(),
          value: NumberToK(item.totalSales),
        }))}
        options={{
          title: props.title,
          axes: {
            left: {
              mapsTo: 'value',
              ticks: {
                formatter: (value) => `${value}K`,
              },
            },
            bottom: {
              mapsTo: 'date',
              scaleType: 'time' as any,
              ticks: {
                formatter: (date) => {
                  if (
                    dayjs(date).format(props.dateFormat) === dayjs().format(props.dateFormat) &&
                    props.dateFormat === 'DD/MM'
                  ) {
                    return `Today (${dayjs(date).format(props.dateFormat)})`;
                  }
                  return dayjs(date).format(props.dateFormat || 'DD/MM');
                },
              },
            },
          },
          experimental: true,
          color: {
            pairing: {
              option: 2,
            },
            scale: getScaleOptions(),
          },
          zoomBar: {
            top: {
              enabled: true,
              type: 'slider_view' as any,
              initialZoomDomain:
                props.dateFormat === 'MM/YYYY'
                  ? [dayjs().subtract(7, 'month').toDate(), dayjs().add(1, 'month').toDate()]
                  : [dayjs().subtract(7, 'day').toDate(), dayjs().add(1, 'day').toDate()],
            },
          },
          height: '400px',
          theme: theme === 'dark' ? 'g100' : ('g10' as any),
        }}
      />
    </div>
  );
});

export default DailySales;
