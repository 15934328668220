import { Formik } from 'formik';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack } from '@carbon/react';
import EmployeeRuleSelector from 'src/components/Form/EmployeeRuleSelector';
import EmployeeSelector from 'src/components/Form/EmployeeSelector';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import { FormikRadioButtonGroup } from 'src/components/Form/FormikRadioButtonGroup';
import RestaurantBranchSelector from 'src/components/Form/RestaurantBranchSelector';
import { useEmployeeRules } from 'src/hooks/useEmployeeRules';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { IssueEntity } from 'src/services/apis/types';
import { IssueStatusEnum, IssueStatuses, IssueTypeEnum, IssueTypes } from 'src/utils/issue';
import { NumberToK } from 'src/utils/price';

interface IssueFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  type?: IssueTypeEnum;
  initialValues: IssueEntity | null | undefined;
}

const BaseSchema = z.object({
  title: z.string().max(50).trim(),
});

const IssueForm = (props: IssueFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const { data: employeeRules } = useEmployeeRules();
  const [submitted, setSubmitted] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      submitForm: () => {
        setSubmitted(true);
        formikRef.current?.submitForm();
      },
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () => {
        setSubmitted(false);
        formikRef.current?.resetForm({
          values: {
            id: '',
            restaurantBranchId: '',
            title: '',
            notes: '',
            description: '',
            type: '',
            status: '',
            tags: '',
          },
        });
      },
    };
  });

  const getDefaultStatus = () => {
    return IssueStatusEnum.OPEN;
  };

  const initialType = props.initialValues?.type || props.type || IssueTypeEnum.EMPLOYEE;

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(BaseSchema)}
        initialValues={{
          id: props.initialValues?.id,
          restaurantBranchId: props.initialValues?.restaurantBranchId || currentRestaurantBranch?.id,
          employeeId: props.initialValues?.employeeId,
          employeeRuleId: props.initialValues?.employeeRuleId,
          fine: props.initialValues ? NumberToK(props.initialValues?.fine) : 0,
          title: props.initialValues?.title,
          notes: props.initialValues?.notes,
          description: props.initialValues?.description,
          type: initialType,
          status: props.initialValues?.status || getDefaultStatus(),
          tags: props.initialValues?.tags ? props.initialValues?.tags.join(',') : '',
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <RestaurantBranchSelector
                value={values.restaurantBranchId}
                onChange={(value) => {
                  setFieldValue('restaurantBranchId', value);
                }}
              />
              <FormikRadioButtonGroup
                name={'type'}
                label={t('common.type')}
                value={values.type!}
                useTranslation
                onChange={(value) => {
                  setFieldValue('type', value);
                }}
                options={IssueTypes}
              />
              {values.type === IssueTypeEnum.EMPLOYEE && (
                <EmployeeSelector
                  value={values.employeeId}
                  invalid={submitted && !values.employeeId}
                  branchId={values.restaurantBranchId}
                  onChange={(value) => {
                    setFieldValue('employeeId', value);
                  }}
                />
              )}
              {values.type === IssueTypeEnum.EMPLOYEE && (
                <EmployeeRuleSelector
                  value={values.employeeRuleId}
                  onChange={(value) => {
                    setFieldValue('employeeRuleId', value);
                    if (!props.initialValues?.title) {
                      setFieldValue('title', employeeRules?.data?.find((item) => item.id === value)?.title);
                    }
                    if (!props.initialValues?.description) {
                      setFieldValue('description', employeeRules?.data?.find((item) => item.id === value)?.description);
                    }
                    if (!props.initialValues?.fine) {
                      setFieldValue(
                        'fine',
                        NumberToK(employeeRules?.data?.find((item) => item.id === value)?.fine || 0)
                      );
                    }
                  }}
                />
              )}
              <FormikTextInput name={'title'} label={t('common.title')} />
              <FormikTextInput name={'description'} label={t('common.description')} />
              {values.type === IssueTypeEnum.EMPLOYEE && (
                <FormikTextInput name={'fine'} isNumber max={50000} label={`${t('common.fine')} (K)`} />
              )}
              <FormikTextInput name={'notes'} label={t('common.notes')} />
              <FormikTextInput name={'tags'} label={t('common.tags')} />
              <FormikRadioButtonGroup
                name={'status'}
                useTranslation
                label={t('common.status')}
                value={values.status!}
                onChange={(value) => setFieldValue('status', value)}
                options={IssueStatuses}
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(IssueForm);
