import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImgsViewer from 'react-images-viewer';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Link,
  Modal,
  Loading,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import BranchForm from 'src/components/Branch/BranchForm';
import { CropperModal } from 'src/components/Uploader/CropperModal';
import {
  useBranches,
  useCreateRestaurantBranchMutation,
  useDeleteRestaurantBranchMutation,
  useUpdateRestaurantBranchMutation,
} from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { RestaurantBranchEntity } from 'src/services/apis/types';
import { getImageUrl } from 'src/utils/cloudinary';
import { getMutationErrorMessage } from 'src/utils/error';
import { KToNumber } from 'src/utils/price';

const RestaurantBranchesSettings = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const { isLoading, data } = useBranches();
  const createRestaurantBranchMutation = useCreateRestaurantBranchMutation();
  const updateRestaurantBranchMutation = useUpdateRestaurantBranchMutation();
  const deleteRestaurantBranchMutation = useDeleteRestaurantBranchMutation();
  const currentRestaurant = useCurrentRestaurant();
  const [openAddBranch, setOpenAddBranch] = useState(false);
  const [editingBranch, setEditingBranch] = useState<null | undefined | RestaurantBranchEntity>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [croppingFile, setCroppingFile] = useState<{ file: File; previewUrl: string } | null>(null);
  const [deletingBranch, setDeletingBranch] = useState<null | undefined | RestaurantBranchEntity>(null);
  const [viewingBranchPhotos, setViewingBranchPhotos] = useState<RestaurantBranchEntity[]>([]);

  const handleCloseAddOrEditBranch = useCallback(() => {
    setOpenAddBranch(false);
    setEditingBranch(null);
    formRef.current?.resetForm();
  }, []);

  const handleAddBranch = useCallback(() => {
    setOpenAddBranch(true);
  }, []);

  const handleCreate = useCallback(
    async (values) => {
      await createRestaurantBranchMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        latitude: parseFloat(values.latitude),
        longitude: parseFloat(values.longitude),
      });
      formRef.current?.resetForm();
      handleCloseAddOrEditBranch();
    },
    [currentRestaurant?.id]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateRestaurantBranchMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        latitude: parseFloat(values.latitude),
        longitude: parseFloat(values.longitude),
        salesGoalPerDay: KToNumber(values.salesGoalPerDay || 0),
        salesGoalPerWeek: KToNumber(values.salesGoalPerWeek || 0),
        salesGoalPerMonth: KToNumber(values.salesGoalPerMonth || 0),
        salesGoalPerYear: KToNumber(values.salesGoalPerYear || 0),
      });
      formRef.current?.resetForm();
      handleCloseAddOrEditBranch();
    },
    [currentRestaurant?.id]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingBranch(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteRestaurantBranchMutation.mutateAsync(deletingBranch?.id!);
    setDeletingBranch(null);
  }, [deletingBranch]);

  const handleCropped = useCallback(({ fileId, format, blurhash }) => {
    setCroppingFile(null);
    formRef.current?.setFieldValue('photo', `${fileId}.${format}`);
    formRef.current?.setFieldValue('photoBlurhash', blurhash);
  }, []);

  const headers = [
    {
      key: 'name',
      header: t('common.name'),
    },
    {
      key: 'slug',
      header: t('common.slug'),
    },
    {
      key: 'address',
      header: t('common.address'),
    },
  ];

  const extraHeaders = [
    { key: 'photo', header: t('common.photo') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return (
      <DataTableSkeleton
        columnCount={headers.length + extraHeaders.length}
        rowCount={6}
        headers={[...headers, ...extraHeaders]}
      />
    );
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (searchQuery) {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.slug.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return true;
      })
      .map((item) => ({
        ...item,
      })) || [];

  const submitting = createRestaurantBranchMutation.isLoading || updateRestaurantBranchMutation.isLoading;
  const createError = getMutationErrorMessage(createRestaurantBranchMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateRestaurantBranchMutation.error as AxiosError);

  const tableTitle = t('titles.branchSettingsPage', { restaurantName: currentRestaurant?.name });

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer title={tableTitle} description={`${data?.total} ${t('common.branches')}`}>
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={(e) => setSearchQuery(e.target.value)} />
                <Button renderIcon={Add} onClick={handleAddBranch}>
                  {t('actions.create')} {t('common.branch')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      {filteredRows[index]?.photo && (
                        <Link onClick={() => setViewingBranchPhotos([filteredRows[index]])}>
                          {filteredRows[index]?.photo}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingBranch(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingBranch(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddBranch || !!editingBranch}
        size={'sm'}
        modalHeading={
          openAddBranch
            ? `${t('actions.createNew')} ${t('common.branch')}`
            : `${t('actions.update')} ${t('common.branch')}`
        }
        modalLabel={tableTitle}
        primaryButtonText={
          submitting ? `${t('common.loading')}...` : editingBranch ? t('actions.update') : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditBranch}
        onRequestClose={handleCloseAddOrEditBranch}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <BranchForm
          errorMessage={createError || updateError}
          initialValues={editingBranch}
          ref={formRef}
          onSubmit={editingBranch ? handleUpdate : handleCreate}
          onSelectedFile={setCroppingFile}
        />
      </Modal>
      <CropperModal
        croppingFile={croppingFile}
        onClose={() => setCroppingFile(null)}
        cropperAspectRatio={16 / 9}
        onChange={handleCropped}
      />
      <DeleteConfirmation
        open={!!deletingBranch}
        heading={t('messages.areYouSureToDeleteThisBranch')}
        label={tableTitle}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteRestaurantBranchMutation.isLoading}
      />
      <ImgsViewer
        imgs={viewingBranchPhotos.map((item) => ({
          src: getImageUrl(item.photo),
          caption: `${currentRestaurant?.name} - ${item.name} ${t('common.photos')}`,
        }))}
        showCloseBtn={false}
        backdropCloseable={true}
        className={'cds--images-viewer'}
        width={'calc(100vw - 16rem)'}
        isOpen={viewingBranchPhotos.length > 0}
        onClickPrev={() => null}
        onClickNext={() => null}
        spinnerSize={10}
        spinner={() => <Loading className={'some-class'} withOverlay={false} />}
        onClose={() => setViewingBranchPhotos([])}
      />
    </>
  );
});

export default RestaurantBranchesSettings;
