import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { Link } from '@carbon/react';

interface StatCardProps {
  icon: React.ReactElement;
  title: string;
  value: number;
  unit?: string;
  onClick?: () => void;
}

const StatCard = React.memo((props: StatCardProps) => {
  const { t } = useTranslation('dashboard');
  return (
    <div className="dashboard__stat_widget">
      <h4>{props.title}</h4>
      <h3>
        <CountUp end={props.value} />
        {props.unit}
      </h3>
      <Link onClick={props.onClick}>{t('viewDetails')}</Link>
      <div className="icon">{props.icon}</div>
    </div>
  );
});

export default StatCard;
