import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderPanel, Link as CarbonLink, SideNavItems, SideNavLink } from '@carbon/react';
import { Switcher, Store } from '@carbon/react/icons';
import { useBranches, useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { saveCurrentRestaurantBranchId } from 'src/utils/restaurant';

export const SwitchStorePanel = React.memo((props: { expanded: boolean }) => {
  const { t } = useTranslation();
  const { data: branches } = useBranches();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  return (
    <HeaderPanel aria-label="Header Panel" expanded={props.expanded}>
      <SideNavItems>
        <SideNavLink large renderIcon={Switcher}>
          <CarbonLink>{t('header.switchStoreBranch')}</CarbonLink>
        </SideNavLink>
        <SideNavLink
          large
          onClick={() => {
            saveCurrentRestaurantBranchId('All');
            return location.reload();
          }}
          renderIcon={Store}
          className={!currentRestaurantBranch?.id && 'cds--side-nav__link--current'}
        >
          {t('common.all')}
        </SideNavLink>
        {branches?.data?.map((branch) => (
          <SideNavLink
            key={branch.id}
            large
            onClick={() => {
              saveCurrentRestaurantBranchId(branch.id);
              return location.reload();
            }}
            renderIcon={Store}
            className={currentRestaurantBranch?.id === branch.id && 'cds--side-nav__link--current'}
          >
            {branch.name}
          </SideNavLink>
        ))}
      </SideNavItems>
    </HeaderPanel>
  );
});
