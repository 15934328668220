import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { Button } from '@carbon/react';
import { ShoppingBag, Delivery, NoodleBowl } from '@carbon/react/icons';
import { OrderTypeEnum } from '@restacity/shared/enums/order';
import { usePOSStore } from 'src/states/pos';

interface CurrentOrderAvailableTablesProps {}

const CurrentOrderAvailableTables = React.memo((props: CurrentOrderAvailableTablesProps) => {
  const { t } = useTranslation();
  const { currentOrderType, setOrderType } = usePOSStore(
    (state) => ({
      setOrderType: state.setOrderType,
      currentOrderType: state.currentOrderInfo.type,
    }),
    shallow
  );
  return (
    <div className="order-types">
      <h5>{t('pos:selectAnOrderType')}</h5>
      <div className="types">
        <div className="order-type">
          <Button
            onClick={() => setOrderType(OrderTypeEnum.DINE_IN)}
            renderIcon={() => <NoodleBowl size={28} />}
            kind={currentOrderType === OrderTypeEnum.DINE_IN ? 'primary' : 'tertiary'}
          />
          <h6>{t('orderTypes.dineIn')}</h6>
        </div>
        <div className="order-type">
          <Button
            onClick={() => setOrderType(OrderTypeEnum.TAKEAWAY)}
            renderIcon={() => <ShoppingBag size={28} />}
            kind={currentOrderType === OrderTypeEnum.TAKEAWAY ? 'primary' : 'tertiary'}
          />
          <h6>{t('orderTypes.takeaway')}</h6>
        </div>
        <div className="order-type">
          <Button
            onClick={() => setOrderType(OrderTypeEnum.DELIVERY)}
            renderIcon={() => <Delivery size={28} />}
            kind={currentOrderType === OrderTypeEnum.DELIVERY ? 'primary' : 'tertiary'}
          />
          <h6>{t('orderTypes.delivery')}</h6>
        </div>
      </div>
    </div>
  );
});

export default CurrentOrderAvailableTables;
