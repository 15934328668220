import React from 'react';
import { Modal } from '@carbon/react';

interface DeleteConfirmationProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  deleting: boolean;
  label: string;
  heading: string;
}

const DeleteConfirmation = React.memo((props: DeleteConfirmationProps) => {
  return (
    <Modal
      open={props.open}
      danger
      size={'sm'}
      modalHeading={props.heading}
      modalLabel={props.label}
      primaryButtonText={props.deleting ? 'Deleting...' : 'Delete'}
      secondaryButtonText="Cancel"
      primaryButtonDisabled={props.deleting}
      onRequestClose={props.onClose}
      onSecondarySubmit={props.onClose}
      onRequestSubmit={props.onDelete}
    />
  );
});

export default DeleteConfirmation;
