import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tag } from '@carbon/react';
import { Delivery, ShoppingBag } from '@carbon/react/icons';
import { OrderTypeEnum } from '@restacity/shared/enums/order';
import OrderDate from 'src/components/Order/OrderDate';
import OrderLine from 'src/components/Order/OrderLine';
import { useMenuItems } from 'src/hooks/useMenuItems';
import { useMarkAsReadyOrdersMutation } from 'src/hooks/useOrders';
import { useTables } from 'src/hooks/useRestaurantTables';
import { OrderEntity } from 'src/services/apis/types';
import { getOrderStatusText, getOrderTypeText } from 'src/utils/order';
import { NumberToK } from 'src/utils/price';

interface DeliveryOrderItemProps {
  order: OrderEntity;
}

function OrderDetailsNotes(props: { order: OrderEntity }) {
  const { t } = useTranslation();
  if (!props.order.notes) {
    return null;
  }
  return (
    <>
      <h5>{t('common.notes')}</h5>
      <div className="order-item">{props.order.notes}</div>
    </>
  );
}

const KitchenOrderItem = React.memo((props: DeliveryOrderItemProps) => {
  const { t } = useTranslation();
  const { data: tables } = useTables();
  const { data: menuItems } = useMenuItems();
  const markAsReadyOrdersMutation = useMarkAsReadyOrdersMutation();
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(Date.now()), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const totalItems = props.order.orderItems.map((item) => item.quantity).reduce((total, quantity) => total + quantity);
  const orderTable = tables?.data?.find((table) => table.id === props.order.tableId);
  const orderItems = props.order.orderItems.map((item) => ({
    ...item,
    orderItem: menuItems?.data.find((r) => r.id === item.menuItemId),
  }));
  const inProgressMinutes = dayjs(currentTime).diff(dayjs(new Date(props.order.createdAt)), 'minutes');
  const summaryClass = classNames({
    summary: true,
    'summary--red-flag': inProgressMinutes >= 5,
  });
  return (
    <div className={'item'}>
      <div className={summaryClass}>
        {props.order.type === OrderTypeEnum.DELIVERY && (
          <div className="table">
            <Delivery size={42} />
          </div>
        )}
        {props.order.type === OrderTypeEnum.TAKEAWAY && (
          <div className="table">
            <ShoppingBag size={42} />
          </div>
        )}
        {props.order.type === OrderTypeEnum.DINE_IN && <div className="table">{orderTable?.tableNumber}</div>}
        <div>
          <h3>{totalItems} Items</h3>
          <h5 className={'address'}>{props.order.line1}</h5>
          <ul className="list-horizontal">
            <li>{t(getOrderTypeText(props.order.type))}</li>
            <li>{t(getOrderStatusText(props.order.status))}</li>
            <li>
              <OrderDate date={props.order.createdAt} />
            </li>
          </ul>
        </div>
      </div>
      <div className="order-items">
        {orderItems.map((row, index) => (
          <OrderLine orderLine={row} key={row.orderItem?.id} index={index} />
        ))}
        <OrderDetailsNotes order={props.order} />
      </div>
      <Tag className="pos-recent-orders-item-status" type="red" title="Clear Filter">
        {NumberToK(props.order.grandTotal)}K
      </Tag>
      <div className="actions">
        <Button size={'xl'} onClick={() => markAsReadyOrdersMutation.mutate({ id: props.order.id })}>
          {t('actions.ready')}
        </Button>
      </div>
    </div>
  );
});

export default KitchenOrderItem;
