import { AxiosError } from 'axios';
import { Field, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  FormGroup,
  TextInput,
  Stack,
  FormItem,
  Button,
  Toggle,
  TextInputSkeleton,
  InlineNotification,
  InlineLoading,
} from '@carbon/react';
import DndUploader from 'src/components/Uploader/DNDUploader';
import { useCurrentRestaurant, useUpdateRestaurantMutation } from 'src/hooks/useRestaurants';
import { getImageUrl } from 'src/utils/cloudinary';

function FormLoading() {
  return (
    <>
      <TextInputSkeleton />
      <TextInputSkeleton />
      <TextInputSkeleton />
      <TextInputSkeleton />
    </>
  );
}

const Schema = z.object({
  name: z.string().max(60).trim(),
  slug: z.string().max(24).trim(),
  slogan: z.string().max(255).trim(),
  address: z.string().max(255).trim(),
  latitude: z.preprocess((a) => parseFloat(`${a}`), z.number()),
  longitude: z.preprocess((a) => parseFloat(`${a}`), z.number()),
});

function FormField(props: { label: string; name: string }) {
  const { name, label } = props;
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <TextInput
            id={name}
            labelText={label}
            invalid={!!(meta.touched && meta.error)}
            invalidText={meta.error}
            {...field}
          />
        );
      }}
    </Field>
  );
}

function FormFieldUpload(props: {
  value: any;
  title: string;
  aspectRatio: string;
  cropperAspectRatio: number;
  description: string;
  onChange: (data) => void;
}) {
  return (
    <FormItem>
      <p className="cds--file--label">{props.title}</p>
      <p className="cds--label-description">{props.description}</p>
      <DndUploader
        useCropper
        initialValue={{ fileId: props.value, previewUrl: getImageUrl(props.value)! }}
        previewAspectRatio={props.aspectRatio}
        cropperAspectRatio={props.cropperAspectRatio}
        onChange={props.onChange}
      />
    </FormItem>
  );
}

const RestaurantInformationSettings = React.memo(() => {
  const { t } = useTranslation();
  const currentRestaurant = useCurrentRestaurant();
  const updateRestaurantMutation = useUpdateRestaurantMutation();

  const postHandleSubmit = useCallback((values) => {
    updateRestaurantMutation.mutate({
      ...values,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updateRestaurantMutation.reset();
    }, 1500);
  }, [updateRestaurantMutation.isSuccess]);

  const error = updateRestaurantMutation?.error as AxiosError;
  let errorMessage = error && error?.response?.status !== 200 ? 'Something wrong!' : '';

  return (
    <div className={'store-settings-information-page'}>
      <FormGroup style={{ maxWidth: '400px' }} legendText={t('common.basicInformation')}>
        <Stack gap={6}>
          {!currentRestaurant && <FormLoading />}
          {currentRestaurant && (
            <Formik
              validationSchema={toFormikValidationSchema(Schema)}
              initialValues={{
                id: currentRestaurant.id,
                name: currentRestaurant.name,
                slug: currentRestaurant.slug,
                slogan: currentRestaurant.slogan,
                address: currentRestaurant.address,
                latitude: `${currentRestaurant.latitude}`,
                longitude: `${currentRestaurant.longitude}`,
                activated: currentRestaurant.activated,
                logo: currentRestaurant.logo,
                logoBlurhash: currentRestaurant.logoBlurhash,
                cover: currentRestaurant.cover,
                coverBlurhash: currentRestaurant.coverBlurhash,
                countryCode: currentRestaurant.countryCode,
              }}
              onSubmit={postHandleSubmit}
            >
              {({ handleSubmit, values, setFieldValue, isValid }) => (
                <>
                  <FormField name={'name'} label={t('common.name')} />
                  <FormField name={'slug'} label={t('common.slug')} />
                  <FormField name={'address'} label={t('common.address')} />
                  <FormField name={'slogan'} label={t('common.slogan')} />
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <FormField name={'latitude'} label={t('common.latitude')} />
                    <FormField name={'longitude'} label={t('common.longitude')} />
                  </div>
                  <Toggle
                    labelText={t('common.activation')}
                    labelA={t('common.off')}
                    labelB={t('common.on')}
                    onChange={(e, { checked }) => setFieldValue('activated', checked)}
                    defaultToggled={values.activated}
                    id="activated"
                  />
                  <FormFieldUpload
                    title={t('common.restaurantLogo')}
                    description={t('messages.photoRecommended', { size: 1, aspectRatio: '1x1' })}
                    cropperAspectRatio={1}
                    aspectRatio={'1x1'}
                    value={values.logo}
                    onChange={(data) => {
                      setFieldValue('logo', `${data.fileId}.${data.format}`);
                      setFieldValue('logoBlurhash', data.blurhash);
                    }}
                  />
                  <FormFieldUpload
                    title={t('common.restaurantCover')}
                    description={t('messages.photoRecommended', { size: 1, aspectRatio: '16x9' })}
                    cropperAspectRatio={16 / 9}
                    aspectRatio={'16x9'}
                    value={values.cover}
                    onChange={(data) => {
                      setFieldValue('cover', `${data.fileId}.${data.format}`);
                      setFieldValue('coverBlurhash', data.blurhash);
                    }}
                  />
                  {errorMessage && <InlineNotification subtitle={errorMessage} />}
                  {updateRestaurantMutation.isLoading || updateRestaurantMutation.isSuccess ? (
                    <InlineLoading
                      description={updateRestaurantMutation.isSuccess ? t('common.saved') : `${t('actions.saving')}...`}
                      status={updateRestaurantMutation.isSuccess ? 'finished' : 'active'}
                    />
                  ) : (
                    <Button disabled={!isValid} onClick={handleSubmit}>
                      {t('actions.save')}
                    </Button>
                  )}
                </>
              )}
            </Formik>
          )}
        </Stack>
      </FormGroup>
    </div>
  );
});

export default RestaurantInformationSettings;
