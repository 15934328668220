import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HeaderMenuItem, HeaderNavigation } from '@carbon/react';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';

export const MainHeaderNavigation = React.memo(() => {
  const { t } = useTranslation();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  return (
    <HeaderNavigation aria-label="Carbon Tutorial">
      <HeaderMenuItem id={'current-restaurant-branch'}>
        {t('common.branch')}: {currentRestaurantBranch?.name || t('common.all')}
      </HeaderMenuItem>
    </HeaderNavigation>
  );
});
