import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/hooks/useUtils';
import { deleteIssuesId, getIssues, postIssues, putIssuesId } from 'src/services/apis/services';
import { IssueCreateDto, IssueUpdateDto } from 'src/services/apis/types';
import { IssueTypeEnum, IssueStatusEnum } from 'src/utils/issue';
import { QUERIES } from 'src/utils/react-query';

export const useIssues = (employee, dish, operation) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{
    limit: number;
    type: IssueTypeEnum | null;
    status: IssueStatusEnum | null;
    search: string | null;
    startDate: Date | null;
    endDate: Date | null;
  }>({
    limit: 20,
    type: null,
    status: null,
    search: null,
    startDate: null,
    endDate: null,
  });
  const getDefaultFilters = useCallback(() => {
    let type: IssueTypeEnum | null = null;
    if (employee) {
      type = IssueTypeEnum.EMPLOYEE;
    }
    if (dish) {
      type = IssueTypeEnum.DISH;
    }
    if (operation) {
      type = IssueTypeEnum.OPERATION;
    }
    return {
      limit: 20,
      type,
      status: null,
      search: null,
      startDate: null,
      endDate: null,
    };
  }, [employee, dish, operation]);
  useEffect(() => {
    setFilters(getDefaultFilters());
  }, [employee, dish, operation]);
  const debounceSearch = useDebounce(filters.search, 700);
  const query = useQuery(
    [
      QUERIES.ISSUES,
      page,
      {
        limit: filters.limit,
        type: filters.type,
        status: filters.status,
        search: debounceSearch,
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    ],
    ({ queryKey: [_key, _page, _filters] }: { queryKey: any }) => {
      const convertedFilters: string[] = [];
      if (_filters.type) {
        convertedFilters.push(`type||$eq||${_filters.type}`);
      }
      if (_filters.status) {
        convertedFilters.push(`status||$eq||${_filters.status}`);
      }
      if (_filters.startDate && _filters.endDate) {
        convertedFilters.push(`createdAt||$gte||${dayjs(_filters.startDate).startOf('day')}`);
        convertedFilters.push(`createdAt||$lte||${dayjs(_filters.endDate).endOf('day')}`);
      }
      if (_filters.search) {
        convertedFilters.push(`title||$contL||${_filters.search}`);
      }
      return getIssues({
        page: _page as number,
        limit: _filters.limit,
        filter: convertedFilters,
      });
    },
    {
      cacheTime: 0,
      refetchOnMount: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return {
    query,
    page,
    filters,
    setPage,
    setFilters,
  };
};

export const useEmployeeMonthlyIssues = (employeeId: string | null) => {
  const [page] = useState(1);
  const [filters, setFilters] = useState<{
    limit: number;
    startDate: Date | null | string;
    endDate: Date | null | string;
    employeeId: string | null;
  }>({
    limit: 100,
    startDate: dayjs().startOf('month').toDate().toISOString(),
    endDate: dayjs().endOf('month').endOf('day').toDate().toISOString(),
    employeeId: null,
  });
  const getDefaultFilters = useCallback(() => {
    return {
      limit: 100,
      startDate: dayjs().startOf('month').toDate().toISOString(),
      endDate: dayjs().endOf('month').endOf('day').toDate().toISOString(),
      employeeId: employeeId,
    };
  }, [employeeId]);
  useEffect(() => {
    setFilters(getDefaultFilters());
  }, [employeeId]);
  const query = useQuery(
    [
      QUERIES.EMPLOYEE_MONTHLY_ISSUES,
      page,
      {
        employeeId: employeeId,
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    ],
    ({ queryKey: [_key, _page, _filters] }: { queryKey: any }) => {
      const convertedFilters: string[] = [];
      convertedFilters.push(`employeeId||$eq||${_filters.employeeId}`);
      convertedFilters.push(`createdAt||$gte||${_filters.startDate}`);
      convertedFilters.push(`createdAt||$lte||${_filters.endDate}`);
      return getIssues({
        page: _page as number,
        limit: _filters.limit,
        filter: convertedFilters,
      });
    },
    {
      cacheTime: 0,
      refetchOnMount: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!employeeId,
    }
  );
  return {
    query,
  };
};

export const useCreateIssuesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: IssueCreateDto) => postIssues(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.ISSUES]);
    },
  });
};

export const useUpdateIssuesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: IssueUpdateDto) => putIssuesId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.ISSUES]);
    },
  });
};

export const useDeleteIssuesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((employeeId: string) => deleteIssuesId(employeeId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.ISSUES]);
    },
  });
};
