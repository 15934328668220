import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Modal,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import TransactionCategoryForm from 'src/components/Accounting/TransactionCategoryForm';
import {
  TransactionCategoryStatusTag,
  TransactionCategoryTypeTag,
} from 'src/components/Accounting/TransactionCategoryTags';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import {
  useTransactionCategories,
  useCreateTransactionCategoriesMutation,
  useUpdateTransactionCategoriesMutation,
  useDeleteTransactionCategoriesMutation,
} from 'src/hooks/useTransactionCategories';
import { TransactionCategoryEntity } from 'src/services/apis/types';
import { getMutationErrorMessage } from 'src/utils/error';

const TransactionCategories = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const { isLoading, data } = useTransactionCategories();
  const createTransactionCategoriesMutation = useCreateTransactionCategoriesMutation();
  const updateTransactionCategoriesMutation = useUpdateTransactionCategoriesMutation();
  const deleteTransactionCategoriesMutation = useDeleteTransactionCategoriesMutation();
  const currentRestaurant = useCurrentRestaurant();
  const [openAddTransactionCategory, setOpenAddTransactionCategory] = useState(false);
  const [editingTransactionCategory, setEditingTransactionCategory] = useState<
    null | undefined | TransactionCategoryEntity
  >(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [deletingTransactionCategory, setDeletingTransactionCategory] = useState<
    null | undefined | TransactionCategoryEntity
  >(null);

  const handleCloseAddOrEditTransactionCategory = useCallback(() => {
    setOpenAddTransactionCategory(false);
    setEditingTransactionCategory(null);
    setEditingTransactionCategory(null);
    formRef.current?.resetForm();
  }, []);

  const handleAddTransactionCategory = useCallback(() => {
    setOpenAddTransactionCategory(true);
  }, []);

  const handleCreate = useCallback(
    async (values) => {
      await createTransactionCategoriesMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
      });
      handleCloseAddOrEditTransactionCategory();
    },
    [currentRestaurant?.id]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateTransactionCategoriesMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
      });
      handleCloseAddOrEditTransactionCategory();
    },
    [currentRestaurant?.id]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingTransactionCategory(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteTransactionCategoriesMutation.mutateAsync(deletingTransactionCategory?.id!);
    setDeletingTransactionCategory(null);
  }, [deletingTransactionCategory]);

  const headers = [
    { key: 'name', header: t('common.name') },
    { key: 'description', header: t('common.description') },
  ];

  const extraHeaders = [
    { key: 'type', header: t('common.type') },
    { key: 'status', header: t('common.status') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return <DataTableSkeleton columnCount={headers.length + 3} rowCount={6} headers={[...headers, ...extraHeaders]} />;
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (searchQuery) {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.description.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return true;
      })
      .map((item) => ({
        ...item,
      })) || [];

  const submitting = createTransactionCategoriesMutation.isLoading || updateTransactionCategoriesMutation.isLoading;
  const createError = getMutationErrorMessage(createTransactionCategoriesMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateTransactionCategoriesMutation.error as AxiosError);

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer
            title={t('titles.transactionCategoriesPage', { restaurantName: currentRestaurant?.name })}
            description={`${data?.total} ${t('common.transactionCategories')}`}
          >
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch placeholder={t('common.search')} onChange={(e) => setSearchQuery(e.target.value)} />
                <Button renderIcon={Add} onClick={handleAddTransactionCategory}>
                  {t('actions.createNewTransactionCategory')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      <TransactionCategoryTypeTag type={filteredRows[index]?.type} />
                    </TableCell>
                    <TableCell>
                      <TransactionCategoryStatusTag status={filteredRows[index]?.status} />
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingTransactionCategory(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingTransactionCategory(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddTransactionCategory || !!editingTransactionCategory}
        size={'sm'}
        modalHeading={
          openAddTransactionCategory
            ? t('actions.createNewTransactionCategory')
            : t('actions.updateTransactionCategory')
        }
        modalLabel={t('titles.transactionCategoriesPage', { restaurantName: currentRestaurant?.name })}
        primaryButtonText={
          submitting
            ? `${t('common.loading')}...`
            : editingTransactionCategory
            ? t('actions.update')
            : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditTransactionCategory}
        onRequestClose={handleCloseAddOrEditTransactionCategory}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <TransactionCategoryForm
          errorMessage={createError || updateError}
          initialValues={editingTransactionCategory}
          ref={formRef}
          onSubmit={editingTransactionCategory ? handleUpdate : handleCreate}
        />
      </Modal>
      <DeleteConfirmation
        open={!!deletingTransactionCategory}
        heading={t('messages.areYouSureToDeleteThisTransactionCategory')}
        label={t('titles.transactionCategoriesPage', { restaurantName: currentRestaurant?.name })}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteTransactionCategoriesMutation.isLoading}
      />
    </>
  );
});

export default TransactionCategories;
