import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
// import ReloadPrompt from 'src/ReloadPrompt';
import AuthenticationLayout from 'src/components/Layout/AuthenticationLayout';
import MainLayout from 'src/components/Layout/MainLayout';
import Accounting from 'src/pages/Accounting';
import AllEmployees from 'src/pages/AllEmployees';
import AllIssues from 'src/pages/AllIssues';
import AllOrders from 'src/pages/AllOrders';
import Dashboard from 'src/pages/Dashboard';
import DashboardAccounting from 'src/pages/Dashboard/Accounting';
import DashboardSelling from 'src/pages/Dashboard/Selling';
import Delivery from 'src/pages/Delivery';
import EmployeeDashboard from 'src/pages/EmployeeDashboard';
import EmployeeDayOff from 'src/pages/EmployeeDayOff';
import EmployeeRules from 'src/pages/EmployeeRules';
import Employees from 'src/pages/Employees';
import Issues from 'src/pages/Issues';
import Kitchen from 'src/pages/Kitchen';
import MainMenuSettings from 'src/pages/MainMenuSettings';
import MenuItemVariantsSettings from 'src/pages/MenuItemVariantsSettings';
import MenuItemsSettings from 'src/pages/MenuItemsSettings';
import MenuSettings from 'src/pages/MenuSettings';
import Orders from 'src/pages/Orders';
import POS from 'src/pages/POS';
import RestaurantBranchesSettings from 'src/pages/RestaurantBranchesSettings';
import RestaurantInformationSettings from 'src/pages/RestaurantInformationSettings';
import RestaurantSettings from 'src/pages/RestaurantSettings';
import RestaurantTablesSettings from 'src/pages/RestaurantTablesSettings';
import SignIn from 'src/pages/SignIn';
import TransactionCategories from 'src/pages/TransactionCategories';
import Transactions from 'src/pages/Transactions';
import { getAuthToken } from 'src/utils/auth';

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  if (!getAuthToken()) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }
  return children;
}

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="" element={<Navigate to="/dashboard/selling" />} />
          <Route path="*" element={<Navigate to="/dashboard/selling" />} />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          >
            <Route path="selling" element={<DashboardSelling />} />
            <Route path="accounting" element={<DashboardAccounting />} />
          </Route>
          <Route
            path="restaurant-settings"
            element={
              <RequireAuth>
                <RestaurantSettings />
              </RequireAuth>
            }
          >
            <Route path="" element={<RestaurantInformationSettings />} />
            <Route path="branches" element={<RestaurantBranchesSettings />} />
            <Route path="tables" element={<RestaurantTablesSettings />} />
          </Route>
          <Route
            path="delivery"
            element={
              <RequireAuth>
                <Delivery />
              </RequireAuth>
            }
          />
          <Route
            path="kitchen"
            element={
              <RequireAuth>
                <Kitchen />
              </RequireAuth>
            }
          />
          <Route
            path="menu-settings"
            element={
              <RequireAuth>
                <MenuSettings />
              </RequireAuth>
            }
          >
            <Route path="" element={<MainMenuSettings />} />
            <Route path="items" element={<MenuItemsSettings />} />
            <Route path="item-variants" element={<MenuItemVariantsSettings />} />
          </Route>
          <Route
            path="orders"
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
          >
            <Route path="today" element={<AllOrders today={true} />} />
            <Route path="all" element={<AllOrders today={false} />} />
          </Route>
          <Route
            path="employees"
            element={
              <RequireAuth>
                <Employees />
              </RequireAuth>
            }
          >
            <Route path="all" element={<AllEmployees />} />
            <Route path="rules" element={<EmployeeRules />} />
            <Route path="day-off" element={<EmployeeDayOff />} />
            <Route path="issues" element={<AllIssues employee={true} dish={false} operation={false} />} />
          </Route>
          <Route
            path="employees/dashboard"
            element={
              <RequireAuth>
                <EmployeeDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="accounting"
            element={
              <RequireAuth>
                <Accounting />
              </RequireAuth>
            }
          >
            <Route
              path="transactions"
              element={<Transactions expenses={false} incomes={false} investments={false} />}
            />
            <Route path="expenses" element={<Transactions expenses={true} incomes={false} investments={false} />} />
            <Route path="incomes" element={<Transactions expenses={false} incomes={true} investments={false} />} />
            <Route path="investments" element={<Transactions expenses={false} incomes={false} investments={true} />} />
            <Route path="settings" element={<TransactionCategories />} />
          </Route>
          <Route
            path="issues"
            element={
              <RequireAuth>
                <Issues />
              </RequireAuth>
            }
          >
            <Route path="all" element={<AllIssues employee={false} dish={false} operation={false} />} />
            <Route path="employee" element={<AllIssues employee={true} dish={false} operation={false} />} />
            <Route path="dish" element={<AllIssues employee={false} dish={true} operation={false} />} />
            <Route path="operation" element={<AllIssues employee={false} dish={false} operation={true} />} />
          </Route>
          <Route
            path="pos"
            element={
              <RequireAuth>
                <POS />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/auth" element={<AuthenticationLayout />}>
          <Route path="login" element={<SignIn />} />
        </Route>
      </Routes>
      {/*<ReloadPrompt />*/}
    </>
  );
};

export default App;
