import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { Breadcrumb, BreadcrumbItem, Loading } from '@carbon/react';
import Row from 'src/components/Base/Row';
import EmployeeBonusDetails from 'src/components/Employee/EmployeeBonusDetails';
import EmployeeDashboardDayOff from 'src/components/Employee/EmployeeDashboardDayOff';
import EmployeeDashboardIssues from 'src/components/Employee/EmployeeDashboardIssues';
import EmployeeIncomes from 'src/components/Employee/EmployeeIncomes';
import EmployeeSelector from 'src/components/Form/EmployeeSelector';
import { useAuthUser } from 'src/hooks/useAuthUser';
import { useEmployeeMonthlyDayOff } from 'src/hooks/useEmployeeDayOff';
import { useEmployeeIncomesDashboard } from 'src/hooks/useEmployees';
import { useEmployeeMonthlyIssues } from 'src/hooks/useIssues';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useEmployeeStore } from 'src/states/employee';

const EmployeeDashboard = () => {
  const { t } = useTranslation('employee');
  const authUser = useAuthUser();
  const { employeeId, setEmployeeId } = useEmployeeStore(
    (state) => ({
      employeeId: state.employeeId,
      setEmployeeId: state.setEmployeeId,
    }),
    shallow
  );
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const {
    query: { isLoading: employeeIncomesLoading, data: employeeIncomes, isRefetching },
  } = useEmployeeIncomesDashboard();
  const {
    query: {
      isLoading: employeeMonthlyIssuesLoading,
      data: employeeMonthlyIssues,
      isRefetching: isRefetchingEmployeeMonthlyIssues,
    },
  } = useEmployeeMonthlyIssues(employeeId);
  const {
    query: {
      isLoading: employeeMonthlyDayOffLoading,
      data: employeeMonthlyDayOff,
      isRefetching: isRefetchingEmployeeMonthlyDayOff,
    },
  } = useEmployeeMonthlyDayOff(employeeId);
  const getTotalRevenueBonus = () => {
    let bonusPercent = 0.08;
    const totalSales = employeeIncomes?.sales
      ?.map((item) => item.totalSales)
      ?.reduce((total, totalSales) => total + totalSales);
    if (totalSales > currentRestaurantBranch?.salesGoalPerMonth!) {
      bonusPercent = 0.1;
    }
    return (totalSales / employeeIncomes?.sales?.length) * bonusPercent;
  };
  const totalRevenueBonus = getTotalRevenueBonus();
  console.log(totalRevenueBonus);
  const loading = false;
  return (
    <div className={'employee-dashboard'}>
      <div className={'employee-dashboard__main-content'}>
        <Breadcrumb noTrailingSlash aria-label="Page navigation">
          <BreadcrumbItem>
            <Link to="/employees/dashboard">{t('title')} / This Month (10/2024)</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <Row gap={0} spaceBetween>
          <h1 className="employee-dashboard__heading">
            {t('welcomeBack')}, {authUser?.firstName}
          </h1>
          <EmployeeSelector
            value={employeeId}
            branchId={currentRestaurantBranch?.id}
            onChange={(value) => {
              setEmployeeId(value);
            }}
            hideTitle
            placeholderText={'Select an employee'}
          />
        </Row>
        {loading && <Loading small />}
        <EmployeeIncomes employeeIncomes={employeeIncomes} />
        <EmployeeBonusDetails />
        <EmployeeDashboardIssues employeeMonthlyIssues={employeeMonthlyIssues?.data} />
        <EmployeeDashboardDayOff employeeMonthlyDayOff={employeeMonthlyDayOff?.data} />
      </div>
    </div>
  );
};

export default EmployeeDashboard;
