import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ComboBox,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Loading,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from '@carbon/react';
import { Add, Erase, Renew } from '@carbon/react/icons';
import TransactionForm from 'src/components/Accounting/TransactionForm';
import { TransactionStatusTag, TransactionTypeTag } from 'src/components/Accounting/TransactionTags';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import FormattedDate from 'src/components/Base/FormattedDate';
import { useAuthPermissions } from 'src/hooks/useAuthPermissions';
import { useBranches, useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { useTransactionCategories } from 'src/hooks/useTransactionCategories';
import {
  useCreateTransactionsMutation,
  useDeleteTransactionsMutation,
  useTransactions,
  useUpdateTransactionsMutation,
} from 'src/hooks/useTransactions';
import { TransactionEntity } from 'src/services/apis/types';
import { TransactionCategoryTypeEnum, transactionStatusOptions, TransactionTypeEnum } from 'src/utils/accounting';
import { getMutationErrorMessage } from 'src/utils/error';
import { KToNumber, NumberToK } from 'src/utils/price';

const Transactions = React.memo(
  ({ expenses, incomes, investments }: { expenses?: boolean; incomes?: boolean; investments?: boolean }) => {
    const { t } = useTranslation();
    const formRef = useRef<any>();
    const {
      query: { isLoading, data: transactions, isRefetching, refetch },
      page,
      filters,
      setPage,
      setFilters,
    } = useTransactions(expenses, incomes, investments);
    const { isManager } = useAuthPermissions();
    const { data: branches } = useBranches();
    const { data: transactionCategories } = useTransactionCategories();
    const currentRestaurant = useCurrentRestaurant();
    const currentRestaurantBranch = useCurrentRestaurantBranch();
    const createTransactionsMutation = useCreateTransactionsMutation();
    const updateTransactionsMutation = useUpdateTransactionsMutation();
    const deleteTransactionsMutation = useDeleteTransactionsMutation();

    const [openAddTransaction, setOpenAddTransaction] = useState(false);
    const [editingTransaction, setEditingTransaction] = useState<null | undefined | TransactionEntity>(null);
    const [deletingTransaction, setDeletingTransaction] = useState<null | undefined | TransactionEntity>(null);

    const handleCloseAddOrEditTransaction = useCallback(() => {
      setOpenAddTransaction(false);
      setEditingTransaction(null);
    }, []);

    const handleAddTransaction = useCallback(() => {
      formRef.current?.resetForm();
      setOpenAddTransaction(true);
    }, []);

    const handleCreate = useCallback(
      async (values) => {
        await createTransactionsMutation.mutateAsync({
          ...values,
          restaurantId: currentRestaurant?.id,
          tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
          total: KToNumber(parseInt(values.total, 10)),
        });
        handleCloseAddOrEditTransaction();
      },
      [currentRestaurant?.id]
    );

    const handleUpdate = useCallback(
      async (values) => {
        await updateTransactionsMutation.mutateAsync({
          ...values,
          tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
          total: KToNumber(parseInt(values.total, 10)),
          restaurantId: currentRestaurant?.id,
        });
        handleCloseAddOrEditTransaction();
      },
      [currentRestaurant?.id]
    );

    const handleAbortDelete = useCallback(() => {
      setDeletingTransaction(null);
    }, []);

    const handleDelete = useCallback(async () => {
      await deleteTransactionsMutation.mutateAsync(deletingTransaction?.id!);
      setDeletingTransaction(null);
    }, [deletingTransaction]);

    const headers = [{ key: 'title', header: t('common.title') }];

    const extraHeaders = [
      { key: 'category', header: t('common.category') },
      { key: 'total', header: t('common.total') },
      { key: 'date', header: t('common.date') },
      { key: 'type', header: t('common.transactionType') },
      { key: 'status', header: t('common.status') },
      { key: 'branch', header: t('common.branch') },
      { key: 'actions', header: t('common.actions') },
    ];

    if (isLoading) {
      return (
        <DataTableSkeleton columnCount={headers.length + 7} rowCount={6} headers={[...headers, ...extraHeaders]} />
      );
    }

    const getTitle = () => {
      if (incomes) {
        return t('common.income');
      }
      if (expenses) {
        return t('common.expense');
      }
      if (investments) {
        return t('common.investment');
      }
      return t('common.transaction');
    };

    const getTableTitle = () => {
      if (currentRestaurantBranch?.name) {
        return t('titles.accountingPage', {
          restaurantName: currentRestaurant?.name,
          branchName: currentRestaurantBranch?.name,
          title,
        });
      }
      return t('titles.accountingPageWithoutBranch', {
        restaurantName: currentRestaurant?.name,
        title,
      });
    };

    const filteredRows = transactions?.data || [];
    const submitting = createTransactionsMutation.isLoading || updateTransactionsMutation.isLoading;
    const createError = getMutationErrorMessage(createTransactionsMutation.error as AxiosError);
    const updateError = getMutationErrorMessage(updateTransactionsMutation.error as AxiosError);
    const title = getTitle();
    const filteredTransactionCategories =
      transactionCategories?.data.filter((item) => {
        if (incomes) {
          return item.type === TransactionCategoryTypeEnum.INCOME;
        }
        if (expenses) {
          return item.type === TransactionCategoryTypeEnum.EXPENSE;
        }
        if (investments) {
          return item.type === TransactionCategoryTypeEnum.INVESTMENT;
        }
        return true;
      }) || [];

    return (
      <>
        <DataTable rows={filteredRows} headers={headers}>
          {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
            <TableContainer title={getTableTitle()} description={`${transactions?.total} ${title.toLowerCase()}`}>
              <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                {isRefetching && <Loading className={'some-class'} small />}
                <TableToolbarContent>
                  <TableToolbarSearch onChange={(e) => setFilters({ ...filters, search: e.target.value })} />
                  <ComboBox
                    items={filteredTransactionCategories.map((item) => ({ id: item.id, text: item.name }))}
                    placeholder={`${t('common.filterByCategory')}...`}
                    size={'lg'}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        categoryId: e.selectedItem?.id,
                      });
                    }}
                    className={'cds--combo-box--no-border-bottom'}
                    itemToString={(item) => (item ? item.text : '')}
                  />
                  <ComboBox
                    items={transactionStatusOptions}
                    placeholder={`${t('common.filterByStatus')}...`}
                    size={'lg'}
                    onChange={(e) => setFilters({ ...filters, status: e.selectedItem?.id })}
                    className={'cds--combo-box--no-border-bottom'}
                    itemToString={(item) => (item ? t(item.text) : '')}
                  />
                  <DatePicker
                    dateFormat={'d/m/Y'}
                    datePickerType="range"
                    disabled={isManager}
                    value={
                      filters.startDate && filters.endDate
                        ? [new Date(filters.startDate), new Date(filters.endDate)]
                        : []
                    }
                    onChange={(dates) => {
                      if (dates.length > 1) {
                        setFilters({
                          ...filters,
                          startDate: dates[0]?.toISOString(),
                          endDate: dates[1]?.toISOString(),
                        });
                      }
                    }}
                  >
                    <DatePickerInput disabled={isManager} placeholder={t('dateRanges.startDate')} />
                    <DatePickerInput disabled={isManager} placeholder={t('dateRanges.endDate')} />
                  </DatePicker>
                  {filters.startDate && filters.endDate && (
                    <Button
                      renderIcon={Erase}
                      onClick={() => setFilters({ ...filters, startDate: null, endDate: null })}
                      hasIconOnly
                      disabled={isManager}
                      kind="ghost"
                      iconDescription={t('common.clearDates')}
                    />
                  )}
                  <Button
                    renderIcon={Renew}
                    onClick={refetch}
                    hasIconOnly
                    kind="secondary"
                    iconDescription={t('common.refresh')}
                  />
                  <Button renderIcon={Add} onClick={handleAddTransaction}>
                    {t('actions.create')} {title}
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {[...headers, ...extraHeaders].map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                      <TableCell>
                        {filteredRows[index] &&
                          transactionCategories?.data.find((item) => item.id === filteredRows[index]?.categoryId)?.name}
                      </TableCell>
                      <TableCell>{NumberToK(filteredRows[index]?.total)}K</TableCell>
                      <TableCell>
                        <FormattedDate date={filteredRows[index]?.date} format={'DD/MM/YYYY'} />
                      </TableCell>
                      <TableCell>
                        <TransactionTypeTag type={filteredRows[index]?.type} />
                      </TableCell>
                      <TableCell>
                        <TransactionStatusTag status={filteredRows[index]?.status} />
                      </TableCell>
                      <TableCell>
                        {filteredRows[index] &&
                          branches?.data.find((item) => item.id === filteredRows[index]?.restaurantBranchId)?.name}
                      </TableCell>
                      <TableCell>
                        <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                          <OverflowMenuItem
                            itemText={t('actions.edit')}
                            requireTitle
                            onClick={() => setEditingTransaction(filteredRows[index])}
                          />
                          <OverflowMenuItem
                            hasDivider
                            isDelete
                            itemText={t('actions.delete')}
                            onClick={() => setDeletingTransaction(filteredRows[index])}
                            requireTitle
                          />
                        </OverflowMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {rows.length === 0 && <EmptyState />}
            </TableContainer>
          )}
        </DataTable>
        <Pagination
          backwardText={t('common.previousPage')}
          forwardText={t('common.nextPage')}
          itemsPerPageText={`${t('common.itemsPerPage')}:`}
          onChange={({ pageSize, page }) => {
            setPage(page);
            setFilters({ ...filters, limit: pageSize });
          }}
          itemText={(min, max) => `${min}–${max} ${t('common.items')}`}
          pageRangeText={(_current, total) =>
            `${t('common.of')} ${total} ${total === 1 ? t('common.page') : t('common.pages')}`
          }
          page={page}
          pageSize={filters.limit}
          pageSizes={[20, 50, 100]}
          size="lg"
          totalItems={transactions?.total}
        />
        <Modal
          open={openAddTransaction || !!editingTransaction}
          size={'sm'}
          modalHeading={openAddTransaction ? `${t('actions.createNew')} ${title}` : `${t('actions.update')} ${title}`}
          modalLabel={`${currentRestaurant?.name}'s ${title}`}
          primaryButtonText={
            submitting ? `${t('common.loading')}...` : editingTransaction ? t('actions.update') : t('actions.create')
          }
          secondaryButtonText={t('actions.cancel')}
          onSecondarySubmit={handleCloseAddOrEditTransaction}
          onRequestClose={handleCloseAddOrEditTransaction}
          primaryButtonDisabled={submitting}
          onRequestSubmit={() => formRef.current?.submitForm()}
        >
          <div style={{ minHeight: 500 }}>
            {(openAddTransaction || !!editingTransaction) && (
              <TransactionForm
                errorMessage={createError || updateError}
                initialValues={editingTransaction}
                ref={formRef}
                type={
                  incomes
                    ? TransactionTypeEnum.INCOME
                    : expenses
                    ? TransactionTypeEnum.EXPENSE
                    : investments
                    ? TransactionTypeEnum.INVESTMENT
                    : TransactionTypeEnum.INCOME
                }
                onSubmit={editingTransaction ? handleUpdate : handleCreate}
              />
            )}
          </div>
        </Modal>
        <DeleteConfirmation
          open={!!deletingTransaction}
          heading={t('messages.areYouSureToDeleteThis', { title })}
          label={`${currentRestaurant?.name}'s ${title}`}
          onClose={handleAbortDelete}
          onDelete={handleDelete}
          deleting={deleteTransactionsMutation.isLoading}
        />
      </>
    );
  }
);

export default Transactions;
