import { AxiosError } from 'axios';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Link,
  Modal,
  Toggle,
  ComboBox,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import ImagesViewer from 'src/components/Base/ImagesViewer';
import MenuItemForm from 'src/components/Menu/MenuItemForm';
import { CropperModal } from 'src/components/Uploader/CropperModal';
import { useMenu } from 'src/hooks/useMenu';
import {
  useMenuItems,
  useCreateMenuItemsMutation,
  useUpdateMenuItemsMutation,
  useDeleteMenuItemsMutation,
} from 'src/hooks/useMenuItems';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { MenuItemEntity } from 'src/services/apis/types';
import { getImageUrl } from 'src/utils/cloudinary';
import { getMutationErrorMessage } from 'src/utils/error';
import { KToNumber, NumberToK } from 'src/utils/price';

const MenuItemsSettings = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const currentRestaurant = useCurrentRestaurant();
  const { data: menu } = useMenu();
  const { isLoading, data } = useMenuItems();
  const createMenuItemMutation = useCreateMenuItemsMutation();
  const updateMenuItemMutation = useUpdateMenuItemsMutation();
  const deleteMenuItemMutation = useDeleteMenuItemsMutation();
  const [filters, setFilters] = useState({
    menuId: null,
  });
  const [openAddMenuItem, setOpenAddMenuItem] = useState(false);
  const [editingMenuItem, setEditingMenuItem] = useState<null | undefined | MenuItemEntity>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [croppingFile, setCroppingFile] = useState<{ file: File; previewUrl: string } | null>(null);
  const [deletingMenuItem, setDeletingMenuItem] = useState<null | undefined | MenuItemEntity>(null);
  const [viewingMenuItemPhotos, setViewingMenuItemPhotos] = useState<MenuItemEntity[]>([]);

  const handleCloseAddOrEditMenuItem = useCallback(() => {
    setOpenAddMenuItem(false);
    setEditingMenuItem(null);
    formRef.current?.resetForm();
  }, []);

  const handleAddMenuItem = useCallback(() => {
    formRef.current?.resetForm();
    setOpenAddMenuItem(true);
  }, []);

  const handleCreateOrUpdate = useCallback(
    async (values) => {
      const payload = {
        ...values,
        restaurantId: currentRestaurant?.id,
        tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
        photos: values.photo ? [values.photo] : [],
        price: KToNumber(parseInt(values.price, 10)),
        order: parseInt(values.order, 10),
        blurhashPhotos: values.photoBlurhash ? [values.photoBlurhash] : [],
      };
      if (editingMenuItem) {
        await updateMenuItemMutation.mutateAsync(payload);
      } else {
        await createMenuItemMutation.mutateAsync(payload);
      }
      handleCloseAddOrEditMenuItem();
    },
    [currentRestaurant?.id, editingMenuItem]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingMenuItem(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteMenuItemMutation.mutateAsync(deletingMenuItem?.id!);
    setDeletingMenuItem(null);
  }, [deletingMenuItem]);

  const handleCropped = useCallback(({ fileId, format, blurhash }) => {
    setCroppingFile(null);
    formRef.current?.setFieldValue('photo', `${fileId}.${format}`);
    formRef.current?.setFieldValue('photoBlurhash', blurhash);
  }, []);

  const menuSelectionItems = useMemo(() => {
    return menu?.data?.map((item) => ({ id: item.id, text: item.name }));
  }, [menu?.data]);

  const headers = [
    {
      key: 'name',
      header: t('common.name'),
    },
    {
      key: 'sku',
      header: t('common.sku'),
    },
  ];

  const extraHeaders = [
    { key: 'menu', header: t('common.menu') },
    { key: 'price', header: t('common.price') },
    { key: 'photo', header: t('common.photo') },
    { key: 'sort', header: t('common.sort') },
    { key: 'available', header: t('common.available') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return (
      <DataTableSkeleton
        columnCount={headers.length + extraHeaders.length}
        rowCount={6}
        headers={[...headers, ...extraHeaders]}
      />
    );
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (filters?.menuId) {
          return item.menuId === filters?.menuId;
        }
        return true;
      })
      .filter((item) => {
        if (searchQuery) {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.sku.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return true;
      }) || [];

  const submitting = createMenuItemMutation.isLoading || updateMenuItemMutation.isLoading;
  const createError = getMutationErrorMessage(createMenuItemMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateMenuItemMutation.error as AxiosError);

  const tableTitle = t('titles.menuItemsPage', { restaurantName: currentRestaurant?.name });

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer title={tableTitle} description={`${data?.total} ${t('common.items')}`}>
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={(e) => setSearchQuery(e.target.value)} />
                <ComboBox
                  items={menuSelectionItems}
                  placeholder={`${t('common.filterByMenu')}...`}
                  size={'lg'}
                  onChange={(e) => setFilters((current) => ({ ...current, menuId: e.selectedItem?.id }))}
                  className={'cds--combo-box--no-border-bottom'}
                  itemToString={(item) => (item ? item.text : '')}
                />
                <Button renderIcon={Add} onClick={handleAddMenuItem}>
                  {t('actions.create')} {t('common.menuItem')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      {menuSelectionItems?.find((item) => item.id === filteredRows[index]?.menuId)?.text}
                    </TableCell>
                    <TableCell>{NumberToK(filteredRows[index]?.price)}K</TableCell>
                    <TableCell>
                      {filteredRows[index]?.photos?.length
                        ? filteredRows[index]?.photos && (
                            <Link onClick={() => setViewingMenuItemPhotos([filteredRows[index]])}>
                              {filteredRows[index]?.photos[0]}
                            </Link>
                          )
                        : t('messages.noPhotos')}
                    </TableCell>
                    <TableCell>{filteredRows[index]?.order}</TableCell>
                    <TableCell>
                      {filteredRows[index] && (
                        <Toggle
                          labelA={t('common.no')}
                          labelB={t('common.yes')}
                          toggled={filteredRows[index].available}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingMenuItem(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingMenuItem(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddMenuItem || !!editingMenuItem}
        size={'sm'}
        modalHeading={
          openAddMenuItem
            ? `${t('actions.createNew')} ${t('common.menuItem')}`
            : `${t('actions.update')} ${t('common.menuItem')}`
        }
        modalLabel={tableTitle}
        primaryButtonText={
          submitting ? `${t('common.loading')}...` : editingMenuItem ? t('actions.update') : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditMenuItem}
        onRequestClose={handleCloseAddOrEditMenuItem}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <MenuItemForm
          errorMessage={createError || updateError}
          initialValues={editingMenuItem}
          ref={formRef}
          onSubmit={handleCreateOrUpdate}
          onSelectedFile={setCroppingFile}
        />
      </Modal>
      <CropperModal
        croppingFile={croppingFile}
        onClose={() => setCroppingFile(null)}
        cropperAspectRatio={1}
        onChange={handleCropped}
      />
      <DeleteConfirmation
        open={!!deletingMenuItem}
        heading={t('messages.areYouSureToDeleteThisMenuItem')}
        label={tableTitle}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteMenuItemMutation.isLoading}
      />
      <ImagesViewer
        images={viewingMenuItemPhotos.map((item) => ({
          src: getImageUrl(item.photos[0])!,
          caption: `${currentRestaurant?.name} - ${item.name} ${t('common.photos')}`,
        }))}
        onClose={() => setViewingMenuItemPhotos([])}
      />
    </>
  );
});

export default MenuItemsSettings;
