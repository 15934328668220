import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { Button, Tag } from '@carbon/react';
import { Checkmark, Edit, Money, Delivery, ShoppingBag } from '@carbon/react/icons';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import { useMenuItems } from 'src/hooks/useMenuItems';
import { useUpdateOrdersMutation } from 'src/hooks/useOrders';
import { useTables } from 'src/hooks/useRestaurantTables';
import { OrderEntity } from 'src/services/apis/types';
import { usePOSStore } from 'src/states/pos';
import { getOrderStatusText, getOrderTypeText } from 'src/utils/order';
import { NumberToK } from 'src/utils/price';

interface RecentOrderItemProps {
  order: OrderEntity;
  paidOrderTokens?: string[];
}

const RecentOrderItem = React.memo((props: RecentOrderItemProps) => {
  const { t } = useTranslation();
  const { data: tables } = useTables();
  const { data: menuItems } = useMenuItems();
  const { expandingRecentOrders, toggleExpandingRecentOrders, setEditOrder, setViewingOrder } = usePOSStore(
    (state) => ({
      setEditOrder: state.setEditOrder,
      setViewingOrder: state.setViewingOrder,
      expandingRecentOrders: state.expandingRecentOrders,
      toggleExpandingRecentOrders: state.toggleExpandingRecentOrders,
    }),
    shallow
  );
  const updateOrdersMutation = useUpdateOrdersMutation();
  const totalItems = props.order.orderItems.map((item) => item.quantity).reduce((total, quantity) => total + quantity);
  const orderTable = tables?.data?.find((table) => table.id === props.order.tableId);

  const handleChangeOrderStatus = useCallback((status: OrderStatusEnum) => {
    updateOrdersMutation.mutate({ id: props.order.id, status });
  }, []);

  const paid = props.paidOrderTokens?.includes(props.order.token);

  const paidButtonClass = classNames({
    'paid-button': true,
    'paid-button--confirmed': paid,
  });

  const orderItemClass = classNames({
    'pos-recent-orders-item': true,
    'pos-recent-orders-item--paid': paid,
  });

  return (
    <div className={orderItemClass}>
      {props.order.type === OrderTypeEnum.DELIVERY && (
        <div className="table" onClick={() => setViewingOrder(props.order)}>
          <Delivery size={28} />
        </div>
      )}
      {props.order.type === OrderTypeEnum.TAKEAWAY && (
        <div className="table" onClick={() => setViewingOrder(props.order)}>
          <ShoppingBag size={28} />
        </div>
      )}
      {props.order.type === OrderTypeEnum.DINE_IN && (
        <div className="table" onClick={() => setViewingOrder(props.order)}>
          {orderTable?.tableNumber}
        </div>
      )}
      <div>
        <div onClick={() => setViewingOrder(props.order)}>
          <h3>
            {totalItems} {t('common.dishes')}
          </h3>
          <ul className="list-horizontal">
            <li>{t(getOrderTypeText(props.order.type))}</li>
            <li>{t(getOrderStatusText(props.order.status))}</li>
          </ul>
        </div>
        <div className="actions">
          <Button
            size={'sm'}
            onClick={() => handleChangeOrderStatus(OrderStatusEnum.READY)}
            renderIcon={Checkmark}
            kind={props.order.status === OrderStatusEnum.READY ? 'primary' : 'tertiary'}
          />
          /
          <Button
            size={'sm'}
            onClick={() => {
              if (expandingRecentOrders) {
                toggleExpandingRecentOrders();
              }
              setEditOrder(props.order, menuItems?.data!);
            }}
            renderIcon={Edit}
            kind={'tertiary'}
          />
        </div>
      </div>
      <Tag className="pos-recent-orders-item-status" type="red" title="Clear Filter">
        {NumberToK(props.order.grandTotal)}K
      </Tag>
      <Button
        onClick={() => handleChangeOrderStatus(OrderStatusEnum.PAID)}
        size={'sm'}
        className={paidButtonClass}
        renderIcon={Money}
        kind={'primary'}
      />
    </div>
  );
});

export default RecentOrderItem;
