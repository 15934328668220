import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getRestaurantTables,
  postRestaurantTables,
  putRestaurantTablesId,
  deleteRestaurantTablesId,
} from 'src/services/apis/services';
import { RestaurantTableCreateDto, RestaurantTableUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useTables = () => {
  return useQuery([QUERIES.TABLES], () => getRestaurantTables());
};

export const useCreateRestaurantTableMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: RestaurantTableCreateDto) => postRestaurantTables(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TABLES]);
    },
  });
};

export const useUpdateRestaurantTableMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: RestaurantTableUpdateDto) => putRestaurantTablesId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TABLES]);
    },
  });
};

export const useDeleteRestaurantTableMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((branchId: string) => deleteRestaurantTablesId(branchId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TABLES]);
    },
  });
};
