import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getMenuItems, deleteMenuItemsId, putMenuItemsId, postMenuItems } from 'src/services/apis/services';
import { MenuItemUpdateDto, MenuItemCreateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useMenuItems = () => {
  return useQuery([QUERIES.MENU_ITEMS], () => getMenuItems());
};

export const useCreateMenuItemsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: MenuItemCreateDto) => postMenuItems(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU_ITEMS]);
    },
  });
};

export const useUpdateMenuItemsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: MenuItemUpdateDto) => putMenuItemsId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU_ITEMS]);
    },
  });
};

export const useDeleteMenuItemsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((menuId: string) => deleteMenuItemsId(menuId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU_ITEMS]);
    },
  });
};
