import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { Button, SkeletonPlaceholder } from '@carbon/react';
import { OrderTypeEnum } from '@restacity/shared/enums/order';
import { useRecentOrders } from 'src/hooks/useOrders';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useTables } from 'src/hooks/useRestaurantTables';
import { usePOSStore } from 'src/states/pos';

interface CurrentOrderAvailableTablesProps {}

const CurrentOrderAvailableTablesLoading = React.memo(() => (
  <div className="table-numbers">
    <h5>Available table number</h5>
    <div className="available-numbers">
      <SkeletonPlaceholder style={{ width: '100%' }} />
    </div>
  </div>
));

const CurrentOrderAvailableTables = React.memo((_: CurrentOrderAvailableTablesProps) => {
  const { t } = useTranslation('pos');
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const { isLoading: isTablesLoading, data: tables } = useTables();
  const { isLoading: isRecentOrdersLoading, data: recentOrders } = useRecentOrders();
  const { currentOrderTableId, currentOrderType, setOrderTableId } = usePOSStore(
    (state) => ({
      setOrderTableId: state.setOrderTableId,
      currentOrderTableId: state.currentOrderInfo.tableId,
      currentOrderType: state.currentOrderInfo.type,
    }),
    shallow
  );
  if (isRecentOrdersLoading || isTablesLoading) {
    return <CurrentOrderAvailableTablesLoading />;
  }
  if (![OrderTypeEnum.DINE_IN].includes(currentOrderType!)) {
    return null;
  }
  const activeTableIds = recentOrders?.data?.map((order) => order.tableId);
  const availableTables = tables?.data
    .filter((item) => item.restaurantBranchId === currentRestaurantBranch?.id)
    .filter((table) => !activeTableIds?.includes(table.id));
  return (
    <>
      <hr />
      <div className="table-numbers">
        <h5>{t('availableTableNumbers')}</h5>
        <div className="available-numbers">
          {availableTables?.length ? (
            availableTables?.map((table) => (
              <Button
                key={table.id}
                size={'sm'}
                kind={currentOrderTableId === table.id ? 'primary' : 'tertiary'}
                onClick={() => setOrderTableId(table.id)}
              >
                {table.tableNumber}
              </Button>
            ))
          ) : (
            <div>
              <p>No available tables!</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default CurrentOrderAvailableTables;
