import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { Link, Popover, PopoverContent } from '@carbon/react';

interface StatCardProps {
  icon: React.ReactElement;
  title: string;
  value: number;
  unit?: string;
  onClick?: () => void;
}

const StatCard = React.memo((props: StatCardProps) => {
  const { t } = useTranslation('employee');
  const [open, setOpen] = React.useState(false);
  return (
    <div className="employee-dashboard__stat_widget">
      <h4>{props.title}</h4>
      <h3>
        <CountUp end={props.value} />
        {props.unit}
      </h3>
      <Popover open={open} autoAlign highContrast onRequestClose={() => setOpen(!open)}>
        <Link
          onClick={() => {
            setOpen(!open);
          }}
        >
          {t('viewDetails')}
        </Link>
        <PopoverContent className="p-3">
          <h2>Available storage</h2>
          <p>This server has 150 GB of block storage remaining.</p>
        </PopoverContent>
      </Popover>
      <div className="icon">{props.icon}</div>
    </div>
  );
});

export default StatCard;
