import classNames from 'classnames';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { AspectRatio, Button } from '@carbon/react';
import { Add, Subtract } from '@carbon/react/icons';
import { MenuItemEntity } from 'src/services/apis/types';
import { usePOSStore } from 'src/states/pos';
import { NumberToK } from 'src/utils/price';

interface MenuItemsProps {
  item: MenuItemEntity & { menuItemVariantId?: string };
}

const MenuItem = React.memo((props: MenuItemsProps) => {
  const { item } = props;
  const { currentOrderItem, addOrderItem, minusOrderItem } = usePOSStore(
    (state) => ({
      currentOrderItem: state.currentOrderItems?.find((r) => r.orderItem.id === item.id),
      addOrderItem: state.addOrderItem,
      minusOrderItem: state.minusOrderItem,
    }),
    shallow
  );

  const currentQuantity = currentOrderItem ? currentOrderItem.quantity : 0;

  const containerClass = classNames({
    'pos-menu-items-col': true,
    'pos-menu-items-col--selected': currentQuantity > 0,
  });

  return (
    <div className={containerClass} id={item.sku}>
      <AspectRatio ratio="4x3">
        <h3>{item.name}</h3>
        <h5>{NumberToK(item.price)}K</h5>
        <div className="actions">
          <Button
            iconDescription={'Minus'}
            kind="tertiary"
            size={'sm'}
            onClick={() => minusOrderItem(item, 1)}
            renderIcon={() => <Subtract size={32} />}
          />
          <h3>{currentQuantity}</h3>
          <Button
            kind="tertiary"
            size={'sm'}
            iconDescription={'Add'}
            onClick={() => addOrderItem(item, 1)}
            renderIcon={() => <Add size={32} />}
          />
        </div>
      </AspectRatio>
    </div>
  );
});

export default MenuItem;
