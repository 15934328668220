import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import TabsWithTablesLayout from 'src/components/Layout/TabsWithTablesLayout';

interface IssuesPageProps {}

const IssuesPage = React.memo((_: IssuesPageProps) => {
  const { t } = useTranslation();
  const routes = [
    {
      label: t('common.allIssues'),
      url: '/issues/all',
    },
    {
      label: t('common.employeeIssues'),
      url: '/issues/employee',
    },
    {
      label: t('common.dishIssues'),
      url: '/issues/dish',
    },
    {
      label: t('common.operationIssues'),
      url: '/issues/operation',
    },
  ];
  return (
    <TabsWithTablesLayout routes={routes} title={t('common.issues')}>
      <Outlet />
    </TabsWithTablesLayout>
  );
});

export default IssuesPage;
