import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { Loading } from '@carbon/react';
import { Account, DocumentImport, DocumentExport, Recycle } from '@carbon/react/icons';
import { DashboardDateRange } from '@restacity/shared/enums/dashboard';
import DailyTransactions from 'src/components/Dashboard/DailyTransactions';
import StatCard from 'src/components/Dashboard/StatCard';
import TransactionsCategories from 'src/components/Dashboard/TransactionsCategories';
import { useDashboardAccountingReports } from 'src/hooks/useDashboardReports';
import { useDashboardStore } from 'src/states/dashboard';
import { TransactionTypeEnum } from 'src/utils/accounting';
import { NumberToK } from 'src/utils/price';

interface AccountingProps {}

const Accounting = React.memo((_: AccountingProps) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const {
    query: { isLoading, data: report, isRefetching },
  } = useDashboardAccountingReports();
  const { startDate, endDate, dateRange } = useDashboardStore(
    (state) => ({
      dateRange: state.dateRange,
      startDate: state.startDate,
      endDate: state.endDate,
    }),
    shallow
  );

  const handleViewDetails = useCallback(
    (transactionType: TransactionTypeEnum | null) => {
      const filteredParams = {
        transactionType: `${transactionType}` || 'all',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
      navigate({
        pathname: '/accounting',
        search: `?${createSearchParams(filteredParams)}`,
      });
    },
    [startDate, endDate]
  );

  const totalIncomes =
    report?.accountingReports?.transactionsReportsByType?.find(
      (item) => item.transactionType === TransactionTypeEnum.INCOME
    )?.transactionTotal || 0;

  const totalExpenses =
    report?.accountingReports?.transactionsReportsByType?.find(
      (item) => item.transactionType === TransactionTypeEnum.EXPENSE
    )?.transactionTotal || 0;

  const totalInvestment =
    report?.accountingReports?.transactionsReportsByType?.find(
      (item) => item.transactionType === TransactionTypeEnum.INVESTMENT
    )?.transactionTotal || 0;

  const transactionsByDateRange = report?.accountingReports?.transactionsByDateRange || [];
  const accountingReportsByCategories = report?.accountingReportsByCategories || [];

  const balance = totalIncomes - totalExpenses - totalInvestment;

  return (
    <div className={'accounting'}>
      {(isLoading || isRefetching) && <Loading small />}
      <div className="stats">
        <StatCard
          title={t('balance')}
          onClick={() => handleViewDetails(null)}
          value={NumberToK(balance)}
          unit={'K'}
          icon={<Account size={36} />}
        />
        <StatCard
          title={t('totalIncomes')}
          onClick={() => handleViewDetails(TransactionTypeEnum.INCOME)}
          value={NumberToK(totalIncomes || 0)}
          unit={'K'}
          icon={<DocumentImport size={36} />}
        />
        <StatCard
          title={t('totalExpenses')}
          onClick={() => handleViewDetails(TransactionTypeEnum.EXPENSE)}
          value={NumberToK(totalExpenses || 0)}
          unit={'K'}
          icon={<DocumentExport size={36} />}
        />
        <StatCard
          title={t('totalInvestments')}
          onClick={() => handleViewDetails(TransactionTypeEnum.INVESTMENT)}
          value={NumberToK(totalInvestment || 0)}
          icon={<Recycle size={36} />}
        />
      </div>
      <div className="stats">
        <DailyTransactions
          dateFormat={dateRange === DashboardDateRange.YEARLY ? 'MM/YYYY' : 'DD/MM'}
          transactionsByDateRange={transactionsByDateRange}
          title={
            dateRange === DashboardDateRange.YEARLY
              ? t('transactionsPerMonth', { unit: 'K' })
              : t('transactionsPerDay', { unit: 'K' })
          }
        />
      </div>
      <div className="stats">
        <TransactionsCategories
          title={t('expenseCategories')}
          transactionsCategories={accountingReportsByCategories?.filter(
            (item) => item.transactionType === TransactionTypeEnum.EXPENSE
          )}
        />
      </div>
      <div className="stats">
        <TransactionsCategories
          title={t('incomeCategories')}
          transactionsCategories={accountingReportsByCategories?.filter(
            (item) => item.transactionType === TransactionTypeEnum.INCOME
          )}
        />
      </div>
      <div className="stats">
        <TransactionsCategories
          title={t('investmentCategories')}
          transactionsCategories={accountingReportsByCategories?.filter(
            (item) => item.transactionType === TransactionTypeEnum.INVESTMENT
          )}
        />
      </div>
    </div>
  );
});

export default Accounting;
