import classNames from 'classnames';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { SkeletonPlaceholder } from '@carbon/react';
import EmptyState from 'src/components/Base/EmptyState';
import MenuItem from 'src/components/POS/MenuItem';
import { useMenu } from 'src/hooks/useMenu';
import { useMenuItemVariants } from 'src/hooks/useMenuItemVariants';
import { useMenuItems } from 'src/hooks/useMenuItems';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { usePOSStore } from 'src/states/pos';

interface MenuItemsProps {}

const MenuItems = React.memo((_: MenuItemsProps) => {
  const { isLoading: isMenuLoading, data: menu } = useMenu();
  const { isLoading, data: menuItems } = useMenuItems();
  const { isLoading: isMenuItemVariantsLoading, data: menuItemVariants } = useMenuItemVariants();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const { selectedMenuId, expandingRecentOrders } = usePOSStore(
    (state) => ({
      selectedMenuId: state.selectedMenuId,
      expandingRecentOrders: state.expandingRecentOrders,
    }),
    shallow
  );
  if (isLoading || isMenuLoading || isMenuItemVariantsLoading) {
    return (
      <>
        <hr />
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </>
    );
  }
  const filteredMenuItems = {
    ...menuItems,
    data: menuItems?.data
      .filter((item) => item.menuId === (selectedMenuId || menu?.data[0].id))
      .map((item) => {
        const matchItemVariant = menuItemVariants?.data?.find(
          (r) => r.menuItemId === item.id && r.specificForBranchIds.includes(currentRestaurantBranch?.id!)
        );
        return {
          ...item,
          ...(matchItemVariant
            ? { name: matchItemVariant.name, price: matchItemVariant.price, sku: matchItemVariant.sku }
            : {}),
          menuItemVariantId: matchItemVariant?.id,
        };
      }),
  };
  if (filteredMenuItems.data?.length === 0) {
    return <EmptyState title={'Empty'} desc={'Please try to select another menu.'} />;
  }
  if (!currentRestaurantBranch) {
    return <EmptyState title={'No branch selected!'} desc={'Please try to select a branch to continue.'} />;
  }
  if (expandingRecentOrders) {
    return null;
  }
  const containerClass = classNames({
    'pos-menu-items': true,
    'pos-menu-items--collapsed': expandingRecentOrders,
  });
  return (
    <>
      <hr />
      <div className={containerClass}>
        <div className={'pos-menu-items-grid'}>
          {filteredMenuItems?.data?.map((item) => (
            <MenuItem item={item} key={item.id} />
          ))}
        </div>
      </div>
    </>
  );
});

export default MenuItems;
