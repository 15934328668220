import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { SimpleBarChart } from '@carbon/charts-react';
import { useTransactionCategories } from 'src/hooks/useTransactionCategories';
import { useAppStore } from 'src/states/app';
import { NumberToK } from 'src/utils/price';

interface SalesByMenuProps {
  title: string;
  transactionsCategories: any[];
}

const TransactionsCategories = React.memo((props: SalesByMenuProps) => {
  const { t } = useTranslation('dashboard');
  const { theme } = useAppStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow
  );
  const { isLoading, data: transactionCategories } = useTransactionCategories();
  const transformedTransactionCategories =
    transactionCategories?.data?.reduce((previousObject, currentObject) => {
      return Object.assign(previousObject, {
        [currentObject.id]: currentObject.name,
      });
    }, {}) || {};

  return (
    <div className="dashboard__stat_widget">
      <SimpleBarChart
        data={
          isLoading || Object.keys(transformedTransactionCategories).length === 0
            ? []
            : props.transactionsCategories?.map((item) => {
                return {
                  group: transformedTransactionCategories[item.transactionCategoryId],
                  value: NumberToK(item.transactionTotal),
                };
              })
        }
        options={{
          title: props.title,
          axes: {
            left: {
              title: t('total'),
              mapsTo: 'value',
              ticks: {
                formatter: (value) => `${value}K`,
              },
            },
            bottom: {
              title: t('category'),
              mapsTo: 'group',
              scaleType: 'labels' as any,
            },
          },
          height: '400px',
          theme: theme === 'dark' ? 'g100' : ('g10' as any),
        }}
      />
    </div>
  );
});

export default TransactionsCategories;
