import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Modal,
  Toggle,
  ComboBox,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import TableForm from 'src/components/Table/TableForm';
import { useBranches } from 'src/hooks/useRestaurantBranches';
import {
  useTables,
  useCreateRestaurantTableMutation,
  useUpdateRestaurantTableMutation,
  useDeleteRestaurantTableMutation,
} from 'src/hooks/useRestaurantTables';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { RestaurantTableEntity } from 'src/services/apis/types';
import { getMutationErrorMessage } from 'src/utils/error';

const RestaurantTablesSettings = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const { isLoading, data } = useTables();
  const { data: branches } = useBranches();
  const createRestaurantTableMutation = useCreateRestaurantTableMutation();
  const updateRestaurantTableMutation = useUpdateRestaurantTableMutation();
  const deleteRestaurantTableMutation = useDeleteRestaurantTableMutation();
  const currentRestaurant = useCurrentRestaurant();
  const [openAddTable, setOpenAddTable] = useState(false);
  const [editingTable, setEditingTable] = useState<null | undefined | RestaurantTableEntity>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [deletingTable, setDeletingTable] = useState<null | undefined | RestaurantTableEntity>(null);
  const [filterByBranchId, setFilterByBranchId] = useState<string | null>(null);

  const handleCloseAddOrEditTable = useCallback(() => {
    setOpenAddTable(false);
    setEditingTable(null);
    formRef.current?.resetForm();
  }, []);

  const handleAddTable = useCallback(() => {
    formRef.current?.resetForm();
    setOpenAddTable(true);
  }, []);

  const handleCreate = useCallback(
    async (values) => {
      await createRestaurantTableMutation.mutateAsync({
        ...values,
        tableNumber: parseInt(values.tableNumber, 10),
        capacity: parseInt(values.capacity, 10),
        restaurantId: currentRestaurant?.id,
      });
      handleCloseAddOrEditTable();
    },
    [currentRestaurant?.id]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateRestaurantTableMutation.mutateAsync({
        ...values,
        tableNumber: parseInt(values.tableNumber, 10),
        capacity: parseInt(values.capacity, 10),
        restaurantId: currentRestaurant?.id,
      });
      handleCloseAddOrEditTable();
    },
    [currentRestaurant?.id]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingTable(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteRestaurantTableMutation.mutateAsync(deletingTable?.id!);
    setDeletingTable(null);
  }, [deletingTable]);

  const headers = [
    {
      key: 'tableNumber',
      header: t('common.tableNumber'),
    },
    {
      key: 'capacity',
      header: t('common.capacity'),
    },
  ];

  const extraHeaders = [
    { key: 'branch', header: t('common.branch') },
    { key: 'available', header: t('common.available') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return (
      <DataTableSkeleton
        columnCount={headers.length + extraHeaders.length}
        rowCount={6}
        headers={[...headers, ...extraHeaders]}
      />
    );
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (filterByBranchId) {
          return item.restaurantBranchId === filterByBranchId;
        }
        return true;
      })
      .filter((item) => {
        if (searchQuery) {
          return item.tableNumber.toString().includes(searchQuery.toLowerCase());
        }
        return true;
      })
      .map((item) => ({
        ...item,
      })) || [];

  const submitting = createRestaurantTableMutation.isLoading || updateRestaurantTableMutation.isLoading;
  const createError = getMutationErrorMessage(createRestaurantTableMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateRestaurantTableMutation.error as AxiosError);

  const tableTitle = t('titles.tableSettingsPage', { restaurantName: currentRestaurant?.name });

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer title={tableTitle} description={`${data?.total} ${t('common.tables')}`}>
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={(e) => setSearchQuery(e.target.value)} />
                <ComboBox
                  items={branches?.data.map((item) => ({ id: item.id, text: item.name }))}
                  placeholder={t('common.filterByBranch')}
                  size={'lg'}
                  onChange={(e) => setFilterByBranchId(e.selectedItem?.id)}
                  className={'cds--combo-box--no-border-bottom'}
                  itemToString={(item) => (item ? item.text : '')}
                />

                <Button renderIcon={Add} onClick={handleAddTable}>
                  {t('actions.create')} {t('common.table')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      {filteredRows[index] &&
                        branches?.data.find((item) => item.id === filteredRows[index].restaurantBranchId)?.name}
                    </TableCell>
                    <TableCell>
                      {filteredRows[index] && (
                        <Toggle
                          labelA={t('common.no')}
                          labelB={t('common.yes')}
                          toggled={filteredRows[index].available}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingTable(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingTable(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddTable || !!editingTable}
        size={'sm'}
        modalHeading={
          openAddTable
            ? `${t('actions.createNew')} ${t('common.table')}`
            : `${t('actions.update')} ${t('common.table')}`
        }
        modalLabel={tableTitle}
        primaryButtonText={
          submitting ? `${t('common.loading')}...` : editingTable ? t('actions.update') : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditTable}
        onRequestClose={handleCloseAddOrEditTable}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <TableForm
          errorMessage={createError || updateError}
          initialValues={editingTable}
          ref={formRef}
          onSubmit={editingTable ? handleUpdate : handleCreate}
        />
      </Modal>
      <DeleteConfirmation
        open={!!deletingTable}
        heading={t('messages.areYouSureToDeleteThisTable')}
        label={tableTitle}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteRestaurantTableMutation.isLoading}
      />
    </>
  );
});

export default RestaurantTablesSettings;
