import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ContainedList, ExpandableSearch, ContainedListItem, Tag, Link, Tooltip, Modal } from '@carbon/react';
import EmptyState from 'src/components/Base/EmptyState';
import Row from 'src/components/Base/Row';
import Spacing from 'src/components/Base/Spacing';
import { useEmployeeRules } from 'src/hooks/useEmployeeRules';
import { IssueEntity } from 'src/services/apis/types';
import { NumberToK } from 'src/utils/price';

interface EmployeeDashboardIssuesProps {
  employeeMonthlyIssues: IssueEntity[] | undefined;
}

const EmployeeDashboardIssues = (props: EmployeeDashboardIssuesProps) => {
  const { data: employeeRules } = useEmployeeRules();
  const [searchTerm, setSearchTerm] = useState('');
  const [viewRules, setViewRules] = useState(false);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredIssues = props.employeeMonthlyIssues?.filter((item) =>
    searchTerm?.trim() ? item.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 : true
  );

  return (
    <div className="issues">
      <Spacing space={1} />
      <Row gap={0} spaceBetween alignCenter>
        <h5 className={'section__heading'}>Your Issues ({filteredIssues?.length || 0})</h5>
        <Link onClick={() => setViewRules(true)}>View Employee Rules</Link>
      </Row>
      <ContainedList
        label="Issues in this month"
        kind="on-page"
        size={'lg'}
        action={
          <>
            <ExpandableSearch
              placeholder="Filterable search"
              value={searchTerm}
              onChange={handleChange}
              closeButtonLabelText="Clear search input"
              size="lg"
            />
          </>
        }
      >
        {(!filteredIssues || filteredIssues?.length === 0) && <EmptyState title={'No Issues Found!'} />}
        {filteredIssues?.map((issue) => (
          <ContainedListItem key={issue.id}>
            <Row gap={0.5} spaceBetween={true} alignCenter={true}>
              <h6>{issue.title}</h6>
              <Row gap={0} style={{ minWidth: '12rem', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <Tooltip align="bottom" label={issue.description}>
                  <Tag type={'blue'} size={'md'}>
                    Details
                  </Tag>
                </Tooltip>
                <Tag size={'md'} title="Clear filter" type={'red'}>
                  Fine: {NumberToK(issue.fine)}k
                </Tag>
                <Tag size={'md'} title="Clear filter">
                  {dayjs(issue.createdAt).format('DD/MM/YYYY HH:mm')}
                </Tag>
              </Row>
            </Row>
          </ContainedListItem>
        ))}
      </ContainedList>
      <Modal
        open={viewRules}
        onRequestClose={() => setViewRules(false)}
        passiveModal
        modalLabel={'General Rules'}
        modalHeading="Employee Rules"
      >
        {employeeRules?.data?.map((rule) => (
          <ContainedListItem key={rule.id}>
            <Row gap={0.5} spaceBetween={true} alignCenter={true}>
              <h6>
                {rule.title} {rule.title}
              </h6>
              <Row gap={0} style={{ minWidth: '12rem', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <Tooltip align="bottom" label={rule.description}>
                  <Tag type={'blue'} size={'md'}>
                    Details
                  </Tag>
                </Tooltip>
                <Tag size={'md'} title="Clear filter" type={'red'}>
                  Fine: {NumberToK(rule.fine)}k
                </Tag>
              </Row>
            </Row>
          </ContainedListItem>
        ))}
      </Modal>
    </div>
  );
};

export default EmployeeDashboardIssues;
