import React from 'react';
import { Money, Logout, SubtractAlt, Gift, Portfolio } from '@carbon/react/icons';
import StatCard from 'src/components/Employee/StatCard';
import { NumberToK } from 'src/utils/price';

interface EmployeeIncomesProps {
  employeeIncomes: any;
}

const EmployeeIncomes = React.memo((props: EmployeeIncomesProps) => {
  const baseSalary = props.employeeIncomes?.employee?.salary || 0;
  const extraBonus = props.employeeIncomes?.employee?.extraBonus || 0;
  return (
    <div className={'incomes'}>
      <h5 className={'section__heading'}>Your Incomes</h5>
      <div className="stats">
        <StatCard
          title={'Total incomes'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<Money size={36} />}
        />
        <StatCard
          title={'Base Salary'}
          onClick={() => null}
          value={NumberToK(baseSalary)}
          unit={'K'}
          icon={<Portfolio size={36} />}
        />
        <StatCard
          title={'Total Bonus'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<Gift size={36} />}
        />
        <StatCard
          title={'Total Fines'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<SubtractAlt size={36} />}
        />
        <StatCard
          title={'Total Day Off'}
          onClick={() => null}
          value={NumberToK(8000000)}
          unit={'K'}
          icon={<Logout size={36} />}
        />
      </div>
    </div>
  );
});

export default EmployeeIncomes;
