import MDEditor from '@uiw/react-md-editor';
import { Formik } from 'formik';
import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { ComboBox, FormGroup, InlineNotification, Stack, Toggle, FormLabel } from '@carbon/react';
import { FormFieldUpload } from 'src/components/Form/FormFieldUpload';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import { useMenu } from 'src/hooks/useMenu';
import { MenuItemEntity } from 'src/services/apis/types';
import { NumberToK } from 'src/utils/price';

interface MenuItemFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: MenuItemEntity | null | undefined;
  onSelectedFile: ({ file, previewUrl }) => void;
}

const Schema = z.object({
  name: z.string().max(60).trim(),
  sku: z.string().max(32).trim(),
  price: z.preprocess((a) => parseFloat(`${a}`), z.number()),
  order: z.preprocess((a) => parseFloat(`${a}`), z.number()),
});

const MenuItemForm = (props: MenuItemFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();
  const { data: menu } = useMenu();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            name: '',
            sku: '',
            menuId: null,
            price: 0,
            order: 0,
            details: '',
            photo: '',
            photoBlurhash: '',
          },
        }),
    };
  });

  const menuSelectionItems = useMemo(() => {
    return menu?.data.map((item) => ({ id: item.id, text: item.name }));
  }, [menu?.data]);

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          id: props.initialValues?.id,
          name: props.initialValues?.name,
          menuId: props.initialValues?.menuId || menuSelectionItems?.find(() => true)?.id,
          sku: props.initialValues?.sku,
          price: props.initialValues ? NumberToK(props.initialValues?.price) : 0,
          order: props.initialValues?.order || 1,
          details: props.initialValues?.details,
          tags: props.initialValues ? props.initialValues.tags?.join(',') : '',
          available: props.initialValues ? props.initialValues.available : true,
          photo: props.initialValues?.photos[0],
          photoBlurhash: props.initialValues?.blurhashPhotos[0],
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <ComboBox
                items={menuSelectionItems}
                placeholder={t('common.filter')}
                selectedItem={
                  props.initialValues ? menuSelectionItems?.find((item) => item.id === values.menuId) : null
                }
                onChange={(e) => setFieldValue('menuId', e.selectedItem?.id)}
                itemToString={(item) => (item ? item.text : '')}
                titleText={t('common.selectAMenu')}
              />
              <FormikTextInput name={'name'} label={t('common.name')} />
              <FormikTextInput name={'sku'} label={t('common.sku')} />
              <FormikTextInput name={'price'} isNumber max={500} label={`${t('common.price')} (K)`} />
              <FormikTextInput name={'order'} isNumber max={100} label={t('common.order')} />
              <div data-color-mode="light">
                <FormLabel>{t('common.details')}</FormLabel>
                <MDEditor
                  preview="edit"
                  height={200}
                  value={values.details}
                  onChange={(value) => setFieldValue('details', value)}
                />
              </div>
              <FormikTextInput name={'tags'} label={`${t('common.tags')} (${t('common.separateByAComma')})`} />
              <FormFieldUpload
                title={t('common.photo')}
                description={t('messages.photoRecommended', { size: 1, aspectRatio: '1x1' })}
                cropperAspectRatio={1}
                aspectRatio={'1x1'}
                onSelectedFile={props.onSelectedFile}
                value={values.photo}
                onChange={(data) => {
                  setFieldValue('photo', `${data.fileId}.${data.format}`);
                  setFieldValue('photoBlurhash', data.blurhash);
                }}
              />
              <Toggle
                labelText={t('common.available')}
                labelA={t('common.off')}
                labelB={t('common.on')}
                onToggle={(checked) => setFieldValue('available', checked)}
                toggled={values.available}
                id="available"
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(MenuItemForm);
