const CURRENT_RESTAURANT_ID = 'RESTACITY:CURRENT_RESTAURANT_ID';
const CURRENT_RESTAURANT_BRANCH_ID = 'RESTACITY:CURRENT_RESTAURANT_BRANCH_ID';

export const getCurrentRestaurantId = () => {
  return localStorage.getItem(CURRENT_RESTAURANT_ID);
};

export const saveCurrentRestaurantId = (restaurantId) => {
  return localStorage.setItem(CURRENT_RESTAURANT_ID, restaurantId);
};

export const getCurrentRestaurantBranchId = () => {
  return localStorage.getItem(CURRENT_RESTAURANT_BRANCH_ID);
};

export const saveCurrentRestaurantBranchId = (restaurantBranchId) => {
  return localStorage.setItem(CURRENT_RESTAURANT_BRANCH_ID, restaurantBranchId);
};

export const clearCurrentRestaurantId = () => {
  localStorage.removeItem(CURRENT_RESTAURANT_ID);
  localStorage.removeItem(CURRENT_RESTAURANT_BRANCH_ID);
};
