import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { locale } from 'src/utils/settings';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: locale || 'vi',
    debug: false,
    ns: ['translation', 'pos', 'dashboard', 'authentication'],
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => r);

export default i18n;
