import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@carbon/react';
import { TransactionCategoryStatusEnum, TransactionCategoryTypeEnum } from 'src/utils/accounting';
import {
  getTransactionCategoryStatusText,
  getTransactionCategoryStatusColor,
  getTransactionCategoryTypeColor,
  getTransactionCategoryTypeText,
} from 'src/utils/accounting';

interface TransactionCategoryTypeTagProps {
  type: TransactionCategoryTypeEnum;
}

interface TransactionCategoryTagProps {
  status: TransactionCategoryStatusEnum;
}

const TransactionCategoryTypeTag = React.memo((props: TransactionCategoryTypeTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getTransactionCategoryTypeColor(props.type)} size="sm">
      {t(getTransactionCategoryTypeText(props.type))}
    </Tag>
  );
});

const TransactionCategoryStatusTag = React.memo((props: TransactionCategoryTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getTransactionCategoryStatusColor(props.status)} size="sm">
      {t(getTransactionCategoryStatusText(props.status))}
    </Tag>
  );
});

export { TransactionCategoryTypeTag, TransactionCategoryStatusTag };
