import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { Link, Modal, SkeletonPlaceholder } from '@carbon/react';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import EmptyState from 'src/components/Base/EmptyState';
import OrderDetails from 'src/components/Order/OrderDetails';
import RecentOrderItem from 'src/components/POS/RecentOrderItem';
import { usePaidOrderTokens, useRecentOrders, useUpdateOrdersMutation } from 'src/hooks/useOrders';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { OrderEntity } from 'src/services/apis/types';
import { usePOSStore } from 'src/states/pos';
import { getOrderTypeText, getOrderStatusText } from 'src/utils/order';

interface RecentOrdersProps {}

const RecentOrdersLoading = React.memo(() => {
  return (
    <div className={'pos-recent-orders-grid'}>
      <div className={'pos-recent-orders-item'}>
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </div>
      <div className={'pos-recent-orders-item'}>
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </div>
      <div className={'pos-recent-orders-item'}>
        <SkeletonPlaceholder style={{ width: '100%' }} />
      </div>
    </div>
  );
});

function RecentOrderTypeAction(props: { type: OrderTypeEnum | null }) {
  const { t } = useTranslation();
  const { recentOrdersFilters, setRecentOrdersFilters } = usePOSStore(
    (state) => ({
      recentOrdersFilters: state.recentOrdersFilters,
      setRecentOrdersFilters: state.setRecentOrdersFilters,
    }),
    shallow
  );
  return (
    <Link
      className={recentOrdersFilters.type === props.type && 'active'}
      onClick={() => setRecentOrdersFilters({ ...recentOrdersFilters, type: props.type })}
    >
      {props.type ? t(getOrderTypeText(props.type)) : t('common.all')}
    </Link>
  );
}

function RecentOrderStatusAction(props: { status: OrderStatusEnum | null }) {
  const { t } = useTranslation();
  const { recentOrdersFilters, setRecentOrdersFilters } = usePOSStore(
    (state) => ({
      recentOrdersFilters: state.recentOrdersFilters,
      setRecentOrdersFilters: state.setRecentOrdersFilters,
    }),
    shallow
  );
  return (
    <Link
      className={recentOrdersFilters.status === props.status && 'active'}
      onClick={() => setRecentOrdersFilters({ ...recentOrdersFilters, status: props.status })}
    >
      {props.status ? t(getOrderStatusText(props.status)) : t('common.all')}
    </Link>
  );
}

const RecentOrders = React.memo((_: RecentOrdersProps) => {
  const { t } = useTranslation('pos');
  const { isLoading, data: recentOrders, isRefetching, refetch } = useRecentOrders();
  const { data: paidOrderTokens } = usePaidOrderTokens();
  const currentRestaurant = useCurrentRestaurant();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const updateOrdersMutation = useUpdateOrdersMutation();
  const { recentOrdersFilters, viewingOrder, setViewingOrder, expandingRecentOrders, toggleExpandingRecentOrders } =
    usePOSStore(
      (state) => ({
        expandingRecentOrders: state.expandingRecentOrders,
        toggleExpandingRecentOrders: state.toggleExpandingRecentOrders,
        viewingOrder: state.viewingOrder,
        setViewingOrder: state.setViewingOrder,
        recentOrdersFilters: state.recentOrdersFilters,
      }),
      shallow
    );

  const handleChangeOrderStatus = useCallback((order: OrderEntity | null | undefined, status: OrderStatusEnum) => {
    if (!order) return;
    updateOrdersMutation.mutate({ id: order.id, status });
  }, []);

  const handleCancelOrders = useCallback((order: OrderEntity | null | undefined, cancelReason: string) => {
    if (!order) return;
    updateOrdersMutation.mutate({ id: order.id, status: OrderStatusEnum.CANCELLED, cancelReason });
  }, []);

  const containerClass = classNames({
    'pos-recent-orders-container': true,
    'pos-recent-orders-container--expanded': expandingRecentOrders,
  });
  let filteredRecentOrders = { ...recentOrders };
  if (recentOrdersFilters.type) {
    filteredRecentOrders.data = filteredRecentOrders.data?.filter((item) => item.type === recentOrdersFilters.type);
  }
  if (recentOrdersFilters.status) {
    filteredRecentOrders.data = filteredRecentOrders.data?.filter((item) => item.status === recentOrdersFilters.status);
  }
  return (
    <div className={containerClass}>
      <div className={'pos-recent-orders-heading'}>
        <h3>
          {t('recentOrders')} ({filteredRecentOrders?.data?.length || 0})
        </h3>
        <div className="actions">
          <RecentOrderTypeAction type={null} />
          <RecentOrderTypeAction type={OrderTypeEnum.DINE_IN} />
          <RecentOrderTypeAction type={OrderTypeEnum.TAKEAWAY} />
          <RecentOrderTypeAction type={OrderTypeEnum.DELIVERY} />
          <span>/</span>
          <RecentOrderStatusAction status={null} />
          <RecentOrderStatusAction status={OrderStatusEnum.IN_PROCESS} />
          <RecentOrderStatusAction status={OrderStatusEnum.READY} />
          <span>/</span>
          <Link onClick={refetch}>{isRefetching ? `${t('refreshing')}...` : t('refresh')}</Link>
          <span>/</span>
          <Link onClick={toggleExpandingRecentOrders}>{expandingRecentOrders ? t('close') : t('expand')}</Link>
        </div>
      </div>
      <div className={`pos-recent-orders`}>
        {isLoading && <RecentOrdersLoading />}
        {!isLoading && !currentRestaurantBranch && <EmptyState title={`${t('messages.pleaseSelectABranch')}!`} />}
        {!isLoading && filteredRecentOrders?.data?.length === 0 && !!currentRestaurantBranch && (
          <EmptyState title={t('messages.noRecentOrders')} />
        )}
        <div className={'pos-recent-orders-grid'}>
          {filteredRecentOrders?.data?.map((order) => (
            <div key={order.id} className={'pos-recent-orders-grid-item'}>
              <RecentOrderItem order={order} paidOrderTokens={paidOrderTokens} />
            </div>
          ))}
        </div>
      </div>
      <Modal
        open={!!viewingOrder}
        size={'md'}
        modalHeading={`#${viewingOrder?.token}`}
        modalLabel={`${currentRestaurant?.name} - ${currentRestaurantBranch?.name}`}
        passiveModal
        onRequestClose={() => setViewingOrder(null)}
      >
        <OrderDetails
          order={viewingOrder!}
          onPaid={(order) => {
            setViewingOrder(null);
            handleChangeOrderStatus(order, OrderStatusEnum.PAID);
          }}
          paidOrderTokens={paidOrderTokens}
          onCancelled={(order, cancelReason) => {
            setViewingOrder(null);
            handleCancelOrders(order, cancelReason);
          }}
        />
      </Modal>
    </div>
  );
});

export default RecentOrders;
