import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { Button, Link, TextInput } from '@carbon/react';
import { ShoppingBag } from '@carbon/react/icons';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import SuccessIcon from 'src/components/Lottie/SuccessIcon';
import CurrentOrderAvailableTables from 'src/components/POS/CurrentOrderAvailableTables';
import CurrentOrderDeliveryInfo from 'src/components/POS/CurrentOrderDeliveryInfo';
import CurrentOrderTypes from 'src/components/POS/CurrentOrderTypes';
import OrderLine from 'src/components/POS/OrderLine';
import { useCreateOrdersMutation, useUpdateOrdersMutation } from 'src/hooks/useOrders';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { OrderUpdateDto } from 'src/services/apis/types';
import { usePOSStore } from 'src/states/pos';
import { NumberToK } from 'src/utils/price';

interface CurrentOrderProps {}

const CurrentOrder = React.memo((_: CurrentOrderProps) => {
  const { t } = useTranslation('pos');
  const [placingOrder, setPlacingOrder] = useState(false);
  const createOrdersMutation = useCreateOrdersMutation();
  const updateOrdersMutation = useUpdateOrdersMutation();
  const currentRestaurant = useCurrentRestaurant();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const {
    currentOrderItems,
    currentOrderTableId,
    currentOrderPhoneNumber,
    currentOrderAddress,
    currentOrderType,
    currentOrderNotes,
    clearOrderItems,
    setOrderNotes,
    clearCurrentOrder,
    editingOrder,
  } = usePOSStore(
    (state) => ({
      currentOrderItems: state.currentOrderItems,
      clearOrderItems: state.clearOrderItems,
      currentOrderType: state.currentOrderInfo.type,
      currentOrderTableId: state.currentOrderInfo.tableId,
      currentOrderPhoneNumber: state.currentOrderInfo.phoneNumber,
      currentOrderAddress: state.currentOrderInfo.address,
      currentOrderNotes: state.currentOrderInfo.notes,
      editingOrder: state.currentOrderInfo.editingOrder,
      setOrderNotes: state.setOrderNotes,
      clearCurrentOrder: state.clearCurrentOrder,
    }),
    shallow
  );
  const [openAddNotes, setOpenAddNotes] = useState(false);

  useEffect(() => {
    if (currentOrderItems.length === 0 && editingOrder) {
      return clearCurrentOrder();
    }
  }, [currentOrderItems.length, editingOrder]);

  const handlePlaceOrder = async () => {
    setPlacingOrder(true);
    const tableId = [OrderTypeEnum.DELIVERY, OrderTypeEnum.TAKEAWAY].includes(currentOrderType!)
      ? undefined
      : currentOrderTableId;
    const phoneNumber = currentOrderType === OrderTypeEnum.DELIVERY ? currentOrderPhoneNumber : undefined;
    const address = currentOrderType === OrderTypeEnum.DELIVERY ? currentOrderAddress : undefined;
    const notes = openAddNotes ? currentOrderNotes : undefined;
    if (editingOrder) {
      const payload: OrderUpdateDto & { tableId?: string | null } = {
        id: editingOrder.id,
        phoneNumber,
        line1: address,
        notes,
        tableId,
        type: currentOrderType!,
      };
      if (currentOrderType === OrderTypeEnum.DELIVERY) {
        payload.tableId = undefined;
      }
      const hasEditOrderItems =
        currentOrderItems.filter((item) => item.editing).length > 0 ||
        editingOrder.orderItems.length !== currentOrderItems.length;
      if (hasEditOrderItems) {
        const grandTotal = currentOrderItems
          .map((item) => item.subTotal)
          .reduce((total, itemTotal) => total + itemTotal);
        payload.orderItems = currentOrderItems.map((orderLineItem) => ({
          menuItemId: orderLineItem.orderItem.id,
          menuItemVariantId: orderLineItem.orderItem.menuItemVariantId || undefined,
          quantity: orderLineItem.quantity,
          sku: orderLineItem.orderItem.sku,
          subTotal: orderLineItem.subTotal,
        }));
        payload.grandTotal = grandTotal;
        payload.subTotal = grandTotal;
      }
      await updateOrdersMutation.mutateAsync(payload);
      clearCurrentOrder();
      setPlacingOrder(false);
      setTimeout(() => {
        updateOrdersMutation.reset();
      }, 2000);
      return;
    }
    const grandTotal = currentOrderItems.map((item) => item.subTotal).reduce((total, itemTotal) => total + itemTotal);
    await createOrdersMutation.mutateAsync({
      restaurantId: currentRestaurant?.id!,
      restaurantBranchId: currentRestaurantBranch?.id!,
      tableId,
      grandTotal,
      total: grandTotal,
      type: currentOrderType!,
      tax: 0,
      orderItems: currentOrderItems.map((orderLineItem) => ({
        menuItemId: orderLineItem.orderItem.id,
        menuItemVariantId: orderLineItem.orderItem.menuItemVariantId || undefined,
        quantity: orderLineItem.quantity,
        sku: orderLineItem.orderItem.sku,
        subTotal: orderLineItem.subTotal,
      })),
      subTotal: grandTotal,
      status: OrderStatusEnum.IN_PROCESS,
      phoneNumber,
      line1: address,
      notes,
    });
    clearCurrentOrder();
    setOpenAddNotes(false);
    setPlacingOrder(false);
    setTimeout(() => {
      createOrdersMutation.reset();
    }, 2000);
  };

  const canSubmit = () => {
    if (currentOrderItems.length === 0) {
      return false;
    }
    if ([OrderTypeEnum.TAKEAWAY].includes(currentOrderType!)) {
      return true;
    }
    if ([OrderTypeEnum.DINE_IN].includes(currentOrderType!)) {
      return !!currentOrderTableId;
    }
    return currentOrderType === OrderTypeEnum.DELIVERY && currentOrderPhoneNumber && currentOrderAddress;
  };

  const subTotal = currentOrderItems?.map((item) => item.subTotal)?.reduce((total, subTotal) => total + subTotal, 0);
  const submitOrderSuccessfully = createOrdersMutation.isSuccess || updateOrdersMutation.isSuccess;
  const submitting = placingOrder || createOrdersMutation.isLoading || updateOrdersMutation.isLoading;

  return (
    <div className={'pos-current-order'}>
      {currentOrderItems.length > 0 && (
        <div className="pos-current-order-items">
          {currentOrderItems.map((row, index) => (
            <OrderLine orderLine={row} key={row.orderItem.id} index={index + 1} />
          ))}
          {(!!currentOrderNotes || openAddNotes) && (
            <TextInput
              id="notes"
              defaultValue={currentOrderNotes}
              onChange={(e) => setOrderNotes(e.target.value?.trim())}
              autoFocus
              labelText={t('notes')}
            />
          )}
        </div>
      )}
      {currentOrderItems.length > 0 && (
        <div className={'pos-current-order-actions'}>
          {editingOrder && <Link onClick={clearCurrentOrder}>{t('cancelEdit')}</Link>}
          {!editingOrder && <Link onClick={clearOrderItems}>{t('clearItems')}</Link>}
          <Link
            onClick={() => {
              setOpenAddNotes(!openAddNotes);
              setOrderNotes('');
            }}
          >
            {openAddNotes ? t('clearNotes') : t('addNotes')}
          </Link>
        </div>
      )}
      <div className="pos-current-order-summary">
        <div className="total-container">
          {currentOrderItems.length === 0 && (
            <div className="empty">
              {!submitOrderSuccessfully && <ShoppingBag size={56} />}
              {!submitOrderSuccessfully && <h4>{t('messages.noItemsAdded')}</h4>}
              {submitOrderSuccessfully && <SuccessIcon />}
            </div>
          )}
          {currentOrderItems.length > 0 && (
            <>
              <div className="sub-total">
                <div className="line">
                  <h4>{t('subTotal')}:</h4>
                  <h5>{NumberToK(subTotal)}K</h5>
                </div>
                <div className="line">
                  <h4>{t('tax')} (0%):</h4>
                  <h5>0</h5>
                </div>
              </div>
              <hr />
              <div className="total">
                <div className="line">
                  <h4>{t('total')}:</h4>
                  <h5>{NumberToK(subTotal)}K</h5>
                </div>
              </div>
            </>
          )}
        </div>
        <CurrentOrderTypes />
        <CurrentOrderAvailableTables />
        <CurrentOrderDeliveryInfo />
        <div className="actions">
          <Button size={'xl'} disabled={!canSubmit() || submitting} onClick={handlePlaceOrder}>
            {submitting ? `${t('placingOrder')}...` : editingOrder ? t('updateOrder') : t('placeOrder')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default CurrentOrder;
