import React, { useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { Content, Theme } from '@carbon/react';
import { ChevronRight } from '@carbon/react/icons';
import { useAppStore } from 'src/states/app';
import MainHeader from './MainHeader';

interface MainLayoutProps {}

const MainLayout = React.memo((_: MainLayoutProps) => {
  const { theme, fullscreen, setFullscreen } = useAppStore(
    (state) => ({
      theme: state.theme,
      zoom: state.zoom,
      fullscreen: state.fullscreen,
      setFullscreen: state.setFullscreen,
    }),
    shallow
  );

  useEffect(() => {
    document.body.className = theme === 'dark' ? 'cds--g90' : 'cds--white';
  }, [theme]);

  const handleToggleSideNav = useCallback(() => {
    const elements = document.getElementsByClassName('cds--main-content');
    if (elements[0]?.classList.contains('cds--content--with-side-nav-expanded')) {
      return elements[0]?.classList.remove('cds--content--with-side-nav-expanded');
    }
    elements[0]?.classList.add('cds--content--with-side-nav-expanded');
  }, []);

  return (
    <div className={fullscreen ? 'cds--fullscreen' : ''}>
      <Theme theme={'g100'}>
        <MainHeader onSideNavToggle={handleToggleSideNav} />
      </Theme>
      <Theme theme={theme === 'dark' ? 'g90' : 'white'}>
        <Content className={'cds--main-content'}>
          <Outlet />
        </Content>
      </Theme>
      <a className="fullscreen-toggle" onClick={() => setFullscreen(!fullscreen)}>
        <ChevronRight />
      </a>
    </div>
  );
});

export default MainLayout;
