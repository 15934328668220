import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ComboBox,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Pagination,
  Loading,
  Modal,
} from '@carbon/react';
import { Add, Renew, Erase } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import FormattedDate from 'src/components/Base/FormattedDate';
import IssueForm from 'src/components/Issue/IssueForm';
import { IssueTypeTag, IssueStatusTag } from 'src/components/Issue/IssueTags';
import {
  useIssues,
  useCreateIssuesMutation,
  useDeleteIssuesMutation,
  useUpdateIssuesMutation,
} from 'src/hooks/useIssues';
import { useBranches, useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { IssueEntity } from 'src/services/apis/types';
import { getMutationErrorMessage } from 'src/utils/error';
import { issueStatusOptions } from 'src/utils/issue';
import { IssueTypeEnum } from 'src/utils/issue';
import { KToNumber, NumberToK } from 'src/utils/price';

const AllIssues = React.memo(
  ({ employee, dish, operation }: { employee?: boolean; dish?: boolean; operation?: boolean }) => {
    const { t } = useTranslation();
    const formRef = useRef<any>();
    const {
      query: { isLoading, data: issues, isRefetching, refetch },
      page,
      filters,
      setPage,
      setFilters,
    } = useIssues(employee, dish, operation);
    const { data: branches } = useBranches();
    const currentRestaurant = useCurrentRestaurant();
    const currentRestaurantBranch = useCurrentRestaurantBranch();
    const createIssuesMutation = useCreateIssuesMutation();
    const updateIssuesMutation = useUpdateIssuesMutation();
    const deleteIssuesMutation = useDeleteIssuesMutation();

    const [openAddIssue, setOpenAddIssue] = useState(false);
    const [editingIssue, setEditingIssue] = useState<null | undefined | IssueEntity>(null);
    const [deletingIssue, setDeletingIssue] = useState<null | undefined | IssueEntity>(null);

    const handleCloseAddOrEditIssue = useCallback(() => {
      setOpenAddIssue(false);
      setEditingIssue(null);
      setEditingIssue(null);
      formRef.current?.resetForm();
    }, []);

    const handleAddIssue = useCallback(() => {
      setOpenAddIssue(true);
    }, []);

    const handleCreate = useCallback(
      async (values) => {
        if (values.type === IssueTypeEnum.EMPLOYEE && !values.employeeId) {
          return;
        }
        await createIssuesMutation.mutateAsync({
          ...values,
          restaurantId: currentRestaurant?.id,
          tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
          fine: KToNumber(parseInt(values.fine || 0, 10)),
        });
        handleCloseAddOrEditIssue();
      },
      [currentRestaurant?.id]
    );

    const handleUpdate = useCallback(
      async (values) => {
        if (values.type === IssueTypeEnum.EMPLOYEE && !values.employeeId) {
          return;
        }
        await updateIssuesMutation.mutateAsync({
          ...values,
          tags: values.tags?.split(',').map((tag) => tag.trim()) || [],
          restaurantId: currentRestaurant?.id,
          fine: KToNumber(parseInt(values.fine || 0, 10)),
        });
        handleCloseAddOrEditIssue();
      },
      [currentRestaurant?.id]
    );

    const handleAbortDelete = useCallback(() => {
      setDeletingIssue(null);
    }, []);

    const handleDelete = useCallback(async () => {
      await deleteIssuesMutation.mutateAsync(deletingIssue?.id!);
      setDeletingIssue(null);
    }, [deletingIssue]);

    const headers = [{ key: 'title', header: t('common.title') }];

    const extraHeaders = [
      { key: 'employee', header: t('common.employee') },
      { key: 'fine', header: t('common.fine') },
      { key: 'type', header: t('common.type') },
      { key: 'status', header: t('common.status') },
      { key: 'branch', header: t('common.branch') },
      { key: 'openedAt', header: t('common.openedAt') },
      { key: 'actions', header: t('common.actions') },
    ];

    if (isLoading) {
      return (
        <DataTableSkeleton columnCount={headers.length + 7} rowCount={6} headers={[...headers, ...extraHeaders]} />
      );
    }

    const getTitle = () => {
      if (employee) {
        return t('common.employeeIssue');
      }
      if (dish) {
        return t('common.dishIssue');
      }
      if (operation) {
        return t('common.operationIssue');
      }
      return t('common.issue');
    };

    const getTableTitle = () => {
      if (currentRestaurantBranch?.name) {
        return t('titles.issuesPage', {
          restaurantName: currentRestaurant?.name,
          branchName: currentRestaurantBranch?.name,
          title,
        });
      }
      return t('titles.issuesPageWithoutBranch', {
        restaurantName: currentRestaurant?.name,
        title,
      });
    };

    const filteredRows = issues?.data || [];
    const submitting = createIssuesMutation.isLoading || updateIssuesMutation.isLoading;
    const createError = getMutationErrorMessage(createIssuesMutation.error as AxiosError);
    const updateError = getMutationErrorMessage(updateIssuesMutation.error as AxiosError);
    const title = getTitle();

    return (
      <>
        <DataTable rows={filteredRows} headers={headers}>
          {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
            <TableContainer title={getTableTitle()} description={`${issues?.total} ${title.toLowerCase()}`}>
              <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                {isRefetching && <Loading className={'some-class'} small />}
                <TableToolbarContent>
                  <TableToolbarSearch onChange={(e) => setFilters({ ...filters, search: e.target.value })} />
                  <ComboBox
                    items={issueStatusOptions}
                    placeholder={`${t('common.filterByStatus')}...`}
                    size={'lg'}
                    onChange={(e) => setFilters({ ...filters, status: e.selectedItem?.id })}
                    className={'cds--combo-box--no-border-bottom'}
                    itemToString={(item) => (item ? item.text : '')}
                  />
                  <DatePicker
                    datePickerType="range"
                    value={
                      filters.startDate && filters.endDate
                        ? [new Date(filters.startDate), new Date(filters.endDate)]
                        : []
                    }
                    onChange={(dates) => {
                      if (dates.length > 1) {
                        setFilters({
                          ...filters,
                          startDate: dates[0]?.toISOString(),
                          endDate: dates[1]?.toISOString(),
                        });
                      }
                    }}
                  >
                    <DatePickerInput placeholder={t('dateRanges.startDate')} />
                    <DatePickerInput placeholder={t('dateRanges.endDate')} />
                  </DatePicker>
                  {filters.startDate && filters.endDate && (
                    <Button
                      renderIcon={Erase}
                      onClick={() => setFilters({ ...filters, startDate: null, endDate: null })}
                      hasIconOnly
                      kind="ghost"
                      iconDescription={t('common.clearDates')}
                    />
                  )}
                  <Button
                    renderIcon={Renew}
                    onClick={refetch}
                    hasIconOnly
                    kind="secondary"
                    iconDescription={t('common.refresh')}
                  />
                  <Button renderIcon={Add} onClick={handleAddIssue}>
                    {t('actions.create')} {title}
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {[...headers, ...extraHeaders].map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                      <TableCell>
                        {filteredRows[index]?.employee
                          ? `${filteredRows[index]?.employee?.user?.firstName} ${filteredRows[index]?.employee?.user?.lastName}`
                          : 'N/A'}
                      </TableCell>
                      <TableCell>{NumberToK(filteredRows[index]?.fine)}K</TableCell>
                      <TableCell>
                        <IssueTypeTag type={filteredRows[index]?.type} />
                      </TableCell>
                      <TableCell>
                        <IssueStatusTag status={filteredRows[index]?.status} />
                      </TableCell>
                      <TableCell>
                        {filteredRows[index] &&
                          branches?.data.find((item) => item.id === filteredRows[index]?.restaurantBranchId)?.name}
                      </TableCell>
                      <TableCell>
                        <FormattedDate date={filteredRows[index]?.createdAt} format={'DD/MM/YYYY'} />
                      </TableCell>
                      <TableCell>
                        <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                          <OverflowMenuItem
                            itemText={t('actions.edit')}
                            requireTitle
                            onClick={() => setEditingIssue(filteredRows[index])}
                          />
                          <OverflowMenuItem
                            hasDivider
                            isDelete
                            itemText={t('actions.delete')}
                            onClick={() => setDeletingIssue(filteredRows[index])}
                            requireTitle
                          />
                        </OverflowMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {rows.length === 0 && <EmptyState />}
            </TableContainer>
          )}
        </DataTable>
        <Pagination
          backwardText={t('common.previousPage')}
          forwardText={t('common.nextPage')}
          itemsPerPageText={`${t('common.itemsPerPage')}:`}
          onChange={({ pageSize, page }) => {
            setPage(page);
            setFilters({ ...filters, limit: pageSize });
          }}
          itemText={(min, max) => `${min}–${max} ${t('common.items')}`}
          pageRangeText={(_current, total) =>
            `${t('common.of')} ${total} ${total === 1 ? t('common.page') : t('common.pages')}`
          }
          page={page}
          pageSize={filters.limit}
          pageSizes={[20, 50, 100]}
          size="lg"
          totalItems={issues?.total}
        />
        <Modal
          open={openAddIssue || !!editingIssue}
          size={'sm'}
          modalHeading={openAddIssue ? `${t('actions.createNew')} ${title}` : `${t('actions.update')} ${title}`}
          modalLabel={`${currentRestaurant?.name}'s ${title}`}
          primaryButtonText={
            submitting ? `${t('common.loading')}...` : editingIssue ? t('actions.update') : t('actions.create')
          }
          secondaryButtonText={t('actions.cancel')}
          onSecondarySubmit={handleCloseAddOrEditIssue}
          onRequestClose={handleCloseAddOrEditIssue}
          primaryButtonDisabled={submitting}
          onRequestSubmit={() => formRef.current?.submitForm()}
        >
          <IssueForm
            errorMessage={createError || updateError}
            initialValues={editingIssue}
            ref={formRef}
            onSubmit={editingIssue ? handleUpdate : handleCreate}
          />
        </Modal>
        <DeleteConfirmation
          open={!!deletingIssue}
          heading={t('messages.areYouSureToDeleteThis', { title })}
          label={`${currentRestaurant?.name}'s ${title}`}
          onClose={handleAbortDelete}
          onDelete={handleDelete}
          deleting={deleteIssuesMutation.isLoading}
        />
      </>
    );
  }
);

export default AllIssues;
