import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Header, HeaderContainer, HeaderMenuButton, HeaderName } from '@carbon/react';
import { MainHeaderGlobalBar } from 'src/components/Layout/MainHeaderGlobalBar';
import { MainHeaderNavigation } from 'src/components/Layout/MainHeaderNavigation';
import { MainSideNav } from 'src/components/Layout/MainSideNav';
import { SwitchStorePanel } from 'src/components/Layout/SwitchStorePanel';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';

const MainHeader = ({ onSideNavToggle }) => {
  const [expandingRightPanel, setExpandingRightPanel] = useState(false);
  const currentRestaurant = useCurrentRestaurant();
  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Restacity">
          <HeaderMenuButton
            isCollapsible
            aria-label="Open menu"
            onClick={() => {
              onClickSideNavExpand();
              onSideNavToggle(!isSideNavExpanded);
            }}
            isActive={isSideNavExpanded}
          />
          <HeaderName as={Link} to="/" prefix={currentRestaurant?.name || 'Loading...'} />
          <MainHeaderNavigation />
          <MainSideNav expanded={isSideNavExpanded} />
          <MainHeaderGlobalBar
            active={expandingRightPanel}
            onExpandSwitchStore={() => setExpandingRightPanel(!expandingRightPanel)}
          />
          <SwitchStorePanel expanded={expandingRightPanel} />
        </Header>
      )}
    />
  );
};

export default MainHeader;
