import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { appSettingsKey } from 'src/utils/settings';

interface AppState {
  theme: 'dark' | 'light' | 'system';
  locale: 'vi' | 'en';
  zoom: number;
  fullscreen: boolean;
  setTheme: (theme: 'dark' | 'light' | 'system') => void;
  setLocale: (locale: 'vi' | 'en') => void;
  setZoom: (zoom: number) => void;
  setFullscreen: (fullscreen: boolean) => void;
}

export const useAppStore = create(
  persist<AppState>(
    (set) => ({
      theme: 'dark',
      locale: import.meta.env.VITE_DEFAULT_LOCALE || 'vi',
      zoom: 1,
      fullscreen: false,
      setTheme: (theme: 'dark' | 'light' | 'system') => {
        set((state) => {
          return {
            ...state,
            theme,
          };
        });
      },
      setLocale: (locale: 'vi' | 'en') => {
        set((state) => {
          return {
            ...state,
            locale,
          };
        });
      },
      setZoom: (zoom: number) => {
        set((state) => {
          return {
            ...state,
            zoom,
          };
        });
      },
      setFullscreen: (fullscreen: boolean) => {
        set((state) => {
          return {
            ...state,
            fullscreen,
          };
        });
      },
    }),
    {
      name: appSettingsKey,
      storage: createJSONStorage(() => localStorage),
    }
  )
);
