import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import TabsWithTablesLayout from 'src/components/Layout/TabsWithTablesLayout';

interface OrdersPageProps {}

const OrdersPage = React.memo((_: OrdersPageProps) => {
  const { t } = useTranslation();
  const routes = [
    {
      label: t('common.today'),
      url: '/orders/today',
    },
    {
      label: t('common.all'),
      url: '/orders/all',
    },
  ];
  return (
    <TabsWithTablesLayout routes={routes} title={t('common.orders')}>
      <Outlet />
    </TabsWithTablesLayout>
  );
});

export default OrdersPage;
