import dayjs from 'dayjs';
import { create } from 'zustand';
import { DashboardDateRange } from '@restacity/shared/enums/dashboard';

interface DashboardState {
  startDate: Date;
  endDate: Date;
  dateRange: DashboardDateRange;
  setDates: (startDate: Date, endDate: Date, dateRange: DashboardDateRange) => void;
}

export const useDashboardStore = create<DashboardState>((set) => ({
  startDate: dayjs().startOf('day').toDate(),
  endDate: dayjs().endOf('day').toDate(),
  dateRange: DashboardDateRange.DAILY,
  setDates: (startDate, endDate, dateRange) => {
    set((state) => {
      return {
        ...state,
        startDate,
        endDate,
        dateRange,
      };
    });
  },
}));
