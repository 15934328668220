import { AxiosError } from 'axios';
import { Formik, Field } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Link, FormGroup, Stack, TextInput, Checkbox, PasswordInput, Button, InlineNotification } from '@carbon/react';
import { ArrowRight } from '@carbon/react/icons';
import { useSignInMutation } from 'src/hooks/useSignIn';

interface SignInProps {}

const Schema = z.object({
  username: z.string().max(60).trim(),
  password: z.string().max(60).trim(),
});

const SignIn = React.memo((_: SignInProps) => {
  const { t } = useTranslation('authentication');
  const signInMutation = useSignInMutation();

  const handleSubmit = useCallback((email, password) => {
    signInMutation.mutate({ email, password });
  }, []);

  const invalidPasswordError = (signInMutation?.error as AxiosError)?.response?.status === 422;

  return (
    <div className={'sign-in__container'}>
      <h4>{t('signIn.title')}</h4>
      <p>
        {t('signIn.troubleToSignIn')} <Link>{t('signIn.contactUs')}.</Link>
      </p>
      {invalidPasswordError && <InlineNotification subtitle={t('errors.invalidEmailOrUsername')} />}
      <Formik
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          username: '',
          password: '',
          rememberMe: false,
        }}
        onSubmit={async (values) => handleSubmit(values.username, values.password)}
      >
        {({ handleSubmit, values, setFieldValue, isValid }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          >
            <FormGroup className={'sign-in__form'} legendText={''}>
              <Stack gap={5}>
                <Field name="username">
                  {({ field, meta }) => {
                    return (
                      <TextInput
                        id="username"
                        labelText={t('signIn.emailOrUsername')}
                        invalid={!!(meta.touched && meta.error)}
                        invalidText={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Field name="password">
                  {({ field, meta }) => {
                    return (
                      <PasswordInput
                        id="password"
                        labelText={t('signIn.password')}
                        invalid={!!(meta.touched && meta.error)}
                        invalidText={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <div className="sign-in__options">
                  <Checkbox
                    labelText={t('signIn.rememberMe')}
                    checked={values.rememberMe}
                    onChange={(e, { checked }) => setFieldValue('rememberMe', checked)}
                    id="checkbox-label-1"
                  />
                  <Link>{t('signIn.forgotPassword')}</Link>
                </div>
                <Button
                  renderIcon={ArrowRight}
                  disabled={!isValid || signInMutation.isLoading}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {t('signIn.submitButtonText')}
                </Button>
              </Stack>
            </FormGroup>
          </form>
        )}
      </Formik>
    </div>
  );
});

export default SignIn;
