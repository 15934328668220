import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { TextInput, Stack } from '@carbon/react';
import { OrderTypeEnum } from '@restacity/shared/enums/order';
import { usePOSStore } from 'src/states/pos';

interface CurrentOrderDeliveryInfoProps {}

const CurrentOrderDeliveryInfo = React.memo((_: CurrentOrderDeliveryInfoProps) => {
  const { t } = useTranslation('pos');
  const { currentOrderType, currentOrderPhoneNumber, currentOrderAddress, setOrderPhoneNumber, setOrderAddress } =
    usePOSStore(
      (state) => ({
        currentOrderType: state.currentOrderInfo.type,
        currentOrderAddress: state.currentOrderInfo.address,
        currentOrderPhoneNumber: state.currentOrderInfo.phoneNumber,
        setOrderPhoneNumber: state.setOrderPhoneNumber,
        setOrderAddress: state.setOrderAddress,
      }),
      shallow
    );
  if (![OrderTypeEnum.DELIVERY].includes(currentOrderType!)) {
    return null;
  }
  return (
    <div className="delivery-info">
      <h5>{t('deliveryInformation')}</h5>
      <div className="form">
        <Stack gap={5}>
          <TextInput
            id="phoneNumber"
            name="phoneNumber"
            defaultValue={currentOrderPhoneNumber || ''}
            onChange={(e) => setOrderPhoneNumber(e.target.value?.trim())}
            autoFocus
            size={'sm'}
            pattern="[0-9]*"
            inputmode="numeric"
            labelText={t('phoneNumber')}
          />
          <TextInput
            defaultValue={currentOrderAddress || ''}
            id="address"
            name="address"
            size={'sm'}
            onChange={(e) => setOrderAddress(e.target.value?.trim())}
            labelText={t('address')}
            onBlur={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                document.body.focus();
              }
            }}
          />
        </Stack>
      </div>
    </div>
  );
});

export default CurrentOrderDeliveryInfo;
