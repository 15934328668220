import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { Checkbox, Column, FlexGrid, Row, SkeletonPlaceholder } from '@carbon/react';
import { useMenu } from 'src/hooks/useMenu';
import { usePOSStore } from 'src/states/pos';

interface MenuProps {}

const RecentOrdersLoading = React.memo(() => {
  return (
    <div className={'pos-recent-orders-grid'}>
      <div className={'pos-menu'}>
        <FlexGrid defaultSelected="standard" legend={'Icon'} className={'pos-menu-grid'}>
          <Row>
            <Column>
              <SkeletonPlaceholder style={{ width: '100%' }} />
            </Column>
            <Column>
              <SkeletonPlaceholder style={{ width: '100%' }} />
            </Column>
            <Column>
              <SkeletonPlaceholder style={{ width: '100%' }} />
            </Column>
            <Column>
              <SkeletonPlaceholder style={{ width: '100%' }} />
            </Column>
            <Column>
              <SkeletonPlaceholder style={{ width: '100%' }} />
            </Column>
          </Row>
        </FlexGrid>
      </div>
    </div>
  );
});

const Menu = React.memo((_: MenuProps) => {
  const { t } = useTranslation();
  const { isLoading, data: menu } = useMenu();
  const { expandingRecentOrders, selectedMenuId, setSelectedMenuId, toggleExpandingRecentOrders } = usePOSStore(
    (state) => ({
      selectedMenuId: state.selectedMenuId,
      setSelectedMenuId: state.setSelectedMenuId,
      expandingRecentOrders: state.expandingRecentOrders,
      toggleExpandingRecentOrders: state.toggleExpandingRecentOrders,
    }),
    shallow
  );
  if (isLoading) {
    return <RecentOrdersLoading />;
  }
  return (
    <div className={'pos-menu'}>
      <FlexGrid className={'pos-menu-grid'}>
        <Row>
          {menu?.data?.map((item, index) => {
            const selected = (!selectedMenuId && index === 0) || selectedMenuId === item.id;
            return (
              <Column
                onClick={() => {
                  if (expandingRecentOrders) {
                    toggleExpandingRecentOrders();
                  }
                  setSelectedMenuId(item.id);
                }}
                className={selected && 'cds--col--selected'}
                key={item.id}
                id={item.slug}
              >
                <img src={item.photos[0]} alt={`Restacity menu icon: ${item.photos[0]}`} />
                <h3>{item.name}</h3>
                {selected && <Checkbox checked />}
              </Column>
            );
          })}
        </Row>
      </FlexGrid>
    </div>
  );
});

export default Menu;
