import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import useWindowFocus from 'use-window-focus';
import { shallow } from 'zustand/shallow';
import { DashboardDateRange } from '@restacity/shared/enums/dashboard';
import { getDashboardAccountingReports, getDashboardSalesReports } from 'src/services/apis/services';
import { useDashboardStore } from 'src/states/dashboard';
import { QUERIES } from 'src/utils/react-query';

export const useDashboardSalesReports = () => {
  const { startDate, endDate, dateRange, setDates } = useDashboardStore(
    (state) => ({
      startDate: state.startDate,
      endDate: state.endDate,
      dateRange: state.dateRange,
      setDates: state.setDates,
    }),
    shallow
  );
  const windowFocused = useWindowFocus();

  useEffect(() => {
    if (windowFocused && dateRange === DashboardDateRange.DAILY) {
      if (startDate.toISOString() !== dayjs().startOf('day').toDate().toISOString()) {
        setDates(dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate(), DashboardDateRange.DAILY);
      }
    }
  }, [windowFocused]);

  const query = useQuery(
    [
      QUERIES.DASHBOARD_SALES_REPORTS,
      {
        startDate,
        endDate,
        dateRange,
      },
    ],
    ({ queryKey: [_key, _filters] }) => getDashboardSalesReports(_filters as any),
    {
      cacheTime: 0,
      refetchOnMount: true,
      refetchInterval: 120000,
      keepPreviousData: false,
      refetchOnWindowFocus: true,
    }
  );
  return {
    query,
  };
};

export const useDashboardAccountingReports = () => {
  const { startDate, endDate, dateRange, setDates } = useDashboardStore(
    (state) => ({
      startDate: state.startDate,
      endDate: state.endDate,
      dateRange: state.dateRange,
      setDates: state.setDates,
    }),
    shallow
  );

  const windowFocused = useWindowFocus();

  useEffect(() => {
    if (windowFocused && dateRange === DashboardDateRange.DAILY) {
      if (startDate.toISOString() !== dayjs().startOf('day').toDate().toISOString()) {
        setDates(dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate(), DashboardDateRange.DAILY);
      }
    }
  }, [windowFocused]);

  const query = useQuery(
    [
      QUERIES.DASHBOARD_ACCOUNTING_REPORTS,
      {
        startDate,
        endDate,
        dateRange,
      },
    ],
    ({ queryKey: [_key, _filters] }) => getDashboardAccountingReports(_filters as any),
    {
      cacheTime: 0,
      refetchOnMount: true,
      refetchInterval: 120000,
      keepPreviousData: false,
      refetchOnWindowFocus: true,
    }
  );
  return {
    query,
  };
};
