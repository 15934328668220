import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';

export function getOrderTypeText(orderType: OrderTypeEnum | number) {
  if (orderType === OrderTypeEnum.DINE_IN) {
    return 'orderTypes.dineIn';
  }
  if (orderType === OrderTypeEnum.TAKEAWAY) {
    return 'orderTypes.takeaway';
  }
  if (orderType === OrderTypeEnum.DELIVERY) {
    return 'orderTypes.delivery';
  }
  return 'orderTypes.other';
}

export function getOrderStatusText(orderStatus: OrderStatusEnum | number) {
  if (orderStatus === OrderStatusEnum.IN_PROCESS) {
    return 'orderStatuses.inProgress';
  }
  if (orderStatus === OrderStatusEnum.READY) {
    return 'orderStatuses.ready';
  }
  if (orderStatus === OrderStatusEnum.PAID) {
    return 'orderStatuses.paid';
  }
  if (orderStatus === OrderStatusEnum.CANCELLED) {
    return 'orderStatuses.cancelled';
  }
  return 'orderStatuses.other';
}

export function getOrderTypeColor(orderType: OrderTypeEnum | number) {
  if (orderType === OrderTypeEnum.DINE_IN) {
    return 'gray';
  }
  if (orderType === OrderTypeEnum.TAKEAWAY) {
    return 'cool-gray';
  }
  if (orderType === OrderTypeEnum.DELIVERY) {
    return 'warm-gray';
  }
  return 'gray';
}

export function getOrderStatusColor(orderStatus: OrderStatusEnum | number) {
  if (orderStatus === OrderStatusEnum.IN_PROCESS) {
    return 'purple';
  }
  if (orderStatus === OrderStatusEnum.READY) {
    return 'cyan';
  }
  if (orderStatus === OrderStatusEnum.PAID) {
    return 'green';
  }
  if (orderStatus === OrderStatusEnum.CANCELLED) {
    return 'red';
  }
  return 'gray';
}

export const OrderTypes = [
  { label: 'orderTypes.dineIn', value: OrderTypeEnum.DINE_IN },
  { label: 'orderTypes.takeaway', value: OrderTypeEnum.TAKEAWAY },
  { label: 'orderTypes.delivery', value: OrderTypeEnum.DELIVERY },
];

export const orderTypeOptions = [
  { id: OrderTypeEnum.DINE_IN, text: getOrderTypeText(OrderTypeEnum.DINE_IN) },
  { id: OrderTypeEnum.TAKEAWAY, text: getOrderTypeText(OrderTypeEnum.TAKEAWAY) },
  { id: OrderTypeEnum.DELIVERY, text: getOrderTypeText(OrderTypeEnum.DELIVERY) },
];

export const orderStatusOptions = [
  { id: OrderStatusEnum.IN_PROCESS, text: getOrderStatusText(OrderStatusEnum.IN_PROCESS) },
  { id: OrderStatusEnum.READY, text: getOrderStatusText(OrderStatusEnum.READY) },
  { id: OrderStatusEnum.PAID, text: getOrderStatusText(OrderStatusEnum.PAID) },
  { id: OrderStatusEnum.CANCELLED, text: getOrderStatusText(OrderStatusEnum.CANCELLED) },
];
