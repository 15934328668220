import MDEditor from '@uiw/react-md-editor';
import { Formik } from 'formik';
import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { shallow } from 'zustand/shallow';
import { ComboBox, FormGroup, InlineNotification, Stack, Toggle, FormLabel } from '@carbon/react';
import { FormFieldUpload } from 'src/components/Form/FormFieldUpload';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import RestaurantBranchesSelector from 'src/components/Form/RestaurantBranchesSelector';
import { useMenuItems } from 'src/hooks/useMenuItems';
import { MenuItemVariantEntity } from 'src/services/apis/types';
import { useAppStore } from 'src/states/app';
import { NumberToK } from 'src/utils/price';

interface MenuItemFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: MenuItemVariantEntity | null | undefined;
  onSelectedFile: ({ file, previewUrl }) => void;
}

const Schema = z.object({
  name: z.string().max(60).trim(),
  menuItemId: z.string().max(60).trim(),
  specificForBranchIds: z.array(z.string()),
  sku: z.string().max(32).trim(),
  price: z.preprocess((a) => parseFloat(`${a}`), z.number()),
});

const MenuItemForm = (props: MenuItemFormProps, ref) => {
  const { t } = useTranslation();
  const { theme } = useAppStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow
  );
  const formikRef = useRef<any>();
  const { data: menuItems } = useMenuItems();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            name: '',
            sku: '',
            menuId: null,
            specificForBranchIds: [],
            price: 0,
            details: '',
            photo: '',
            photoBlurhash: '',
          },
        }),
    };
  });

  const menuItemSelectionItems = useMemo(() => {
    return menuItems?.data.map((item) => ({ id: item.id, text: item.name }));
  }, [menuItems?.data]);

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(Schema)}
        initialValues={{
          id: props.initialValues?.id,
          name: props.initialValues?.name,
          menuItemId: props.initialValues?.menuItemId || null,
          sku: props.initialValues?.sku,
          price: props.initialValues ? NumberToK(props.initialValues?.price) : 0,
          details: props.initialValues?.details,
          tags: props.initialValues ? props.initialValues.tags?.join(',') : '',
          available: props.initialValues ? props.initialValues.available : true,
          photo: props.initialValues?.photos[0],
          specificForBranchIds: props.initialValues?.specificForBranchIds || [],
          photoBlurhash: props.initialValues?.blurhashPhotos[0],
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue, getFieldMeta, setFieldTouched, setFieldError }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <RestaurantBranchesSelector
                value={values.specificForBranchIds}
                invalid={getFieldMeta('specificForBranchIds').touched && values.specificForBranchIds?.length === 0}
                onChange={(value) => {
                  if (value?.length === 0) {
                    setFieldError('specificForBranchIds', 'Required!');
                    setFieldTouched('specificForBranchIds');
                  }
                  setFieldValue('specificForBranchIds', value);
                }}
              />
              <ComboBox
                items={menuItemSelectionItems}
                placeholder={t('common.filter')}
                selectedItem={
                  values.menuItemId ? menuItemSelectionItems?.find((item) => item.id === values.menuItemId) : null
                }
                invalid={getFieldMeta('menuItemId').touched && !values.menuItemId}
                onChange={(e) => {
                  if (!e.selectedItem) {
                    setFieldError('menuItemId', 'Required!');
                    setFieldTouched('menuItemId');
                    setFieldValue('name', '');
                    setFieldValue('sku', '');
                    setFieldValue('price', 0);
                    return setFieldValue('menuItemId', null);
                  }
                  const menuItem = menuItems?.data.find((item) => item.id === e.selectedItem?.id);
                  setFieldValue('menuItemId', e.selectedItem?.id);
                  if (menuItem) {
                    setFieldValue('name', menuItem.name);
                    setFieldValue('sku', `${menuItem.sku}-`);
                    setFieldValue('price', NumberToK(menuItem.price));
                  }
                }}
                itemToString={(item) => (item ? item.text : '')}
                titleText={t('common.selectAMenuItem')}
              />
              <FormikTextInput name={'name'} label={t('common.name')} />
              <div style={{ display: 'flex', gap: '1rem' }}>
                <FormikTextInput name={'sku'} label={t('common.sku')} />
                <FormikTextInput name={'price'} isNumber max={500} label={`${t('common.price')} (K)`} />
              </div>
              <div data-color-mode={theme}>
                <FormLabel>{t('common.details')}</FormLabel>
                <MDEditor
                  preview="edit"
                  height={100}
                  value={values.details}
                  onChange={(value) => setFieldValue('details', value)}
                />
              </div>
              <FormikTextInput name={'tags'} label={`${t('common.tags')} (${t('common.separateByAComma')})`} />
              <FormFieldUpload
                title={t('common.photo')}
                description={t('messages.photoRecommended', { size: 1, aspectRatio: '1x1' })}
                cropperAspectRatio={1}
                aspectRatio={'1x1'}
                onSelectedFile={props.onSelectedFile}
                value={values.photo}
                onChange={(data) => {
                  setFieldValue('photo', `${data.fileId}.${data.format}`);
                  setFieldValue('photoBlurhash', data.blurhash);
                }}
              />
              <Toggle
                labelText={t('common.available')}
                labelA={t('common.off')}
                labelB={t('common.on')}
                onToggle={(checked) => setFieldValue('available', checked)}
                toggled={values.available}
                id="available"
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(MenuItemForm);
