import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getMenuItemVariants,
  postMenuItemVariants,
  putMenuItemVariantsId,
  deleteMenuItemVariantsId,
} from 'src/services/apis/services';
import { MenuItemVariantUpdateDto, MenuItemVariantCreateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useMenuItemVariants = () => {
  return useQuery([QUERIES.MENU_ITEM_VARIANTS], () => getMenuItemVariants());
};

export const useCreateMenuItemVariantsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: MenuItemVariantCreateDto) => postMenuItemVariants(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU_ITEM_VARIANTS]);
    },
  });
};

export const useUpdateMenuItemVariantsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: MenuItemVariantUpdateDto) => putMenuItemVariantsId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU_ITEM_VARIANTS]);
    },
  });
};

export const useDeleteMenuItemVariantsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((menuId: string) => deleteMenuItemVariantsId(menuId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MENU_ITEM_VARIANTS]);
    },
  });
};
