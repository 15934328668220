import React from 'react';

interface SeparatorProps {
  children: string;
}

const Separator = React.memo((props: SeparatorProps) => {
  return <div className={'cds--separator'}>{props.children}</div>;
});

export default Separator;
