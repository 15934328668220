import axios from 'axios';
import { useCallback } from 'react';
import { UploadSignature } from 'src/services/apis/types';

interface CloudinaryUploadPayload {
  file: File;
  resourceType: 'image';
}

export const useCloudinaryUpload = () => {
  return useCallback(async (payload: CloudinaryUploadPayload & UploadSignature) => {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('api_key', payload.apiKey);
    formData.append('public_id', payload.fileId);
    formData.append('signature', payload.signature);
    formData.append('timestamp', payload.timestamp.toString());
    return axios.post(`https://api.cloudinary.com/v1_1/${payload.name}/${payload.resourceType}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }, []);
};
