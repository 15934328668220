import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  ComboBox,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Link,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Pagination,
  Loading,
  Modal,
  Tag,
} from '@carbon/react';
import { Add, Delivery, Renew, Erase, ShoppingBag } from '@carbon/react/icons';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import Delayed from 'src/components/Base/Delayed';
import EmptyState from 'src/components/Base/EmptyState';
import OrderTypeSelector from 'src/components/Form/OrderTypeSelector';
import OrderDate from 'src/components/Order/OrderDate';
import OrderDetails from 'src/components/Order/OrderDetails';
import { OrderTypeTag, OrderStatusTag } from 'src/components/Order/OrderTags';
import { useCancelOrdersMutation, useOrders } from 'src/hooks/useOrders';
import { useBranches, useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useTables } from 'src/hooks/useRestaurantTables';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { OrderEntity } from 'src/services/apis/types';
import { orderStatusOptions } from 'src/utils/order';
import { NumberToK } from 'src/utils/price';

const headers = [
  {
    key: 'token',
    header: 'ID',
  },
];

const AllOrders = React.memo(({ today }: { today?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    query: { isLoading, data: orders, isRefetching, refetch },
    page,
    filters,
    setPage,
    setFilters,
  } = useOrders(today || false);
  const { data: tables } = useTables();
  const { data: branches } = useBranches();
  const currentRestaurant = useCurrentRestaurant();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const cancelOrdersMutation = useCancelOrdersMutation();
  const [viewingOrder, setViewingOrder] = useState<OrderEntity | null>(null);

  const extraHeaders = [
    { key: 'items', header: t('common.items') },
    { key: 'grandTotal', header: t('common.grandTotal') },
    { key: 'type', header: t('common.orderType') },
    { key: 'status', header: t('common.status') },
    { key: 'branch', header: t('common.branch') },
    { key: 'delivery', header: `${t('orderTypes.dineIn')}/${t('common.delivery')}` },
    { key: 'date', header: t('common.createdDate') },
    { key: 'actions', header: t('common.actions') },
  ];

  if (isLoading) {
    return <DataTableSkeleton columnCount={headers.length + 7} rowCount={6} headers={[...headers, ...extraHeaders]} />;
  }

  const transformedTables = tables?.data.reduce((previousObject, currentObject) => {
    return Object.assign(previousObject, {
      [currentObject.id]: currentObject.tableNumber,
    });
  }, {});

  const filteredRows =
    orders?.data?.map((order) => {
      const totalItems = order.orderItems.map((item) => item.quantity).reduce((total, quantity) => total + quantity);
      return {
        ...order,
        totalItems,
      };
    }) || [];

  return (
    <div className={'orders-page'}>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer
            title={t('titles.ordersPage', {
              restaurantName: currentRestaurant?.name,
              branchName: currentRestaurantBranch?.name,
            })}
            description={`${orders?.total} ${t('common.orders')}`}
          >
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              {(isRefetching || cancelOrdersMutation.isLoading) && <Loading className={'some-class'} small />}
              <TableToolbarContent>
                <TableToolbarSearch onChange={(e) => setFilters({ ...filters, search: e.target.value })} />
                <OrderTypeSelector
                  value={filters.type}
                  onChange={(value) => {
                    setPage(1);
                    setFilters({ ...filters, type: value });
                  }}
                />
                <ComboBox
                  items={orderStatusOptions.map((item) => ({ ...item, text: t(item.text) }))}
                  placeholder={`${t('common.filterByOrderStatus')}...`}
                  size={'lg'}
                  onChange={(e) => {
                    setPage(1);
                    setFilters({ ...filters, status: e.selectedItem?.id });
                  }}
                  className={'cds--combo-box--no-border-bottom'}
                  itemToString={(item) => (item ? item.text : '')}
                />
                {!today && (
                  <Delayed delay={!(state as any)?.previousPath ? 0 : 500}>
                    <DatePicker
                      datePickerType="range"
                      value={
                        filters.startDate && filters.endDate
                          ? [new Date(filters.startDate), new Date(filters.endDate)]
                          : []
                      }
                      onChange={(dates) => {
                        if (dates.length > 1) {
                          setFilters({
                            ...filters,
                            startDate: dates[0]?.toISOString(),
                            endDate: dates[1]?.toISOString(),
                          });
                        }
                      }}
                    >
                      <DatePickerInput placeholder={t('dateRanges.startDate')} />
                      <DatePickerInput placeholder={t('dateRanges.endDate')} />
                    </DatePicker>
                  </Delayed>
                )}
                {!today && filters.startDate && filters.endDate && (
                  <Delayed delay={!(state as any)?.previousPath ? 0 : 500}>
                    <Button
                      renderIcon={Erase}
                      onClick={() => setFilters({ ...filters, startDate: null, endDate: null })}
                      hasIconOnly
                      kind="ghost"
                      iconDescription={t('common.clearDates')}
                    />
                  </Delayed>
                )}
                <Button
                  renderIcon={Renew}
                  onClick={refetch}
                  hasIconOnly
                  kind="secondary"
                  iconDescription={t('common.refresh')}
                />
                <Button renderIcon={Add} onClick={() => navigate('/pos')}>
                  {t('common.createOrders')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {[...headers, ...extraHeaders].map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>
                      <Link onClick={() => setViewingOrder(filteredRows[index])}>
                        {filteredRows[index]?.totalItems} {t('common:items')}
                      </Link>
                    </TableCell>
                    <TableCell>{NumberToK(filteredRows[index]?.grandTotal)}K</TableCell>
                    <TableCell>
                      <OrderTypeTag type={filteredRows[index]?.type} />
                    </TableCell>
                    <TableCell>
                      <OrderStatusTag status={filteredRows[index]?.status} />
                    </TableCell>
                    <TableCell>
                      {filteredRows[index] &&
                        branches?.data.find((item) => item.id === filteredRows[index]?.restaurantBranchId)?.name}
                    </TableCell>
                    <TableCell>
                      {filteredRows[index]?.type === OrderTypeEnum.DELIVERY && (
                        <Button
                          kind="tertiary"
                          renderIcon={Delivery}
                          size={'sm'}
                          iconDescription={`${filteredRows[index]?.line1} \n ${filteredRows[index]?.phoneNumber}`}
                          hasIconOnly
                        />
                      )}
                      {filteredRows[index]?.type === OrderTypeEnum.DINE_IN && (
                        <Tag type={'magenta'} size="sm">
                          {transformedTables && transformedTables[filteredRows[index]?.tableId]}
                        </Tag>
                      )}
                      {filteredRows[index]?.type === OrderTypeEnum.TAKEAWAY && (
                        <Button
                          kind="tertiary"
                          renderIcon={ShoppingBag}
                          size={'sm'}
                          iconDescription={`Takeaway`}
                          hasIconOnly
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <OrderDate date={filteredRows[index].createdAt} format={today ? 'HH:mm' : undefined} />
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.view')}
                          requireTitle
                          onClick={() => setViewingOrder(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        backwardText={t('common.previousPage')}
        forwardText={t('common.nextPage')}
        itemsPerPageText={`${t('common.itemsPerPage')}:`}
        onChange={({ pageSize, page }) => {
          setPage(page);
          setFilters({ ...filters, limit: pageSize });
        }}
        itemText={(min, max) => `${min}–${max} ${t('common.items')}`}
        pageRangeText={(_current, total) =>
          `${t('common.of')} ${total} ${total === 1 ? t('common.page') : t('common.pages')}`
        }
        page={page}
        pageSize={filters.limit}
        pageSizes={[20, 50, 100]}
        size="lg"
        totalItems={orders?.total}
      />
      <Modal
        open={!!viewingOrder}
        size={'md'}
        modalHeading={`#${viewingOrder?.token.toUpperCase()}`}
        modalLabel={`${currentRestaurant?.name} - ${currentRestaurantBranch?.name}`}
        passiveModal
        onRequestClose={() => setViewingOrder(null)}
      >
        <OrderDetails
          order={viewingOrder!}
          onCancelled={(order, cancelReason) => {
            cancelOrdersMutation.mutate({
              id: viewingOrder?.id!,
              status: OrderStatusEnum.CANCELLED,
              cancelReason,
            });
            setViewingOrder(null);
          }}
        />
      </Modal>
    </div>
  );
});

export default AllOrders;
