import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getTransactionCategories,
  postTransactionCategories,
  putTransactionCategoriesId,
  deleteTransactionCategoriesId,
} from 'src/services/apis/services';
import { TransactionCategoryCreateDto, TransactionCategoryUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';

export const useTransactionCategories = () => {
  return useQuery([QUERIES.TRANSACTION_CATEGORIES], () => getTransactionCategories());
};

export const useCreateTransactionCategoriesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: TransactionCategoryCreateDto) => postTransactionCategories(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TRANSACTION_CATEGORIES]);
    },
  });
};

export const useUpdateTransactionCategoriesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: TransactionCategoryUpdateDto) => putTransactionCategoriesId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TRANSACTION_CATEGORIES]);
    },
  });
};

export const useDeleteTransactionCategoriesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((employeeId: string) => deleteTransactionCategoriesId(employeeId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TRANSACTION_CATEGORIES]);
    },
  });
};
