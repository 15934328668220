import React from 'react';
import ImgsViewer from 'react-images-viewer';
import { Loading } from '@carbon/react';

interface ImagesViewerProps {
  images: Array<{ src: string; caption: string }>;
  onClose: () => void;
}

const ImagesViewer = React.memo((props: ImagesViewerProps) => {
  return (
    <ImgsViewer
      imgs={props.images}
      showCloseBtn={false}
      backdropCloseable={true}
      className={'cds--images-viewer'}
      width={'calc(100vw - 16rem)'}
      isOpen={props.images.length > 0}
      onClickPrev={() => null}
      onClickNext={() => null}
      spinnerSize={10}
      spinner={() => <Loading withOverlay={false} />}
      onClose={props.onClose}
    />
  );
});

export default ImagesViewer;
