export const getAuthToken = () => {
  return localStorage.getItem('token');
};
export const setAuthToken = (token: string) => {
  return localStorage.setItem('token', token);
};

export const deleteAuthToken = () => {
  return localStorage.removeItem('token');
};
