import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getRestaurants, putRestaurantsId } from 'src/services/apis/services';
import { RestaurantUpdateDto } from 'src/services/apis/types';
import { QUERIES } from 'src/utils/react-query';
import { getCurrentRestaurantId, saveCurrentRestaurantId } from 'src/utils/restaurant';

export const useRestaurants = () => {
  return useQuery([QUERIES.MY_RESTAURANTS], () => getRestaurants());
};

export const useCurrentRestaurant = () => {
  const { data } = useRestaurants();
  if (data?.data && data?.data.length > 0) {
    const currentRestaurantId = getCurrentRestaurantId();
    if (!currentRestaurantId) {
      saveCurrentRestaurantId(data.data[0].id);
      return data.data[0];
    }
    return data.data.find((item) => item.id === currentRestaurantId) || data.data[0];
  }
  return null;
};

export const useUpdateRestaurantMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: RestaurantUpdateDto) => putRestaurantsId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.MY_RESTAURANTS]);
    },
  });
};
