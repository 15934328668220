import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tag } from '@carbon/react';
import { Delivery, Money, PhoneOutgoing } from '@carbon/react/icons';
import { OrderStatusEnum, OrderTypeEnum } from '@restacity/shared/enums/order';
import { useUpdateOrdersMutation } from 'src/hooks/useOrders';
import { useBranches } from 'src/hooks/useRestaurantBranches';
import { OrderEntity } from 'src/services/apis/types';
import { NumberToK } from 'src/utils/price';

interface DeliveryOrderItemProps {
  order: OrderEntity;
  onView: (order: OrderEntity) => void;
  paidOrderTokens?: string[];
}

const DeliveryOrderItem = React.memo((props: DeliveryOrderItemProps) => {
  const { t } = useTranslation();
  const { data: branches } = useBranches();
  const updateOrdersMutation = useUpdateOrdersMutation(OrderTypeEnum.DELIVERY);

  const transformedBranches = branches?.data.reduce((previousObject, currentObject) => {
    return Object.assign(previousObject, {
      [currentObject.id]: currentObject.name,
    });
  }, {});

  const handleChangeOrderStatus = useCallback((status: OrderStatusEnum) => {
    updateOrdersMutation.mutate({ id: props.order.id, status });
  }, []);

  return (
    <div className={'delivery-order-item'}>
      <div className="table" onClick={() => props.onView(props.order)}>
        <Delivery size={48} />
      </div>
      <div className={'info'}>
        <h5>{props.order.phoneNumber}</h5>
        <h4>{props.order.line1}</h4>
        <ul className="list-horizontal">
          <li>{dayjs(props.order.createdAt).fromNow()}</li>
        </ul>
        <div className="actions">
          <Button
            onClick={() => handleChangeOrderStatus(OrderStatusEnum.PAID)}
            size={'sm'}
            renderIcon={Money}
            kind={'primary'}
          />
          <a href={`tel:${props.order.phoneNumber}`}>
            <Button size={'sm'} renderIcon={PhoneOutgoing} kind={'danger'} />
          </a>
        </div>
      </div>
      <Tag className="pos-recent-orders-item-status" type="red" title="Clear Filter">
        {transformedBranches && transformedBranches[props.order.restaurantBranchId]} |{' '}
        {NumberToK(props.order.grandTotal)}K
      </Tag>
    </div>
  );
});

export default DeliveryOrderItem;
