import { Formik } from 'formik';
import React, { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack } from '@carbon/react';
import Row from 'src/components/Base/Row';
import { FormikDatePicker } from 'src/components/Form/FormikDatePicker';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import { FormikRadioButtonGroup } from 'src/components/Form/FormikRadioButtonGroup';
import RestaurantBranchSelector from 'src/components/Form/RestaurantBranchSelector';
import TransactionCategorySelector from 'src/components/Form/TransactionCategorySelector';
import { useAuthPermissions } from 'src/hooks/useAuthPermissions';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';
import { useTransactionCategories } from 'src/hooks/useTransactionCategories';
import { TransactionEntity } from 'src/services/apis/types';
import {
  TransactionStatusEnum,
  TransactionStatuses,
  TransactionTypeEnum,
  TransactionTypes,
} from 'src/utils/accounting';
import { NumberToK } from 'src/utils/price';

interface TransactionFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  type?: TransactionTypeEnum;
  initialValues: TransactionEntity | null | undefined;
}

const BaseSchema = z.object({
  title: z.string().max(50).trim(),
  total: z.preprocess((a) => parseFloat(`${a}`), z.number()),
});

const TransactionForm = (props: TransactionFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();
  const { isManager } = useAuthPermissions();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  const { data: transactionCategories } = useTransactionCategories();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () => {
        const category = transactionCategories?.data?.find((item) => item.type === initialType);
        formikRef.current?.resetForm({
          values: {
            restaurantBranchId: currentRestaurantBranch?.id,
            date: new Date(),
            title: category?.name,
            description: '',
            notes: '',
            tags: '',
            total: 0,
            type: initialType,
            status: getDefaultStatus(initialType),
            categoryId: category?.id,
          },
        });
      },
    };
  });

  const getStatusOptions = (type: TransactionTypeEnum) => {
    if (type === TransactionTypeEnum.INCOME) {
      return TransactionStatuses.filter((item) => item.value !== TransactionStatusEnum.PAID);
    }
    if (type === TransactionTypeEnum.EXPENSE) {
      return TransactionStatuses.filter((item) => item.value !== TransactionStatusEnum.RECEIVED);
    }
    return TransactionStatuses;
  };

  const getDefaultStatus = (type: TransactionTypeEnum) => {
    if (type === TransactionTypeEnum.INCOME) {
      return TransactionStatusEnum.RECEIVED;
    }
    if (type === TransactionTypeEnum.EXPENSE) {
      return TransactionStatusEnum.PAID;
    }
    return TransactionStatusEnum.PAID;
  };

  const initialType = props.initialValues?.type || props.type || TransactionTypeEnum.INCOME;

  return (
    <div id={'transaction-form'}>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize={!!props.initialValues}
        validationSchema={toFormikValidationSchema(BaseSchema)}
        initialValues={{
          id: props.initialValues?.id,
          restaurantBranchId: props.initialValues?.restaurantBranchId || currentRestaurantBranch?.id,
          categoryId:
            props.initialValues?.categoryId ||
            transactionCategories?.data?.find((item) => item.type === initialType)?.id,
          title: props.initialValues?.title || '',
          date: props.initialValues?.date || new Date(),
          total: props.initialValues ? NumberToK(props.initialValues?.total) : 0,
          notes: props.initialValues?.notes || '',
          description: props.initialValues?.description,
          type: initialType,
          status: props.initialValues?.status || getDefaultStatus(initialType),
          tags: props.initialValues?.tags ? props.initialValues?.tags.join(',') : '',
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <Row gap={1}>
                <RestaurantBranchSelector
                  value={values.restaurantBranchId}
                  onChange={(value) => {
                    setFieldValue('restaurantBranchId', value);
                  }}
                  disabled={isManager}
                />
                <TransactionCategorySelector
                  value={values.categoryId}
                  type={values.type}
                  onChange={(value) => {
                    setFieldValue('categoryId', value);
                    if (!props.initialValues?.title) {
                      setFieldValue('title', transactionCategories?.data?.find((item) => item.id === value)?.name);
                    }
                  }}
                />
              </Row>
              <FormikRadioButtonGroup
                name={'type'}
                id={'transaction-type-radio'}
                label={t('common.type')}
                value={values.type!}
                onChange={(value) => {
                  setFieldValue('type', value);
                  setFieldValue('status', getDefaultStatus(parseInt(value, 10) as TransactionTypeEnum));
                }}
                useTranslation
                options={TransactionTypes}
              />
              <FormikTextInput name={'title'} label={t('common.title')} />
              <Row gap={1}>
                <FormikDatePicker
                  label={t('common.date')}
                  value={values.date}
                  disabled={isManager}
                  onChange={(dates) => setFieldValue('date', dates[0])}
                />
                <div className="cds--fullwidth">
                  <FormikTextInput name={'total'} isNumber max={50000} label={`${t('common.total')} (K)`} />
                </div>
              </Row>
              <Row gap={1}>
                <FormikTextInput name={'notes'} label={t('common.notes')} />
                <FormikTextInput name={'tags'} label={t('common.tags')} />
              </Row>
              <FormikRadioButtonGroup
                name={'status'}
                id={'transaction-status-radio'}
                useTranslation
                label={t('common.status')}
                value={values.status!}
                onChange={(value) => setFieldValue('status', value)}
                options={getStatusOptions(values.type)}
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(TransactionForm);
