import { AxiosError } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableSkeleton,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  OverflowMenu,
  OverflowMenuItem,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Modal,
  Toggle,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import DeleteConfirmation from 'src/components/Base/DeleteConfirmation';
import EmptyState from 'src/components/Base/EmptyState';
import MainMenuForm from 'src/components/Menu/MainMenuForm';
import { useMenu, useCreateMenuMutation, useDeleteMenuMutation, useUpdateMenuMutation } from 'src/hooks/useMenu';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';
import { MenuEntity } from 'src/services/apis/types';
import { getMutationErrorMessage } from 'src/utils/error';

const MainMenuSettings = React.memo(() => {
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const { isLoading, data } = useMenu();
  const currentRestaurant = useCurrentRestaurant();
  const createMenuMutation = useCreateMenuMutation();
  const updateMenuMutation = useUpdateMenuMutation();
  const deleteMenuMutation = useDeleteMenuMutation();
  const [openAddMenu, setOpenAddMenu] = useState(false);
  const [editingMenu, setEditingMenu] = useState<null | undefined | MenuEntity>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [deletingMenu, setDeletingMenu] = useState<null | undefined | MenuEntity>(null);

  const handleCloseAddOrEditMenu = useCallback(() => {
    setOpenAddMenu(false);
    setEditingMenu(null);
    formRef.current?.resetForm();
  }, []);

  const handleAddMenu = useCallback(() => {
    formRef.current?.resetForm();
    setOpenAddMenu(true);
  }, []);

  const handleCreate = useCallback(
    async (values) => {
      await createMenuMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        photos: [values.icon],
        blurhashPhotos: [],
      });
      handleCloseAddOrEditMenu();
    },
    [currentRestaurant?.id]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateMenuMutation.mutateAsync({
        ...values,
        restaurantId: currentRestaurant?.id,
        photos: [values.icon],
        blurhashPhotos: [],
      });
      handleCloseAddOrEditMenu();
    },
    [currentRestaurant?.id]
  );

  const handleAbortDelete = useCallback(() => {
    setDeletingMenu(null);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteMenuMutation.mutateAsync(deletingMenu?.id!);
    setDeletingMenu(null);
  }, [deletingMenu]);

  const headers = [
    {
      key: 'name',
      header: t('common.name'),
    },
    {
      key: 'slug',
      header: t('common.slug'),
    },
    {
      key: 'summary',
      header: t('common.summary'),
    },
  ];

  if (isLoading) {
    return <DataTableSkeleton columnCount={headers.length} rowCount={6} headers={headers} />;
  }

  const filteredRows =
    data?.data
      .filter((item) => {
        if (searchQuery) {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.slug.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        return true;
      })
      .map((item) => ({
        ...item,
      })) || [];

  const submitting = createMenuMutation.isLoading || updateMenuMutation.isLoading;
  const createError = getMutationErrorMessage(createMenuMutation.error as AxiosError);
  const updateError = getMutationErrorMessage(updateMenuMutation.error as AxiosError);

  const tableTitle = t('titles.menuPage', { restaurantName: currentRestaurant?.name });

  return (
    <>
      <DataTable rows={filteredRows} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getToolbarProps, getRowProps }) => (
          <TableContainer title={tableTitle} description={`${data?.total} ${t('common.menu')}`}>
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={(e) => setSearchQuery(e.target.value)} />
                <Button renderIcon={Add} onClick={handleAddMenu}>
                  {t('actions.create')} {t('common.menu')}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                  <TableHeader>{t('common.totalItems')}</TableHeader>
                  <TableHeader>{t('common.icon')}</TableHeader>
                  <TableHeader>{t('common.activated')}</TableHeader>
                  <TableHeader>{t('common.actions')}</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                    <TableCell>0 {t('common.items')}</TableCell>
                    <TableCell>
                      {filteredRows[index]?.photos && (
                        <img src={filteredRows[index]?.photos[0]} width={32} height={32} alt="" />
                      )}
                    </TableCell>
                    <TableCell>
                      {filteredRows[index] && (
                        <Toggle
                          labelA={t('common.no')}
                          labelB={t('common.yes')}
                          toggled={filteredRows[index].activated}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <OverflowMenu size="md" flipped ariaLabel={t('actions.title')}>
                        <OverflowMenuItem
                          itemText={t('actions.edit')}
                          requireTitle
                          onClick={() => setEditingMenu(filteredRows[index])}
                        />
                        <OverflowMenuItem
                          hasDivider
                          isDelete
                          itemText={t('actions.delete')}
                          onClick={() => setDeletingMenu(filteredRows[index])}
                        />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rows.length === 0 && <EmptyState />}
          </TableContainer>
        )}
      </DataTable>
      <Modal
        open={openAddMenu || !!editingMenu}
        size={'sm'}
        modalHeading={
          openAddMenu ? `${t('actions.createNew')} ${t('common.menu')}` : `${t('actions.update')} ${t('common.menu')}`
        }
        modalLabel={tableTitle}
        primaryButtonText={
          submitting ? `${t('common.loading')}...` : editingMenu ? t('actions.update') : t('actions.create')
        }
        secondaryButtonText={t('actions.cancel')}
        onSecondarySubmit={handleCloseAddOrEditMenu}
        onRequestClose={handleCloseAddOrEditMenu}
        primaryButtonDisabled={submitting}
        onRequestSubmit={() => formRef.current?.submitForm()}
      >
        <MainMenuForm
          errorMessage={createError || updateError}
          initialValues={editingMenu}
          ref={formRef}
          onSubmit={editingMenu ? handleUpdate : handleCreate}
        />
      </Modal>
      <DeleteConfirmation
        open={!!deletingMenu}
        heading={t('messages.areYouSureToDeleteThisMenu')}
        label={tableTitle}
        onClose={handleAbortDelete}
        onDelete={handleDelete}
        deleting={deleteMenuMutation.isLoading}
      />
    </>
  );
});

export default MainMenuSettings;
