import React from 'react';
import { MenuItemEntity } from 'src/services/apis/types';
import { NumberToK } from 'src/utils/price';

interface OrderLineProps {
  orderLine: {
    orderItem: MenuItemEntity | undefined;
    quantity: number;
    subTotal: number;
  };
  index: number;
}

const OrderLine = React.memo((props: OrderLineProps) => {
  return (
    <div className="order-item">
      <h4>{props.orderLine.orderItem?.name}</h4>
      <span>x{props.orderLine.quantity}</span>
      <h5>{NumberToK(props.orderLine.subTotal)}K</h5>
    </div>
  );
});

export default OrderLine;
