import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { SimpleBarChart } from '@carbon/charts-react';
import { useMenu } from 'src/hooks/useMenu';
import { useMenuItems } from 'src/hooks/useMenuItems';
import { useAppStore } from 'src/states/app';
import { NumberToK } from 'src/utils/price';

interface SalesByMenuProps {
  title: string;
  orderItemsSalesReports: any[];
  valueKey: string;
}

const ItemsSales = React.memo((props: SalesByMenuProps) => {
  const { t } = useTranslation('dashboard');
  const { theme } = useAppStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow
  );
  const { isLoading, data: menuItems } = useMenuItems();
  const { data: menu } = useMenu();
  const mappedMenuItems = menuItems?.data?.map((menuItem) => ({
    ...menuItem,
    menu: menu?.data?.find((r) => r.id === menuItem.menuId),
  }));
  return (
    <div className="dashboard__stat_widget">
      <SimpleBarChart
        data={
          isLoading
            ? []
            : props.orderItemsSalesReports.map((item) => {
                const menuItem = mappedMenuItems?.find((r) => r.id === item.menuItemId);
                return {
                  key: menuItem?.name,
                  group: menuItem?.menu?.name,
                  value: props.valueKey === 'totalSales' ? NumberToK(item[props.valueKey]) : item[props.valueKey],
                };
              })
        }
        options={{
          title: props.title,
          axes: {
            left: {
              title: t('totalSales'),
              mapsTo: 'value',
              ticks: {
                formatter: (value) => (props.valueKey === 'totalSales' ? `${value}K` : `${value}`),
              },
            },
            bottom: {
              title: t('menuItem'),
              mapsTo: 'key',
              scaleType: 'labels' as any,
            },
          },
          height: '400px',
          theme: theme === 'dark' ? 'g100' : ('g10' as any),
        }}
      />
    </div>
  );
});

export default ItemsSales;
