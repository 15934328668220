import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import TabsWithTablesLayout from 'src/components/Layout/TabsWithTablesLayout';

interface AccountingPageProps {}

const AccountingPage = React.memo((_: AccountingPageProps) => {
  const { t } = useTranslation();
  const routes = [
    {
      label: t('common.allTransactions'),
      url: '/accounting/transactions',
    },
    {
      label: t('common.incomes'),
      url: '/accounting/incomes',
    },
    {
      label: t('common.expenses'),
      url: '/accounting/expenses',
    },
    {
      label: t('common.investments'),
      url: '/accounting/investments',
    },
    {
      label: t('common.settings'),
      url: '/accounting/settings',
    },
  ];

  return (
    <TabsWithTablesLayout
      homeUrl={'/dashboard/accounting'}
      mainPageUrl={'/accounting/transactions'}
      routes={routes}
      title={t('common.accounting')}
    >
      <Outlet />
    </TabsWithTablesLayout>
  );
});

export default AccountingPage;
