import { useAuthUser } from 'src/hooks/useAuthUser';
import { useCurrentRestaurant } from 'src/hooks/useRestaurants';

export function useAuthPermissions() {
  const user = useAuthUser();
  const currentRestaurant = useCurrentRestaurant();
  const isAdmin = user?.roleId === 1;
  const isRestaurantOwner = currentRestaurant?.ownerId && currentRestaurant?.ownerId === user?.id;
  const isManager = user?.roleId === 2 && user.managedRestaurantIds.length > 0 && !isRestaurantOwner;
  const isEmployee = !isManager && !isRestaurantOwner && user?.roleId === 2 && user.employedRestaurantIds.length > 0;

  return {
    isAdmin,
    isRestaurantOwner,
    isManager,
    isEmployee,
  };
}
