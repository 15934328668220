import { Formik } from 'formik';
import React, { useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FormGroup, InlineNotification, Stack } from '@carbon/react';
import { FormikTextInput } from 'src/components/Form/FormikInputs';
import { FormikRadioButtonGroup } from 'src/components/Form/FormikRadioButtonGroup';
import { TransactionCategoryEntity } from 'src/services/apis/types';
import {
  TransactionCategoryTypeEnum,
  TransactionCategoryTypes,
  TransactionCategoryStatusEnum,
  TransactionCategoryStatuses,
} from 'src/utils/accounting';

interface TransactionCategoryFormProps {
  onSubmit: (values: any) => void;
  errorMessage: string | null;
  initialValues: TransactionCategoryEntity | null | undefined;
}

const BaseSchema = z.object({
  name: z.string().max(50).trim(),
});

const TransactionCategoryForm = (props: TransactionCategoryFormProps, ref) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      submitForm: formikRef.current?.submitForm,
      setFieldValue: formikRef.current?.setFieldValue,
      resetForm: () =>
        formikRef.current?.resetForm({
          values: {
            id: '',
            name: '',
            description: '',
            type: TransactionCategoryTypeEnum.INCOME,
            status: TransactionCategoryStatusEnum.ACTIVE,
            tags: '',
            icon: '',
          },
        }),
    };
  });

  return (
    <div>
      {props.errorMessage && <InlineNotification subtitle={props.errorMessage} />}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validationSchema={toFormikValidationSchema(BaseSchema)}
        initialValues={{
          id: props.initialValues?.id,
          name: props.initialValues?.name,
          description: props.initialValues?.description,
          type: props.initialValues?.type || TransactionCategoryTypeEnum.INCOME,
          status: props.initialValues?.status || TransactionCategoryStatusEnum.ACTIVE,
          tags: props.initialValues?.tags,
          icon: props.initialValues?.icon,
        }}
        onSubmit={props.onSubmit}
      >
        {({ values, setFieldValue }) => (
          <FormGroup legendText={''}>
            <Stack gap={5}>
              <FormikRadioButtonGroup
                name={'type'}
                label={t('common.type')}
                value={values.type!}
                useTranslation={true}
                onChange={(value) => setFieldValue('type', value)}
                options={TransactionCategoryTypes}
              />
              <FormikTextInput name={'name'} label={t('common.name')} />
              <FormikTextInput name={'description'} label={t('common.description')} />
              <FormikTextInput name={'tags'} label={`${t('common.tags')} (${t('common.separateByAComma')})`} />
              <FormikRadioButtonGroup
                name={'status'}
                label={t('common.status')}
                value={values.status!}
                useTranslation={true}
                onChange={(value) => setFieldValue('status', value)}
                options={TransactionCategoryStatuses}
              />
            </Stack>
          </FormGroup>
        )}
      </Formik>
    </div>
  );
};

export default React.forwardRef(TransactionCategoryForm);
