import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useAuthPermissions } from 'src/hooks/useAuthPermissions';
import { useDebounce } from 'src/hooks/useUtils';
import { deleteTransactionsId, getTransactions, postTransactions, putTransactionsId } from 'src/services/apis/services';
import { TransactionCreateDto, TransactionUpdateDto } from 'src/services/apis/types';
import { TransactionStatusEnum, TransactionTypeEnum } from 'src/utils/accounting';
import { QUERIES } from 'src/utils/react-query';

export const useTransactions = (
  expenses: boolean | undefined,
  incomes: boolean | undefined,
  investments: boolean | undefined
) => {
  const { isManager } = useAuthPermissions();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{
    limit: number;
    type: TransactionTypeEnum | null;
    status: TransactionStatusEnum | null;
    search: string | null;
    categoryId: string | null;
    startDate: Date | null;
    endDate: Date | null;
  }>({
    limit: 20,
    type: null,
    status: null,
    search: null,
    startDate: isManager ? dayjs(new Date()).startOf('day').toDate() : null,
    endDate: isManager ? dayjs(new Date()).endOf('day').toDate() : null,
    categoryId: null,
  });
  const getDefaultFilters = useCallback(() => {
    let type: TransactionTypeEnum | null = null;
    if (expenses) {
      type = TransactionTypeEnum.EXPENSE;
    }
    if (incomes) {
      type = TransactionTypeEnum.INCOME;
    }
    if (investments) {
      type = TransactionTypeEnum.INVESTMENT;
    }
    return {
      limit: 20,
      type,
      status: null,
      search: null,
      startDate: isManager ? dayjs(new Date()).startOf('day').toDate() : null,
      endDate: isManager ? dayjs(new Date()).endOf('day').toDate() : null,
      categoryId: null,
    };
  }, [expenses, incomes, investments, isManager]);
  useEffect(() => {
    setFilters(getDefaultFilters());
  }, [expenses, incomes, investments, isManager]);
  const debounceSearch = useDebounce(filters.search, 700);
  const query = useQuery(
    [
      QUERIES.TRANSACTIONS,
      page,
      {
        limit: filters.limit,
        type: filters.type,
        status: filters.status,
        categoryId: filters.categoryId,
        search: debounceSearch,
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    ],
    ({ queryKey: [_key, _page, _filters] }: { queryKey: any }) => {
      const convertedFilters: string[] = [];
      let convertedSearch: string | undefined = undefined;
      if (_filters.type) {
        convertedFilters.push(`type||$eq||${_filters.type}`);
      }
      if (_filters.status) {
        convertedFilters.push(`status||$eq||${_filters.status}`);
      }
      if (_filters.categoryId) {
        convertedFilters.push(`categoryId||$eq||${_filters.categoryId}`);
      }
      if (_filters.startDate && _filters.endDate) {
        convertedFilters.push(`date||$gte||${dayjs(_filters.startDate).startOf('day')}`);
        convertedFilters.push(`date||$lte||${dayjs(_filters.endDate).endOf('day')}`);
      }
      if (_filters.search) {
        convertedFilters.push(`title||$contL||${_filters.search}`);
      }
      return getTransactions({
        page: _page as number,
        limit: _filters.limit,
        filter: convertedFilters,
        s: convertedSearch,
      });
    },
    {
      cacheTime: 0,
      refetchOnMount: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return {
    query,
    page,
    filters,
    setPage,
    setFilters,
  };
};

export const useCreateTransactionsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: TransactionCreateDto) => postTransactions(payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TRANSACTIONS]);
    },
  });
};

export const useUpdateTransactionsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: TransactionUpdateDto) => putTransactionsId(payload.id, payload), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TRANSACTIONS]);
    },
  });
};

export const useDeleteTransactionsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((employeeId: string) => deleteTransactionsId(employeeId), {
    onSuccess: () => {
      return queryClient.refetchQueries([QUERIES.TRANSACTIONS]);
    },
  });
};
