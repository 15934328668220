import React from 'react';
import { FormItem } from '@carbon/react';
import DndUploader from 'src/components/Uploader/DNDUploader';
import { getImageUrl } from 'src/utils/cloudinary';

export function FormFieldUpload(props: {
  value: any;
  title: string;
  aspectRatio: string;
  cropperAspectRatio: number;
  description: string;
  onChange: (data) => void;
  onSelectedFile: ({ file, previewUrl }) => void;
}) {
  return (
    <FormItem>
      <p className="cds--file--label">{props.title}</p>
      <p className="cds--label-description">{props.description}</p>
      <DndUploader
        useCropper
        onSelectedFile={props.onSelectedFile}
        initialValue={props.value ? { fileId: props.value, previewUrl: getImageUrl(props.value)! } : null}
        previewAspectRatio={props.aspectRatio}
        cropperAspectRatio={props.cropperAspectRatio}
        onChange={props.onChange}
      />
    </FormItem>
  );
}
