import React from 'react';
import { Checkbox, Column, FlexGrid, Row } from '@carbon/react';

interface IconSelectorProps {
  value: string;
  onChange: (icon: string) => void;
  icons: string[];
}

const IconSelector = React.memo((props: IconSelectorProps) => {
  return (
    <FlexGrid defaultSelected="standard" legend={'Icon'} className={'icon-selector--container'}>
      <Row>
        {props.icons.map((icon) => (
          <Column className={icon === props.value && 'cds--col--selected'} onClick={() => props.onChange(icon)}>
            <img src={icon} alt={`Restacity menu icon: ${icon}`} />
            {icon === props.value && <Checkbox checked id={icon} />}
          </Column>
        ))}
      </Row>
    </FlexGrid>
  );
});

export default IconSelector;
