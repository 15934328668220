import 'cropperjs/dist/cropper.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploaderDropContainer, Column, FlexGrid, Row, AspectRatio, FileUploaderItem } from '@carbon/react';
import { CropperModal } from 'src/components/Uploader/CropperModal';

interface DndUploaderProps {
  useCropper: boolean;
  initialValue?: {
    fileId: string;
    previewUrl: string;
  } | null;
  cropperAspectRatio: number;
  previewAspectRatio: string;
  onChange: ({ fileId, format, blurhash }) => void;
  onSelectedFile?: ({ file, previewUrl }) => void;
}

type UploadedFile = {
  uploading?: boolean;
  uploaded?: boolean;
  fileId?: string;
  previewUrl?: string;
};

const DndUploader = React.memo((props: DndUploaderProps) => {
  const { t } = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedFile>>(
    props.initialValue ? [{ ...props.initialValue, uploaded: true }] : []
  );
  const [croppingFile, setCroppingFile] = useState<{ file: File | null; previewUrl: string } | null>(null);

  useEffect(() => {
    if (props.initialValue?.fileId) {
      setUploadedFiles([{ ...props.initialValue, uploaded: true }]);
    } else {
      setUploadedFiles([]);
    }
  }, [props.initialValue?.fileId]);

  const handleChangeFiles = useCallback(async (e) => {
    const selectedFile = { file: e.target.files[0], previewUrl: URL.createObjectURL(e.target.files[0]) };
    if (props.onSelectedFile) {
      return props.onSelectedFile(selectedFile);
    }
    if (!props.initialValue) {
      setUploadedFiles([]);
    }
    setCroppingFile(selectedFile);
  }, []);

  const handleCropped = useCallback(
    async ({ fileId, format, blurhash, previewUrl }) => {
      setCroppingFile(null);
      setUploadedFiles([
        {
          fileId: `${fileId}.${format}`,
          previewUrl,
          uploaded: true,
        },
      ]);
      props.onChange({ fileId, format, blurhash });
    },
    [uploadedFiles, croppingFile]
  );

  return (
    <div className={'dnd-uploader'}>
      <FileUploaderDropContainer
        accept={['image/jpeg', 'image/png']}
        innerRef={{
          current: '[Circular]',
        }}
        labelText={t('common.dndFilesHereOrClickToUpload')}
        multiple={false}
        name=""
        onAddFiles={handleChangeFiles}
        onChange={handleChangeFiles}
        role=""
      />
      <div className="cds--file-container cds--file-container--drop" />
      <FlexGrid className={'dnd-uploader__items'} fullWidth>
        <Row narrow>
          {uploadedFiles.map((selectedFile) => (
            <Column lg={8} className="dnd-uploader__item" key={selectedFile.fileId}>
              <FileUploaderItem
                errorBody={t('messages.fileIsTooBig', { size: '1M' })}
                errorSubject="File size exceeds limit"
                iconDescription={t('actions.deleteFile')}
                name={selectedFile.fileId}
                size="md"
                status={selectedFile.uploading ? 'uploading' : 'complete'}
              />
              <AspectRatio
                ratio={props.previewAspectRatio}
                className={'dnd-uploader__item__preview'}
                style={{ backgroundImage: `url(${selectedFile.previewUrl})` }}
              />
            </Column>
          ))}
        </Row>
      </FlexGrid>
      {!props.onSelectedFile && (
        <CropperModal
          croppingFile={croppingFile}
          onClose={() => setCroppingFile(null)}
          cropperAspectRatio={props.cropperAspectRatio}
          onChange={handleCropped}
        />
      )}
    </div>
  );
});

export default DndUploader;
