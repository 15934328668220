import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '@carbon/react';
import Toolbar from 'src/components/Dashboard/Toolbar';
import { useAuthUser } from 'src/hooks/useAuthUser';
import { useCurrentRestaurantBranch } from 'src/hooks/useRestaurantBranches';

const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  const authUser = useAuthUser();
  const currentRestaurantBranch = useCurrentRestaurantBranch();
  return (
    <div className={'dashboard'}>
      <div className={'dashboard__main-content'}>
        <Breadcrumb noTrailingSlash aria-label="Page navigation">
          <BreadcrumbItem>
            <Link to="/dashboard/selling">{t('title')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/dashboard/selling">{currentRestaurantBranch?.name || 'All'}</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <h1 className="dashboard__heading">
          {t('welcomeBack')}, {authUser?.firstName}
        </h1>
        <Toolbar />
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
