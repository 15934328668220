import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import TabsWithTablesLayout from 'src/components/Layout/TabsWithTablesLayout';

interface EmployeesPageProps {}

const EmployeesPage = React.memo((_: EmployeesPageProps) => {
  const { t } = useTranslation();
  const routes = [
    {
      label: t('common.employees'),
      url: '/employees/all',
    },
    {
      label: t('common.employeeIssues'),
      url: '/employees/issues',
    },
    {
      label: t('common.employeeRules'),
      url: '/employees/rules',
    },
    {
      label: t('common.employeeDayOff'),
      url: '/employees/day-off',
    },
  ];
  return (
    <TabsWithTablesLayout routes={routes} title={t('common.employees')}>
      <Outlet />
    </TabsWithTablesLayout>
  );
});

export default EmployeesPage;
