import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@carbon/react';
import { OrderTypeEnum, OrderStatusEnum } from '@restacity/shared/enums/order';
import { getOrderTypeText, getOrderTypeColor, getOrderStatusColor, getOrderStatusText } from 'src/utils/order';

interface OrderTypeTagProps {
  type: OrderTypeEnum;
}

interface OrderStatusTagProps {
  status: OrderStatusEnum;
}

const OrderTypeTag = React.memo((props: OrderTypeTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getOrderTypeColor(props.type)} size="sm">
      {t(getOrderTypeText(props.type))}
    </Tag>
  );
});

const OrderStatusTag = React.memo((props: OrderStatusTagProps) => {
  const { t } = useTranslation();
  return (
    <Tag type={getOrderStatusColor(props.status)} size="sm">
      {t(getOrderStatusText(props.status))}
    </Tag>
  );
});

export { OrderTypeTag, OrderStatusTag };
