import React, { useEffect, useMemo } from 'react';
import { Select, SelectItem } from '@carbon/react';
import { useBranches } from 'src/hooks/useRestaurantBranches';

interface RestaurantBranchSelectorProps {
  value: string | undefined;
  invalid?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const RestaurantBranchSelector = React.memo((props: RestaurantBranchSelectorProps) => {
  const { data: branches } = useBranches();

  useEffect(() => {
    if (branches?.data?.length && !props.value) {
      const defaultBranchId = branches?.data?.find(() => true)?.id;
      if (defaultBranchId) {
        props.onChange(defaultBranchId);
      }
    }
  }, [branches, props.value]);

  const branchSelectionItems = useMemo(() => {
    return branches?.data.map((item) => ({ id: item.id, text: item.name }));
  }, [branches?.data]);

  return (
    <div className={'cds--fullwidth'}>
      <label className={'cds--label'}>Select a branch</label>
      <Select
        labelText="Select an option"
        hideLabel
        id={'branch-selector'}
        disabled={props.disabled}
        value={props.value}
        onChange={(e: any) => props.onChange(e.target.value)}
        invalid={!props.value && branchSelectionItems?.length}
      >
        {branchSelectionItems?.map((item) => {
          return <SelectItem value={item.id} key={item.id} text={item.text} />;
        })}
      </Select>
    </div>
  );
});

export default RestaurantBranchSelector;
